import { createAsyncTypes } from "@ctra/utils";

const ns = "app/signup";

/**
 * Commit form changes to the store
 * @category Type
 */
const COMMIT = `${ns}/COMMIT`;

/**
 * Push form changes to the server
 * @category Type
 */
const PUSH = `${ns}/PUSH`;

/**
 * Get a link and status for a remote session
 * @type {AsyncType}
 * @category Type
 */
const GET_REMOTE_SESSION = createAsyncTypes(ns, "GET_REMOTE_SESSION", {
  replays: true,
  expires: 5 * 60 * 1000, // 5 minutes
  preservesPayload: true
});

/**
 * Make a remote session
 * @type {AsyncType}
 * @category Type
 */
const MAKE_REMOTE_SESSION = createAsyncTypes(ns, "MAKE_REMOTE_SESSION");

export default {
  COMMIT,
  PUSH,
  GET_REMOTE_SESSION,
  MAKE_REMOTE_SESSION
};
