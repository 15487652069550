import moment from "moment";
import * as _ from "lodash";

import { EntitySelector } from "@ctra/utils";

import { TokenEntity } from "../modules/auth";
import { BreadcrumbList, RemoteSession, SignupState, UserEntity, UserList } from "../modules";
import { SubscriptionsAppState } from "./state";

/**
 * Get the auth token info
 * @param {SubscriptionsAppState} state
 */
export const getToken: EntitySelector<SubscriptionsAppState, Record<string, unknown>, TokenEntity> = (
  state
) => state.auth.token;

/**
 * Tell whether the user access token is valid
 * @param {SubscriptionsAppState} state
 * @private
 */
const _hasValidToken: EntitySelector<SubscriptionsAppState, Record<string, unknown>, boolean> = (state) => {
  const expires = state.auth.token.expires;
  const now = moment();

  return expires ? moment(expires).isAfter(now) : false;
};

/**
 * Tell whether the current user is logged in
 * @param {SubscriptionsAppState} state
 */
export const isLoggedIn: EntitySelector<SubscriptionsAppState, Record<string, unknown>, boolean> = (state) =>
  !!getToken(state) && _hasValidToken(state);

/**
 * Tell if the user is logged out
 * @param {SubscriptionsAppState} state
 */
export const hasLoggedOut: EntitySelector<SubscriptionsAppState, never, boolean> = (state) =>
  state.auth.logout;

/**
 * Get the breadcrumbs
 * @param {SubscriptionsAppState} state
 */
export const getBreadcrumbs: EntitySelector<SubscriptionsAppState, Record<string, unknown>, BreadcrumbList> =
  (state) => state.navigation.breadcrumbs;

/**
 * Get the username of the logged in user
 * @param {SubscriptionsAppState} state
 */
export const getUsername: EntitySelector<
  SubscriptionsAppState,
  Record<string, unknown>,
  UserEntity["username"]
> = (state) => state.auth.user;

/**
 * Get the logged in user
 * @param {SubscriptionsAppState} state
 */
export const getLoggedInUser: EntitySelector<SubscriptionsAppState, Record<string, unknown>, UserEntity> = (
  state
) => {
  const user = getUser(state, { username: getUsername(state) });

  if (!user) {
    throw new Error("You don't seem to be logged in yet.");
  }

  return user;
};

/**
 * Get all the users from the state
 * @param {SubscriptionsAppState} state
 * @return {UserList}
 */
export const getUserList: EntitySelector<SubscriptionsAppState, never, UserList> = (state) => {
  return _.merge({}, ..._.values(state.users));
};

/**
 * Get a user from the state based on the given filters
 * @param {SubscriptionsAppState} state
 * @param {Partial<UserEntity>} filter
 */
export const getUser: EntitySelector<SubscriptionsAppState, Partial<UserEntity>, UserEntity | undefined> = (
  state,
  filter
) => {
  if (filter) {
    return _.find(getUserList(state), (user) =>
      _.every(user, (v, k) => _.isEqual(filter[k as keyof UserEntity], v) || !filter[k as keyof UserEntity])
    );
  }

  throw new Error(
    "You must pass a search query (filter) when you look for a user. You may want to use: getLoggedInUser"
  );
};

/**
 * Get the signup form values
 * @param {SubscriptionsAppState} state
 * @return {SignupState}
 */
export const getSignupValues: EntitySelector<SubscriptionsAppState, Record<string, unknown>, SignupState> = (
  state
) => state.signup;

/**
 * Get the remote session
 * @param {SubscriptionsAppState} state
 * @return {RemoteSession}
 */
export const getRemoteSession: EntitySelector<SubscriptionsAppState, Record<string, unknown>, RemoteSession> =
  (state) => _.get(state, ["signup", "remoteSession"]) as RemoteSession;
