import * as _ from "lodash";

import { readableHash } from "@ctra/utils";

import {
  ChartEntity,
  ChartFilterDefinition,
  ChartFilters,
  ChartTimePeriod,
  ChartDataSource,
  ChartData,
  UnitSystemParam
} from "./typings";

import { Normalizer } from "./normalizer";
import { FarmEntity } from "../farms";

/**
 * Make a hash from the given chart id and filters.
 * @param chartID
 * @param timePeriod
 * @param farmIDs
 * @param unitSystem
 * @param filters
 * @example
 * ```ts
 * makeChartHash(
 *   "1234-asdf-2345-sdfg",
 *   { startDate: "2022-02-01T00:00:00.000Z", endDate: "2022-03-03T00:00:00.000Z" },
 *   { farmIDs: [33, 38] }
 * );
 * // "a23dd9603622592f2ee01de3d6407d49b0e328c7"
 * ```
 */
export const makeChartHash = (
  chartID: ChartEntity["id"],
  timePeriod: ChartTimePeriod,
  farmIDs: Array<FarmEntity["id"]>,
  unitSystem: UnitSystemParam,
  filters?: ChartFilters
): string =>
  readableHash({
    chartID,
    timePeriod,
    farmIDs,
    unitSystem,
    filters
  });

/**
 * Supported duration values which apply to the charts
 */
export const supportedDurations = ["P1W", "P2W", "P3W", "P1M", "P2M", "P3M", "P6M", "P9M", "P1Y", "P5Y"];

/**
 * @todo type this for better support
 */
export type ISODuration = typeof supportedDurations[number];

/**
 * Gets default filters
 * Filters out unitSystem filters and assigns filters to empty array values
 * @param {Array<ChartFilterDefinition>} filterDefinitions
 * @return {ChartFilters}
 *
 * @example
 * ```ts
 * getDefaultFilters([{ name: dimGroupKey, isMultiSelectable: true }, { name: unitSystem, isMultiSelectable: true }]);
 * // {dimGroupKey: []}
 * ```
 */
export const getDefaultFilters = (filterDefinitions: Array<ChartFilterDefinition>) => {
  return _.reduce<ChartFilterDefinition, ChartFilters>(
    filterDefinitions,
    (res, filter) => {
      const key = filter.name;

      if (key) {
        /**
         * Naive assumption that every filter is an array.
         * @todo keep an eye on non-array filters
         */
        res[key] = [];
      }

      return res;
    },
    {}
  );
};

/**
 * Process the incoming chart data
 * @param input
 * @returns
 */
export const normalizeChartData = (input: ChartDataSource): ChartData => {
  const normalizer = Normalizer.create(input);
  return normalizer.format();
};
