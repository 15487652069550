import * as _ from "lodash";
import { Reducer, UnitSystem } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import { types as sessionTypes } from "../session";
import types from "./types";
import { UserPreferencesEntity } from "./typings";

export const initialState: UserPreferencesEntity = {
  timezone: null,
  locale: "en-US",
  unitSystem: UnitSystem.metric,
  sandbox: {
    isEnabled: null,
    farmId: null
  }
};

/**
 * Reducer for user preferences
 * @param state
 * @param action
 * @category Reducer
 */
export const reducer: Reducer<UserPreferencesEntity> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_USER_PREFERENCES.fulfilled: {
      return _.merge({}, state, payload);
    }
    case types.SET_USER_PREFERENCES.fulfilled: {
      return _.merge({}, state, payload);
    }
  }

  return state;
};

export default cleanup<UserPreferencesEntity>(initialState, sessionTypes.LOGOUT)(reducer);
