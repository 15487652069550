import * as _ from "lodash";
import { NormalizedSchema, schema } from "normalizr";

import { default as herdGroupsSchemas } from "../herd-groups/schemas";
import { HerdGroupList } from "../herd-groups";
import { default as dimGroupSchemas } from "../dim-groups/schemas";
import { default as penSchemas } from "../pens/schemas";
import { DIMGroupList } from "../dim-groups";
import { FarmEntity, FarmList } from "./typings";
import { PensList } from "../pens";

const farm = new schema.Entity(
  "farm",
  {
    dimGroups: new schema.Array(dimGroupSchemas.dimGroup),
    pens: new schema.Array(
      {
        pen: penSchemas.pen,
        herdGroup: herdGroupsSchemas.herdGroup
      },
      (input) => input.schema
    )
  },
  {
    processStrategy: ({ gpsCoordinates, farmId, id, ...rest }) => {
      const [lat, lon] = _.map(_.split(gpsCoordinates, ","), _.trim);

      return {
        gpsCoordinates: {
          lat: +lat,
          lon: +lon
        },
        id: farmId || id,
        ...rest
      };
    },
    idAttribute: ({ id, farmId }) => farmId || id
  }
);

const farmList = new schema.Entity("farmList", {
  farms: new schema.Array(farm)
});

export type NormalizedFarmList = NormalizedSchema<
  { farm: FarmList; herdGroup: HerdGroupList; dimGroup: DIMGroupList; pen: PensList },
  Array<FarmEntity["id"]>
>;

export default {
  farm,
  farmList
};
