import { schema } from "normalizr";
import * as _ from "lodash";

import { schemas as farmSchemas } from "../farms";
import { EventContext, EventSource, EventEntity } from "./typings";

const event = new schema.Entity(
  "event",
  { farm: farmSchemas.farm },
  {
    processStrategy: ({ context, ...rest }: EventSource): EventEntity => {
      return {
        ...rest,
        context: _.mapKeys(context, (v, k) =>
          _.endsWith(k, "Id") ? `${k.substr(0, k.length - 2)}ID` : k
        ) as EventContext
      };
    }
  }
);

const eventList = new schema.Array(event);

export default {
  event,
  eventList
};
