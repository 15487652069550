import { SupportedCountryCodes } from "@ctra/i18n";

import { Icon } from "../../../elements";
import styles from "./Flag.module.less";

const CDN_URL = "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/";

export interface FlagProps {
  /**
   * Supported country strings
   */
  countryCode: SupportedCountryCodes;
}

/**
 * Country Flag
 * @param countryCode
 * @constructor
 */
const Flag: React.FC<FlagProps> = ({ countryCode }) => {
  /**
   * gets flag url from cdn based on country code
   */
  const flagUrl = `${CDN_URL}${countryCode.toLowerCase()}.svg`;

  return <img className={styles.Flag} src={flagUrl} alt="flag" height="16" />;
};

export default Flag;
