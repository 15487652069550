import { createAsyncActions } from "@ctra/utils";
import { normalize } from "normalizr";
import * as _ from "lodash";

import schemas, { NormalizedMetricCollection } from "./schemas";
import { EnumList, MetricListResponse } from "./typings";
import types from "./types";

/**
 * Fetch metrics
 * @category Action
 */
const fetchMetrics = createAsyncActions(
  types.FETCH_METRICS,
  () => ({}),
  (metricListResponse: MetricListResponse): NormalizedMetricCollection => {
    const {
      entities: { dataDescriptor, metric, ...restOfEntities },
      ...restOfData
    }: NormalizedMetricCollection = normalize(metricListResponse, schemas.metricList);

    return {
      ...restOfData,
      entities: {
        dataDescriptor: _.mapValues(dataDescriptor, (dataDescriptor) => _.omit(dataDescriptor, "__source")),
        metric: _.mapValues(metric, (metric) => _.omit(metric, "__source")),
        ...restOfEntities
      }
    };
  },
  (error: string): { error: string } => ({ error })
);

/**
 * Fetch enums
 * @category Action
 */
const fetchEnums = createAsyncActions(
  types.FETCH_ENUMS,
  () => ({}),
  (response: EnumList) => response,
  (error: string): { error: string } => ({ error })
);

export default {
  fetchMetrics,
  fetchEnums
};
