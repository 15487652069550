import * as _ from "lodash";

type Mapped<A> = { [key in keyof A]: A[keyof A] };

/**
 * Omit undefined values from an object.
 * @param obj
 * @example
 * ```ts
 * const values = {
 *   a: 1,
 *   b: 2,
 *   c: undefined
 * };
 *
 * omitUndefined(values);
 * // { a: 1, b: 2 }
 * ```
 */
export const omitUndefined = <A extends Record<string, unknown>>(obj: A): Mapped<A> =>
  _.omitBy(obj, _.isUndefined) as Mapped<A>;

/**
 * Deep-omit undefined values from an object.
 * @param obj
 * @example
 * ```ts
 * const values = {
 *   a: 1,
 *   b: undefined;
 *   c: {
 *     d: 1,
 *     e: undefined
 *   }
 * }
 *
 * omitDeepUndefined(values);
 * // { a: 1, c: { d: 1 } }
 * ```
 */
export const omitDeepUndefined = <A extends Record<string, unknown>>(obj: A): Mapped<A> =>
  _.mapValues(omitUndefined(obj), (value) =>
    _.isPlainObject(value) ? omitDeepUndefined(value as Record<string, unknown>) : value
  ) as Mapped<A>;
