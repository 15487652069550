import { Reducer } from "@ctra/utils";

import { types as farmTypes } from "../farms";
import { cleanup } from "../../utils/reducer";
import { NormalizedFarmList } from "../farms/schemas";
import { DIMGroupList } from "./typings";

export const initialState: DIMGroupList = {};

/**
 * Herd group reducer
 * @param state
 * @param action
 */
const reducer: Reducer<DIMGroupList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case farmTypes.FETCH_FARM_LIST.fulfilled: {
      const {
        entities: { dimGroup }
      } = payload as NormalizedFarmList;

      return {
        ...state,
        ...dimGroup
      };
    }
  }

  return state;
};

export default cleanup<DIMGroupList>(initialState)(reducer);
