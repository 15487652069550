import { ComponentProps, FC } from "react";
import { Row, Col, Tabs, Typography, Space, Grid } from "antd";
import moment from "moment";
import * as _ from "lodash";

import { classname } from "@ctra/utils";
import styles from "./DateRangePicker.module.less";

const { Text } = Typography;

export interface DateRangePickerProps extends Omit<ComponentProps<typeof Row>, "onChange"> {
  /**
   * center date to manipulate
   */
  center: moment.Moment;
  /**
   * list of ISO time periods
   */
  durations: Array<string> | Array<{ duration: string; label: string }>;
  /**
   * current value
   */
  value: string;
  /**
   * change handler
   */
  onChange: (period: string) => void;
  /**
   * labels
   */
  labels: {
    before: string;
    after: string;
  };
}

/**
 * Date range picker component
 * @param {Array<string> | Array<{duration: string, label: string}>} durations
 * @param {(period: string) => void} onChange
 * @param {moment.Moment} center
 * @param {string} value
 * @param {{before: string, after: string}} labels
 * @param {string | undefined} className
 * @param {Omit<React.PropsWithChildren<DateRangePickerProps>, "onChange" | "center" | "durations" | "className" | "value" | "labels">} rest
 * @returns {JSX.Element}
 * @constructor
 */
const DateRangePicker: FC<DateRangePickerProps> = ({
  durations,
  onChange,
  center,
  value,
  labels,
  className,
  ...rest
}) => {
  const valueDuration = moment.duration(value);
  const { md } = Grid.useBreakpoint();

  /**
   * Start and end labels
   */
  const [startLabel, endLabel] = [
    center.clone().subtract(valueDuration).format("LL"),
    center.clone().add(valueDuration).format("LL")
  ];

  /**
   * Center label
   * @type {string}
   */
  const centerLabel = center.format("LL");

  /**
   * Make tabs
   * @type {{key: string, label: string}[]}
   */
  const tabs = _.map<typeof durations[number], { key: string; label: string }>(durations, (duration) => {
    if (typeof duration === "string") {
      return {
        key: duration,
        label: moment.duration(duration).humanize()
      };
    } else {
      const { duration: durationString, label } = duration;

      return {
        key: durationString,
        label
      };
    }
  });

  return (
    <Row
      gutter={[16, md ? 0 : 4]}
      align="middle"
      className={classname("ctra-impactTracking-dateRangePicker", styles.DateRangePicker, className)}
      {...rest}
    >
      <Col {...(md ? {} : { span: 24 })}>
        <div className={styles.DateRange}>
          <Space>
            <Text className={styles.Label}>{labels.before}</Text>
            <Text className={styles.Date}>
              {startLabel} - {centerLabel}
            </Text>
          </Space>
        </div>
      </Col>
      <Col {...(md ? {} : { span: 24 })}>
        <div className={styles.DateRange}>
          <Space>
            <Text className={styles.Label}>{labels.after}</Text>
            <Text className={styles.Date}>
              {centerLabel} - {endLabel}
            </Text>
          </Space>
        </div>
      </Col>
      <Col className={styles.Tabs} {...(md ? {} : { span: 24 })}>
        <Tabs activeKey={value} items={tabs} onChange={onChange} />
      </Col>
    </Row>
  );
};

export default DateRangePicker;
