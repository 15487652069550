import { createModule, from, makeAsset } from "../../utils";

export const overview = createModule("overview", {
  ...from("sandboxAlert", "noFarms"),
  noFarms: {
    ...from("sider", "title"),
    farmer: from("step", "content", "event", "cta"),
    advisor: from("content", "ctaPlainText", "ctaHyperlink"),
    explorer: from("content", "ctaPlainText", "ctaHyperlink")
  },
  integrationPending: {},
  inviteFarm: from("label", "submit", "success", "error"),
  pageIntro: from("greeting", "description"),
  invitation: from("title", "subTitle", "description", "cta"),
  farmSelector: from("allFarms"),
  farmOverview: {
    ...from("loading", "header", "preview"),
    goto: makeAsset,
    insights: from("header"),
    gamePlans: from("header"),
    tasks: from("header"),
    empty: from("description")
  },
  panel: {
    ...from("loading", "header", "title"),
    chart: {
      ...from("panelHeader", "itemHeader"),
      empty: from("title", "description")
    },
    timeline: {
      ...from("panelHeader", "itemHeader"),
      empty: from("title", "description")
    },
    insights: {
      ...from("panelHeader", "itemHeader"),
      empty: from("title", "description")
    },
    gamePlan: {
      ...from("panelHeader", "itemHeader"),
      labels: from("kpi", "target", "progress"),
      empty: from("title", "description")
    },
    askIda: {
      ...from("panelHeader", "itemHeader"),
      empty: from("title", "description")
    }
  },
  carousel: {
    ...from("title", "description", "cta"),
    listItem: makeAsset,
    imageDescription: makeAsset
  }
});
