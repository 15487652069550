import { normalize } from "normalizr";

import { createAsyncActions } from "@ctra/utils";

import { AnnotationResultsResponse } from "./typings";
import types from "./types";
import schemas from "./schemas";

/**
 * Fetch annotation results
 * @category Action
 */
const fetchAnnotationResults = createAsyncActions(
  types.FETCH_ANNOTATION_RESULTS,
  (annotationID: string) => ({ annotationID }),
  (annotationID: string, annotationResults: AnnotationResultsResponse) => ({
    ...normalize(annotationResults, schemas.annotationResult),
    annotationID
  }),
  (annotationID, error: string) => ({
    annotationID,
    error
  })
);

/**
 * Fetch annotation results
 * @category Action
 */
const addMetricToEvent = createAsyncActions(
  types.ADD_METRIC,
  (annotationID: string, dataDescriptorID: string) => ({ annotationID, dataDescriptorID }),
  (annotationID: string) => ({
    annotationID
  }),
  (annotationID, error: string) => ({
    annotationID,
    error
  })
);

export default {
  fetchAnnotationResults,
  addMetricToEvent
};
