import { createModule, from, makeAsset } from "../../utils";

export const iofc = createModule("iofc", {
  page: {
    ...from("title"),
    alert: from("title", "description")
  },
  form: {
    ...from("title", "empty"),
    validation: from("required", "sum", "typeNumber"),
    label: from(
      "range",
      "avgFeedCost",
      "avgMilkPrice",
      "milkYieldSource",
      "milkFatSource",
      "milkProteinSource",
      "submit",
      "add",
      "remove",
      "scenario",
      "simulation",
      "milkPickups",
      "milkClass",
      "classWeight",
      "classWeightLegend"
    )
  },
  chart: {
    ...from("title", "scenario", "today"),
    kpi: makeAsset
  }
});
