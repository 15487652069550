import { createModule, from, makeAsset } from "../../utils";

export const gettingStarted = createModule("gettingStarted", {
  ...from("title", "subtitle", "description", "prev", "next", "skip", "final", "loader"),
  navigation: from("accountDetails", "farmDetails", "appointment", "dataIntegration", "dhms"),
  accountDetails: {
    ...from("title", "description"),
    contactDetails: {
      ...from("title"),
      form: {
        ...from("required", "unknownCountry"),
        labels: from("firstName", "lastName", "country", "email", "phone", "submit")
      }
    },
    subscription: {
      ...from("title", "confirm"),
      tier: from("title", "description")
    }
  },
  farmDetails: {
    ...from("title", "description"),
    form: {
      ...from("title", "required", "unknownCountry"),
      labels: from("name", "address", "city", "postCode", "country", "submit")
    }
  },
  dhms: {
    ...from("title", "description", "alert"),
    form: {
      ...from("title", "smallPrint", "link", "alert", "required"),
      labels: from("dhms", "other", "checkbox", "submit")
    }
  },
  appointment: {
    ...from("title", "description"),
    content: from("title", "description")
  },
  success: {
    ...from("title", "description"),
    eventScheduled: from("title", "description")
  },
  dataIntegration: {
    ...from("title", "description", "loader", "confirm"),
    steps: {
      ...from("title", "description"),
      alert: from("sameComputer", "doNotClose"),
      download: from("title", "description"),
      open: from("title", "description"),
      join: {
        ...from("title", "description"),
        call: from("text", "link")
      }
    },
    carousel: {
      ...from("title", "description", "cta"),
      listItem: makeAsset,
      imageDescription: makeAsset
    },
    result: {
      ...from("title", "description"),
      alert: from("title", "description", "button", "link")
    }
  }
});
