import * as _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react";

import { Optional } from "@ctra/utils";

type EntityID = number | string;

interface ContextType {
  /**
   * Current ID
   */
  current: Optional<EntityID>;
  meta: {
    /**
     * Current index
     */
    currentIdx: number;
    /**
     * List of IDs
     */
    list: Array<EntityID>;
  };
  api: {
    /**
     * Current ID setter
     */
    setCurrent: Dispatch<SetStateAction<Optional<EntityID>>>;
    /**
     * List setter
     */
    setList: Dispatch<SetStateAction<Array<EntityID>>>;
    /**
     * Next index and ID
     */
    next: () => void;
    /**
     * Previous index and ID
     */
    prev: () => void;
  };
}

interface CarouselContextProps {
  /**
   * List of IDs
   */
  list: Array<EntityID>;
  /**
   * change callback on the carousel
   */
  onChange?: (current: unknown) => void;
}

const DefaultContext = createContext<ContextType>({
  current: undefined,
  meta: {
    currentIdx: 0,
    list: []
  },
  api: {
    setCurrent: _.noop,
    setList: _.noop,
    next: _.noop,
    prev: _.noop
  }
});

/**
 * Carousel context: carousel control center
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @param {Array<EntityID>} entityIDList
 * @param {((current: unknown) => void) | undefined} onChange
 * @return {JSX.Element}
 */
const _CarouselContextProvider: FC<PropsWithChildren<CarouselContextProps>> = ({
  children,
  list: entityIDList,
  onChange
}) => {
  const [list, setList] = useState<Array<EntityID>>(entityIDList);
  const [current, setCurrent] = useState<Optional<EntityID>>(_.first(list));

  /**
   * Look for list changes
   */
  useDeepCompareEffect(() => {
    if (entityIDList?.length) {
      setList(entityIDList);

      if (!current || !_.includes(entityIDList, current)) {
        setCurrent(_.first(entityIDList));
      }
    } else {
      setList([]);
      setCurrent(void 0);
    }
  }, [entityIDList, current]);

  /**
   * Move the pointer to the next of the previous item
   * @param {-1 | 1} direction
   * @return {() => void}
   */
  const move = (direction: -1 | 1) => () => {
    const total = list?.length;
    const currentIdx = _.findIndex(list, (id) => id === current);
    const next = list[_.clamp(currentIdx + direction, 0, total - 1)];

    if (_.isFunction(onChange)) {
      onChange(next);
    }

    setCurrent(next);
  };

  return (
    <DefaultContext.Provider
      value={{
        current,
        meta: { currentIdx: _.findIndex(list, (id) => id === current), list },
        api: { setList, setCurrent, next: move(1), prev: move(-1) }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const CarouselContext = {
  Provider: _CarouselContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Make a hook to access the context in FCs
 */
export const useCarousel = (): ContextType => useContext(DefaultContext);
