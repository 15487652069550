import { Nullable } from "@ctra/utils";

import { UserEntity } from "../user";
import { ErrorSource } from "../../typings";

export interface LoginFormValues {
  username: NonNullable<UserEntity["username"]>;
  password: string;
  rememberMe: boolean;
  impersonating?: NonNullable<Array<string>>;
}

export interface ForgotPasswordFormValues {
  username: NonNullable<UserEntity["username"]>;
}

export enum AdvisorType {
  veterinarian = "veterinarian",
  nutritionist = "nutritionist",
  repro = "repro",
  hygiene = "hygiene",
  dairyField = "dairy-field",
  seed = "seed",
  financial = "financial",
  overall = "overall"
}

export interface UserRegistrationFormValues {
  opportunityID: string;
  email: UserEntity["username"];
  companyName: Nullable<string>;
  firstName: UserEntity["firstName"];
  lastName: UserEntity["lastName"];
  password: Nullable<string>;
  confirmPassword: Nullable<string>;
  phoneNumber: Nullable<number>;
  subType: Nullable<AdvisorType>;
}

export interface UserSignupFormValues {
  email: NonNullable<UserEntity["username"]>;
  firstName: NonNullable<UserEntity["firstName"]>;
  lastName: NonNullable<UserEntity["lastName"]>;
  phoneNumber?: number;
  confirmPassword?: string;
  password?: string;
  eulaAccepted: boolean;
  "g-recaptcha-response": string;
}

export interface ResetPasswordFormValues {
  confirmPassword: string;
  password: string;
  otp: string;
  username: NonNullable<UserEntity["username"]>;
  userId: NonNullable<UserEntity["id"]>;
}

export interface TokenEntity {
  accessToken: Nullable<string>;
  expiresIn: Nullable<number>;
  tokenType: Nullable<string>;
  refresh: Nullable<string>;
  issued: Nullable<string>;
  expires: Nullable<string>;
  refreshTokenExpires?: Nullable<string>;
  refreshTokenExpiresIn?: Nullable<number>;
}

export interface AuthEntity {
  token: TokenEntity;
  user: Nullable<UserEntity["username"]>;
  impersonating?: boolean;
}

export interface AuthSource {
  username: Nullable<UserEntity["username"]>;
  impersonating: Nullable<UserEntity["username"]>;
  token: Nullable<string>;
  expirationEpoch: Nullable<number>;
  expirationDate: Nullable<string>;
  refreshToken: Nullable<string>;
  refreshTokenExpirationDate: Nullable<string>;
  refreshTokenExpirationEpoch: Nullable<number>;
}

export interface RenewTokenValues {
  currentToken: Nullable<string>;
  refreshToken: Nullable<string>;
  provider?: Nullable<string>;
}

export interface AuthState extends AuthEntity {
  logout: boolean;
  error: Nullable<ErrorSource>;
}
