import { Epic, StateObservable, ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AjaxResponse } from "rxjs/ajax";
import { of, Observable } from "rxjs";
import { Action } from "redux";
import _ from "lodash";

import { Debug } from "@ctra/utils";

import { EnterpriseAppState } from "../../enterprise";
import { makeAzureApiURL, Request as R } from "../../utils/ajax";
import { IOFCResponse } from "./typings";
import actions from "./actions";
import types from "./types";

/**
 * Request IOFC data
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchIOFCData: Epic = (
  action$: Observable<Action>,
  state$: StateObservable<EnterpriseAppState>,
  { Request }: { Request: typeof R }
): Observable<Action> =>
  action$.pipe(
    ofType(types.FETCH_IOFC_DATA.pending),
    mergeMap<ReturnType<typeof actions.fetchIOFCData.start>, Observable<Action>>(
      ({ payload: { scenario, farmId, startDate, endDate, ...rest } }) =>
        Request.POST<IOFCResponse>(
          makeAzureApiURL("analytics", "/labs/iofc/farm/<%= farmId %>")({ farmId }),
          {
            body: {
              startDate: startDate.replace(/[Z]/g, ""),
              endDate: endDate.replace(/[Z]/g, ""),
              ...rest
            }
          }
        ).pipe(
          map<AjaxResponse<IOFCResponse>, Action>(({ response }) => {
            return actions.fetchIOFCData.fulfill(scenario, response);
          }),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            if (error) {
              Debug.iofcApi.error(error);
            }

            return of(actions.fetchIOFCData.reject(scenario, error, statusCode, details));
          })
        )
    )
  );

export default {
  fetchIOFCData
};
