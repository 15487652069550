import React, { FC, useEffect, useState, createContext, useContext } from "react";
import * as _ from "lodash";

import { Nullable, Cookies } from "@ctra/utils";
import { getStorageKey } from "../../utils/versioning";

interface ContextType {
  event: Nullable<{ start_time: string }>;
  meta: {
    isLoading: boolean;
  };
  api: {
    getEvent: () => void;
    setEvent: (eventID: string) => void;
  };
}

const DefaultContext = createContext<ContextType>({
  event: null,
  meta: {
    isLoading: false
  },
  api: {
    getEvent: _.noop,
    setEvent: _.noop
  }
});

/**
 * Calendly event context provider
 */
const _CalendlyAppointmentProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventInfo, setEventInfo] = useState<ContextType["event"]>(null);

  const tkn =
    "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzEzNTE3MDM2LCJqdGkiOiI1N2VjY2FkNS0yMjk5LTRhYzUtOTYyNS1lOTVmYzZkNjQ3NzAiLCJ1c2VyX3V1aWQiOiI2ODA2MWQwMC00YTNiLTQ2MmUtOGM2YS0xZmQwOTFmM2EyYzIifQ.8denerJtVnX2D6CohAurfb5NX6FiVm8AjOv_V7HHcgiKaNuilU1SeD1U3ouYmzScrHMVXehsZPkKbzXZ4976eQ";

  /**
   * Set event details
   * @return {Promise<void>}
   */
  const getEvent = async () => {
    const key = `${getStorageKey()}.appointment`;
    const eventID = Cookies.get(key);

    setIsLoading(true);

    fetch(`https://api.calendly.com/scheduled_events/${eventID}`, {
      headers: { Authorization: `Bearer ${tkn}` }
    })
      .then((response) => response.json())
      .then(({ resource }) => setEventInfo(resource))
      .finally(() => setIsLoading(false));
  };

  /**
   * Set event details
   * @param {string} eventID
   */
  const setEvent = (eventID: string) => {
    const key = `${getStorageKey()}.appointment`;
    Cookies.set(key, eventID);

    getEvent();
  };

  useEffect(() => {
    const key = `${getStorageKey()}.appointment`;
    const eventID = Cookies.get(key);

    if (eventID) {
      getEvent();
    }
  }, []);

  return (
    <DefaultContext.Provider
      value={{
        event: eventInfo,
        meta: {
          isLoading
        },
        api: {
          getEvent,
          setEvent
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const CalendlyAppointmentContext = {
  Provider: _CalendlyAppointmentProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Use calendly event
 */
export const useCalendlyAppointment = (): ContextType => useContext(DefaultContext);
