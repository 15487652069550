const ns = "app/session";

/**
 * Log out
 * @category Type
 */
const LOGOUT = `${ns}/LOGOUT`;

/**
 * Reset session
 * @category Type
 */
const RESET = `${ns}/RESET`;

export default {
  LOGOUT,
  RESET
};
