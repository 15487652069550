import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";
import { v4 } from "uuid";

import { Action } from "@ctra/utils";

import { makeAzureApiURL, withSandboxPrefix } from "../../utils/ajax";
import actions, { FetchScorecardValuePendingPayload } from "./actions";
import { ScorecardValueSource } from "./typings";
import types from "./types";

// type FetchScorecardValueResponse = {
//   id: number;
//   value: number;
//   farmID: number;
//   fieldName: string;
// };

/**
 * Fetch a KPI value (scorecard current average)
 * @param action$
 * @param state$
 * @param Request
 */
const fetchScorecardValue: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_SCORECARD_VALUE.pending),
    mergeMap(
      ({
        payload: { hash, dataDescriptorID, farmID, unitSystem, date }
      }: {
        payload: FetchScorecardValuePendingPayload;
      }) =>
        Request.GET(
          makeAzureApiURL(
            withSandboxPrefix("analytics", state$),
            "/compat/descriptors/<%= dataDescriptorID %>/value"
          )({ dataDescriptorID }),
          {
            body: {
              unitSystem,
              date,
              farmId: farmID
            }
          }
        ).pipe(
          map<{ status: number; response: ScorecardValueSource }, Action>(({ status, response }) =>
            actions.fetchScorecardValue.fulfill(
              hash,
              status === 204
                ? {
                    data: {
                      [farmID]: { id: v4(), value: null, date: new Date().toDateString() }
                    },
                    meta: {}
                  }
                : response
            )
          ),
          catchError<unknown, Observable<Action>>((error) => {
            const { status } = error;
            return of(
              status === 404
                ? actions.fetchScorecardValue.fulfill(hash, {
                    data: {
                      [farmID]: { id: v4(), value: null, date: new Date().toDateString() }
                    },
                    meta: {}
                  })
                : actions.fetchScorecardValue.reject(hash, error)
            );
          })
        )
    )
  );

export default {
  fetchScorecardValue
};
