import { ComponentProps, FC } from "react";
import { Collapse } from "antd";

import { classname } from "@ctra/utils";

import styles from "./SectionPanel.module.less";

const { Panel } = Collapse;

export type SectionPanelProps = ComponentProps<typeof Panel>;

/**
 * Analytics section panel component
 * @param {string | undefined} className
 * @param {Pick<CollapsePanelProps & {children?: React.ReactNode | undefined}, "key" | "header" | "disabled" | "style" | "showArrow" | "prefixCls" | "forceRender" | "id" | "extra" | "collapsible" | "children">} props
 * @return {JSX.Element}
 * @constructor
 */
const SectionPanel: FC<SectionPanelProps> = ({ className, ...props }) => {
  return (
    <Panel
      className={classname("ctra-analytics-sectionPanel", styles.SectionPanel, className)}
      {...props}
      showArrow={false}
    />
  );
};

export default SectionPanel;
