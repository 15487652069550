import { Observable, of } from "rxjs";
import { switchMap, map, catchError, mergeMap } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";
import * as _ from "lodash";

import { Action, Nullable, Debug } from "@ctra/utils";

import { makeAzureApiURL } from "../../utils/ajax";
import types from "./types";
import actions from "./actions";
import { InvitationType, ReferralSource, ReferralType } from "./typings";

/**
 * Fetch invitations list
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchInvitationList: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_INVITATION_LIST.pending),
    switchMap<ReturnType<typeof actions.fetchInvitationList.start>, Observable<Promise<unknown>>>(() => {
      Debug.referralApi.info("Fetching invitation list");

      return Request.GET(makeAzureApiURL("accounts", "/self/invitations")()).pipe(
        map<{ response: Array<ReferralSource> }, Action>(({ response }) => {
          Debug.referralApi.info("Fetched invitation list");

          return actions.fetchInvitationList.fulfill(response);
        }),
        catchError<
          { status: number; message: string; response: Nullable<{ error: string; statusCode: number }> },
          Observable<Action>
        >(({ response, message, status }) => {
          Debug.referralApi.error("Failed to fetch invitation list", { response, message, status });

          return of(
            actions.fetchInvitationList.reject(response ? response : { statusCode: status, error: message })
          );
        })
      );
    })
  );

/**
 * Fetch a single invitation
 * @param action$
 * @param state$
 * @param Request
 */
const fetchInvitation: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_INVITATION.pending),
    switchMap<ReturnType<typeof actions.fetchInvitation.start>, Observable<Promise<unknown>>>(
      ({ payload: { invitationId } }) => {
        Debug.referralApi.info(`Fetching invitation by id ${invitationId}`);

        return Request.GET(
          makeAzureApiURL(
            "accounts",
            "/invitations/<%= id %>"
          )({
            id: invitationId
          })
        ).pipe(
          map<{ response: { metadata: Record<string, unknown>; invitation: ReferralSource } }, Action>(
            ({
              response: {
                invitation,
                metadata: { farms }
              }
            }) => {
              Debug.referralApi.info("Fetched invitation", { invitation });

              return actions.fetchInvitation.fulfill({
                ...invitation,
                farmName: _.get(farms, invitation.farmId)
              });
            }
          ),
          catchError<
            { status: number; message: string; response: Nullable<{ error: string; statusCode: number }> },
            Observable<Action>
          >(({ response, message, status }) => {
            Debug.referralApi.error("Failed to fetch invitation", { response, message, status });

            return of(
              actions.fetchInvitation.reject(
                response
                  ? { ...response, invitationId }
                  : { invitationId, statusCode: status, error: message }
              )
            );
          })
        );
      }
    )
  );

/**
 * Fetch a single invitation
 * @param action$
 * @param state$
 * @param Request
 */
const fetchInvitationByCode: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_INVITATION_BY_CODE.pending),
    switchMap<ReturnType<typeof actions.fetchInvitationByCode.start>, Observable<Promise<unknown>>>(
      ({ payload: { invitationCode } }) => {
        Debug.referralApi.info(`Fetching invitation by code ${invitationCode}`);

        return Request.GET(
          makeAzureApiURL(
            "accounts",
            "/invitations/<%= code %>"
          )({
            code: invitationCode
          })
        ).pipe(
          map<{ response: { metadata: Record<string, unknown>; invitation: ReferralSource } }, Action>(
            ({
              response: {
                invitation,
                metadata: { farms }
              }
            }) => {
              Debug.referralApi.info("Fetched invitation", { invitation });

              return actions.fetchInvitationByCode.fulfill({
                ...invitation,
                farmName: _.get(farms, invitation.farmId)
              });
            }
          ),
          catchError<
            { status: number; message: string; response: Nullable<{ error: string; statusCode: number }> },
            Observable<Action>
          >(({ response, message, status }) => {
            Debug.referralApi.error("Failed to fetch invitation", { response, message, status });

            return of(
              actions.fetchInvitationByCode.reject(
                response
                  ? { ...response, invitationCode }
                  : { invitationCode, statusCode: status, error: message }
              )
            );
          })
        );
      }
    )
  );

/**
 * Create a new invitation
 * @param action$
 * @param state$
 * @param Request
 */
const createInvitation: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.CREATE_INVITATION.pending),
    mergeMap<ReturnType<typeof actions.createInvitation.start>, Observable<Promise<unknown>>>(
      ({ payload: { invitationType, ...targetUser } }) => {
        Debug.referralApi.info("Creating invitation", { targetUser });

        return Request.POST(makeAzureApiURL("accounts", "/self/invitations")(), {
          body: {
            invitationType,
            targetUser
          }
        }).pipe(
          map<{ response: { invitation: ReferralSource } }, Action>(({ response: { invitation } }) => {
            Debug.referralApi.info("Created invitation", { invitation });

            return actions.createInvitation.fulfill(invitation);
          }),
          catchError<
            { status: number; message: string; response: Nullable<{ error: string; statusCode: number }> },
            Observable<Action>
          >(({ response, message, status }) => {
            Debug.referralApi.error("Failed to create invitation", { response, message, status });

            return of(
              actions.createInvitation.reject(
                response
                  ? response
                  : {
                      statusCode: status,
                      error: message
                    }
              )
            );
          })
        );
      }
    )
  );

/**
 * Delete an invitation
 * @param action$
 * @param state$
 * @param Request
 */
const deleteInvitation: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.DELETE_INVITATION.pending),
    mergeMap<ReturnType<typeof actions.deleteInvitation.start>, Observable<Promise<unknown>>>(
      ({ payload: { invitationId } }) => {
        Debug.referralApi.info(`Deleting invitation ${invitationId}`);

        return Request.DELETE(
          makeAzureApiURL("accounts", "/self/invitations/<%= id %>")({ id: invitationId })
        ).pipe(
          map<unknown, Action>(() => {
            Debug.referralApi.info("Deleted invitation", invitationId);

            return actions.deleteInvitation.fulfill(invitationId);
          }),
          catchError<
            { status: number; message: string; response: Nullable<{ error: string; statusCode: number }> },
            Observable<Action>
          >(({ response, message, status }) => {
            Debug.referralApi.error("Failed to delete invitation", { response, message, status });

            return of(
              actions.deleteInvitation.reject(
                response
                  ? { ...response, invitationId }
                  : { invitationId, statusCode: status, error: message }
              )
            );
          })
        );
      }
    )
  );

/**
 * Update an invitation
 * @param action$
 * @param state$
 * @param Request
 */
const updateInvitation: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_INVITATION.pending),
    mergeMap<ReturnType<typeof actions.updateInvitation.start>, Observable<Promise<unknown>>>(({ payload }) =>
      Request.PUT(makeAzureApiURL("accounts", `/self/invitations/<%= id %>`)({ id: payload.invitationId }), {
        body: payload
      }).pipe(
        map<{ response: { invitation: ReferralSource } }, Action>(({ response: { invitation } }) =>
          actions.updateInvitation.fulfill(invitation)
        ),
        catchError<
          { status: number; message: string; response: Nullable<{ error: string; statusCode: number }> },
          Observable<Action>
        >(({ response, message, status }) =>
          of(
            actions.updateInvitation.reject(
              response
                ? { ...response, invitationId: payload.invitationId }
                : { invitationId: payload.invitationId, statusCode: status, error: message }
            )
          )
        )
      )
    )
  );

/**
 * Accept an invitation
 * @param action$
 * @param state$
 * @param Request
 */
const acceptInvitation: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.ACCEPT_INVITATION.pending),
    mergeMap<ReturnType<typeof actions.acceptInvitation.start>, Observable<Promise<unknown>>>(
      ({ payload: { invitationCode } }) =>
        Request.PUT(makeAzureApiURL("accounts", `/invitations/<%= invitationCode %>`)({ invitationCode }), {
          body: { isAccepted: true }
        }).pipe(
          map<{ response: ReferralSource }, Action>(({ response }) =>
            actions.acceptInvitation.fulfill(response)
          ),
          catchError<{ response: { error: string; statusCode: number } }, Observable<Action>>(
            ({ response }) => of(actions.acceptInvitation.reject(response))
          )
        )
    )
  );

export default {
  fetchInvitationByCode,
  fetchInvitationList,
  fetchInvitation,
  createInvitation,
  deleteInvitation,
  updateInvitation,
  acceptInvitation
};
