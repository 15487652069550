import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import { DataDictionaryState } from "./typings";
import { NormalizedMetricCollection } from "./schemas";

export const initialState: DataDictionaryState = {
  metrics: {},
  dataDescriptors: {},
  enums: {}
};

/**
 * Data dictionary reducer
 * @param {DataDictionaryState} state
 * @param action
 * @return {{dataDescriptors: DataDescriptorList, metrics: MetricCollection} | DataDictionaryState}
 */
export const reducer: Reducer<DataDictionaryState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_METRICS.fulfilled: {
      const {
        entities: { dataDescriptor, metric }
      } = payload as NormalizedMetricCollection;

      return {
        ...state,
        dataDescriptors: dataDescriptor,
        metrics: metric
      };
    }

    case types.FETCH_ENUMS.fulfilled: {
      return {
        ...state,
        enums: payload
      };
    }
  }

  return state;
};

export default cleanup<DataDictionaryState>(initialState)(reducer);
