import { memoize, Optional } from "@ctra/utils";
import { Enterprise } from "../../../enterprise";
import { HerdGroupEntity } from "../../herd-groups";
import { Base } from "./Base";

class HerdGroupSeries extends Base {
  protected entity: Optional<HerdGroupEntity>;

  constructor(id: number) {
    super(id);

    try {
      this.entity = Enterprise.entities.getHerdGroup(Enterprise.store.getState(), { id });
    } catch (e) {}
  }

  @memoize
  getField(): Optional<string> {
    return this.entity?.name;
  }
}

export { HerdGroupSeries };
