import { ComponentProps, FC } from "react";
import { Row, Col } from "antd";
import * as _ from "lodash";

import { Typography } from "../../../elements";
import { classname, Nmbr, Nullable } from "@ctra/utils";

import styles from "./Bar.module.less";

const { Text } = Typography;

export interface BarProps extends ComponentProps<typeof Row> {
  /**
   * bar label
   */
  value?: Nullable<number | string>;
  /**
   * bar label
   */
  unit?: string;
  /**
   * width
   */
  width?: number;
  /**
   * variant
   */
  variant?: "light" | "dark" | "neon" | "lightGray" | "darkGray";
}
/**
 * Bar component for showing value change
 * @param {string} variant
 * @param {number | string | null | undefined} value
 * @param {string | undefined} unit
 * @param {string | undefined} className
 * @param {number} width
 * @param {Omit<React.PropsWithChildren<BarProps>, "variant" | "width" | "unit" | "className" | "value">} rest
 * @returns {JSX.Element}
 * @constructor
 */
const Bar: FC<BarProps> = ({ variant = "light", value, unit, className, width = 100, ...rest }) => (
  <Row
    className={classname(
      styles.Bar,
      variant === "light" ? styles.Light : null,
      variant === "neon" ? styles.Neon : null,
      variant === "dark" ? styles.Dark : null,
      variant === "lightGray" ? styles.LightGray : null,
      variant === "darkGray" ? styles.DarkGray : null,
      className
    )}
    style={{ width: `${_.isNumber(width) ? width : 100}%` }}
    align="middle"
    {...rest}
  >
    <Col>
      <Text>{_.isNumber(value) ? Nmbr.format(value) : _.isNil(value) ? "\u00A0" : value}</Text>
      {_.isNumber(value) && !!unit && (
        <>
          {" "}
          <Text>{unit}</Text>
        </>
      )}
    </Col>
  </Row>
);

export default Bar;
