import { createAsyncActions } from "@ctra/utils";

import { IOFCRequestPayload, IOFCResponse } from "./typings";
import types from "./types";

/**
 * Fetch IOFC data
 */
const fetchIOFCData = createAsyncActions(
  types.FETCH_IOFC_DATA,
  (payload: IOFCRequestPayload & { scenario: number }) => payload,
  (scenario: number, response: IOFCResponse) => ({
    ...response,
    scenario
  }),
  (scenario, error, statusCode, details) => ({
    scenario,
    error,
    statusCode,
    details
  })
);

export default {
  fetchIOFCData
};
