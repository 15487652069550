import { Nullable } from "@ctra/utils";

import { UserEntity } from "../user";
import { DairyCowEntity } from "../cows";
import { FarmEntity } from "../farms";
import { GenericInsightEntity } from "../insights";
import { HerdGroupEntity } from "../herd-groups";

export enum GenericLabelScope {
  herdGroup = "herd",
  farm = "farm",
  cow = "cow"
}

export interface GenericLabelSource {
  GenericLabelID: number;
  category: string;
  createdByUserID: UserEntity["id"];
  createdEpoch: number;
  customerVisible: boolean;
  dairyCowID: Nullable<DairyCowEntity["id"]>;
  description: string;
  endEpoch: number;
  farmID: FarmEntity["id"];
  genericInsightID: Nullable<GenericInsightEntity["id"]>;
  herdGroupID: Nullable<HerdGroupEntity["id"]>;
  labelScope: GenericLabelScope;
  startEpoch: number;
  tags: string;
  title: string;
  id?: string;
}
