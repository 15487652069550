import { createModule, from, makeAsset } from "../../utils";

export const askIda = createModule("askIda", {
  question: {
    heading: "heading",
    description: "description",
    title: "title",
    change: makeAsset,
    effect: makeAsset,
    createdOn: "createdOn",
    archived: "archived"
  },
  answer: {
    ...from("empty", "heading", "title", "description", "more"),
    change: from("before", "expected", "after", "duration", "weight"),
    effect: {
      description: makeAsset
    }
  },
  createTracker: {
    ...from("loading", "success", "fail", "close"),
    actions: from("cancel", "confirm", "submit"),
    steps: {
      ...from("rollback"),
      title: makeAsset
    },
    configure: {
      ...from("kpiLead", "changeDirectionLead", "dateTimeLead"),
      effectDescription: makeAsset,
      placeholders: from("change", "kpi"),
      labels: {
        ...from("kpi", "addRow", "next", "beta"),
        change: makeAsset
      }
    },
    summary: from("title", "description", "date"),
    activateInsight: from("title", "description", "cta")
  },
  noData: from("message", "description")
});
