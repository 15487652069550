// istanbul ignore file
import objectHash from "object-hash";

import {
  Auth,
  DairyCowSource,
  EventEntity,
  Events,
  EventScope,
  FarmEntity,
  Farms,
  GenericInsightEntity,
  HerdGroupEntity,
  InsightCategory,
  InsightResolutionEntity,
  InsightResolutionType,
  Insights,
  InsightType,
  InsightValidation,
  Preferences,
  SandboxInfo,
  FarmListResponse,
  User
} from "../modules";

import store from "../enterprise/store";
import resolutions from "../modules/insights/insightResolutions.json";

import * as Mocks from "./mocks";

/**
 * Make an entitifier function
 * @param entity
 */
const entitifier =
  (entity: string) =>
  (suffix?: string | number): string =>
    `${entity}-id${suffix ? `-${suffix}` : ""}`;

/**
 * Make a number
 * @return {(nr?: T) => (1 | T)}
 */
const numerifier =
  <T extends number>(): ((nr?: T) => T | 1) =>
  (nr?: T) =>
    nr || 1;

/**
 * Frequently used entity identifiers
 */
export const Entitifiers = {
  dataDescriptor: entitifier("data-descriptor"),
  user: entitifier("user"),
  task: entitifier("task"),
  chart: entitifier("chart"),
  layout: entitifier("layout"),
  section: entitifier("section"),
  chartContainer: entitifier("chart-container"),
  dimGroup: entitifier("dim-group"),
  insightList: entitifier("insight-list"),
  insightTypeName: entitifier("insight-type-name"),
  event: entitifier("event"),
  label: numerifier(),
  insight: numerifier<GenericInsightEntity["id"]>(),
  herdGroup: numerifier<HerdGroupEntity["id"]>(),
  farm: numerifier<FarmEntity["id"]>(),
  userEmail: "user@email.com"
};

/**
 * Add the login info to the store
 */
export const login = (): void => {
  store.dispatch(Auth.actions.requestToken.start(Entitifiers.userEmail, "password", false));
};

/**
 * Make a list of farms
 */
export const makeFarmList = (): void => {
  store.dispatch(
    Farms.actions.fetchFarmList.fulfill(
      Mocks.Farms.farmListResponse([
        Mocks.Farms.farmResponse({
          id: Entitifiers.farm(),
          herdGroups: [
            Mocks.HerdGroups.herdGroupResponse({
              id: Entitifiers.herdGroup(),
              name: "Lollercoaster"
            }),
            Mocks.HerdGroups.herdGroupResponse({
              id: Entitifiers.herdGroup(2)
            }),
            Mocks.HerdGroups.herdGroupResponse({
              id: Entitifiers.herdGroup(3)
            })
          ]
        }),
        Mocks.Farms.farmResponse({
          id: Entitifiers.farm(2),
          herdGroups: [
            Mocks.HerdGroups.herdGroupResponse({
              id: Entitifiers.herdGroup(4)
            }),
            Mocks.HerdGroups.herdGroupResponse({
              id: Entitifiers.herdGroup(5)
            }),
            Mocks.HerdGroups.herdGroupResponse({
              id: Entitifiers.herdGroup(6)
            })
          ]
        }),
        Mocks.Farms.farmResponse({
          id: Entitifiers.farm(3)
        }),
        Mocks.Farms.farmResponse({
          id: Entitifiers.farm(4)
        }),
        Mocks.Farms.farmResponse({
          id: Entitifiers.farm(5)
        }),
        Mocks.Farms.farmResponse({
          id: Entitifiers.farm(6)
        })
      ]) as unknown as FarmListResponse
    )
  );
};

/**
 * Make users
 */
export const makeUserList = (): void => {
  store.dispatch(
    User.actions.fetchUserList.fulfill(-1, {
      userGroupMembers: [],
      farmMembers: [],
      allMembers: [
        Mocks.User.userResponse({
          userID: Entitifiers.user(),
          userName: Entitifiers.userEmail,
          email: Entitifiers.userEmail
        }),
        Mocks.User.userResponse({
          userID: Entitifiers.user(1)
        }),
        Mocks.User.userResponse({
          userID: Entitifiers.user(2)
        })
      ]
    })
  );
};

/**
 * Make insight lists
 */
const makeInsightLists = () => {
  const kpiInsightMetadata = JSON.stringify({
    userPreferences: {
      algorithmOptions: {
        minThreshold: 0,
        maxThreshold: 100
      }
    },
    dataDescription: {
      dataTypeName: "kpiTypeName",
      dataDescriptorId: Entitifiers.dataDescriptor(),
      insightDirection: "decrease"
    },
    triggerPoint: {
      date: "05/05/2021",
      context: {
        value: 0.1
      }
    }
  });

  const lelyInsightMetadata = JSON.stringify({
    lactationNumber: 3,
    reproductionStatus: "Open",
    daysInLactation: 108,
    x: [
      "2021-06-22T00:00:00Z",
      "2021-06-23T00:00:00Z",
      "2021-06-24T00:00:00Z",
      "2021-06-25T00:00:00Z",
      "2021-06-26T00:00:00Z",
      "2021-06-27T00:00:00Z",
      "2021-06-28T00:00:00Z",
      "2021-06-29T00:00:00Z",
      "2021-06-30T00:00:00Z"
    ],
    y: [
      {
        lineName: "Daily Milk Yield [Kg]",
        values: [63.7, 57.1, 59.8, 61.6, 62.6, 63.3, 57.9, null, null]
      }
    ]
  });

  store.dispatch(
    Insights.actions.fetchInsights.fulfill(
      objectHash({
        filter: {
          and: [
            {
              farmID: {
                eq: Entitifiers.farm()
              }
            }
          ]
        }
      }),
      Mocks.Insights.insightListResponse([
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(),
          farmID: Entitifiers.farm(),
          __insightType: InsightType.farmKPI,
          insightMetadata: kpiInsightMetadata,
          insightResolution: JSON.stringify([
            Mocks.Insights.insightResolutionResponse({
              insightType: {
                any: [InsightType.farmKPI]
              }
            })
          ]),
          insightValidations: [],
          dairyCowID: 1,
          dairyCow: Mocks.Cows.dairyCowResponse({
            DairyCowID: 1
          }) as DairyCowSource
        }),
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(2),
          farmID: Entitifiers.farm(),
          __insightType: InsightType.farmKPI,
          insightMetadata: kpiInsightMetadata
        }),
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(3),
          farmID: Entitifiers.farm(),
          __insightType: InsightType.farmKPI,
          insightMetadata: kpiInsightMetadata,
          insightResolution: JSON.stringify(null),
          insightValidations: [
            Mocks.Insights.insightValidationResponse({
              genericInsightID: Entitifiers.insight(3),
              validation: InsightValidation.yes
            })
          ]
        }),
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(4),
          farmID: Entitifiers.farm(),
          __insightType: InsightType.farmKPI,
          insightMetadata: kpiInsightMetadata
        })
      ]),
      Entitifiers.insightList()
    )
  );

  store.dispatch(
    Insights.actions.fetchInsights.fulfill(
      objectHash({
        filter: {
          and: [
            {
              farmID: {
                eq: Entitifiers.farm(2)
              }
            }
          ]
        }
      }),
      Mocks.Insights.insightListResponse([
        Mocks.Insights.insightResponse({
          farmID: Entitifiers.farm(2),
          insightResolution: JSON.stringify([
            Mocks.Insights.insightResolutionResponse({
              insightCategory: {
                any: [InsightCategory.health]
              },
              insightType: {
                any: [Entitifiers.insightTypeName()]
              }
            }),
            Mocks.Insights.insightResolutionResponse({
              insightCategory: {
                any: [InsightCategory.fertility]
              },
              insightType: {
                any: [Entitifiers.insightTypeName(2)]
              }
            })
          ])
        }),
        Mocks.Insights.insightResponse({
          farmID: Entitifiers.farm(2),
          insightResolution: JSON.stringify([
            Mocks.Insights.insightResolutionResponse({
              insightCategory: {
                none: [InsightCategory.health]
              },
              insightType: {
                none: [Entitifiers.insightTypeName(3)]
              }
            }),
            Mocks.Insights.insightResolutionResponse({
              insightCategory: {
                none: [InsightCategory.fertility]
              },
              insightType: {
                none: [Entitifiers.insightTypeName(4)]
              }
            })
          ])
        }),
        Mocks.Insights.insightResponse({
          farmID: Entitifiers.farm(2)
        }),
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(5),
          farmID: Entitifiers.farm(),
          __insightType: InsightType.lelyMilkDrop,
          insightMetadata: lelyInsightMetadata,
          insightValidations: [
            Mocks.Insights.insightValidationResponse({
              genericInsightID: Entitifiers.insight(5),
              validation: InsightValidation.yes
            })
          ],
          insightResolution: JSON.stringify([
            Mocks.Insights.insightResolutionResponse({
              id: "pisti",
              validation: InsightValidation.yes,
              source: InsightResolutionType.preset
            })
          ])
        })
      ]),
      Entitifiers.insightList(2)
    )
  );

  /**
   * Mock Ajinomoto insights
   */
  store.dispatch(
    Insights.actions.fetchInsights.fulfill(
      objectHash({
        filter: {
          and: [
            {
              farmID: {
                eq: Entitifiers.farm(3)
              }
            }
          ]
        }
      }),
      Mocks.Insights.insightListResponse([
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(6),
          farmID: Entitifiers.farm(3),
          __insightType: InsightType.ajinomotoBloodTest,
          insightValidations: []
        }),
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(7),
          farmID: Entitifiers.farm(3),
          __insightType: InsightType.ajinomotoFeedingSchedule,
          insightValidations: []
        }),
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(9),
          farmID: Entitifiers.farm(3),
          __insightType: InsightType.ajinomotoHighRiskCows,
          insightValidations: []
        }),
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(11),
          farmID: Entitifiers.farm(3),
          __insightType: InsightType.ajinomotoNutritionRecommendation,
          insightValidations: []
        })
      ]),
      Entitifiers.insightList(3)
    )
  );

  /**
   * Make a list of child insights for the blood screening schedule
   */
  store.dispatch(
    Insights.actions.fetchInsights.fulfill(
      objectHash({
        orderBy: "endEpoch desc",
        expand: ["insightType", "insightValidations", "dairyCow"],
        filter: {
          and: {
            parentGenericInsightID: Entitifiers.insight(6)
          },
          insightMetadata: { contains: "thisWeek" }
        }
      }),
      Mocks.Insights.insightListResponse([
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(10),
          farmID: Entitifiers.farm(3),
          __insightType: InsightType.ajinomotoIndividualBloodTest,
          parentGenericInsightID: Entitifiers.insight(6),
          insightMetadata: JSON.stringify({
            developerTitle: "Loller",
            developerDescription: "Coaster",
            tagNumber: 1,
            bloodTest: "High risk",
            dueDate: "2022-08-01T14:55:09+00:00"
          }),
          insightValidations: []
        })
      ]),
      `bloodScreening-${Entitifiers.insight(6)}-thisWeek`
    )
  );

  /**
   * Make a list of child insights for the high risk cows
   */
  store.dispatch(
    Insights.actions.fetchInsights.fulfill(
      objectHash({
        orderBy: "endEpoch desc",
        expand: ["insightType", "insightValidations", "dairyCow"],
        filter: {
          and: {
            parentGenericInsightID: Entitifiers.insight(9)
          }
        }
      }),
      Mocks.Insights.insightListResponse([
        Mocks.Insights.insightResponse({
          GenericInsightID: Entitifiers.insight(8),
          parentGenericInsightID: Entitifiers.insight(9),
          farmID: Entitifiers.farm(3),
          __insightType: InsightType.ajinomotoHighRiskCow,
          insightMetadata: JSON.stringify({
            developerTitle: "Loller",
            developerDescription: "Coaster",
            tagNumber: 1,
            riskSeverity: "High risk",
            riskType: "Lorem",
            riskReason: "Ipsum"
          }),
          insightValidations: []
        })
      ]),
      `highRiskCows-${Entitifiers.insight(9)}`
    )
  );
};

/**
 * Mock KPI insight settings
 */
export const makeInsightSettings = (): void => {
  store.dispatch(
    Insights.actions.fetchKPIInsightSettings.fulfill({
      preferences: {
        [Entitifiers.farm()]: {
          [Entitifiers.dataDescriptor()]: Mocks.Insights.kpiInsightSettings({ isEnabled: true }),
          [Entitifiers.dataDescriptor(1)]: Mocks.Insights.kpiInsightSettings({ isEnabled: true }),
          [Entitifiers.dataDescriptor(2)]: Mocks.Insights.kpiInsightSettings({ isEnabled: false })
        }
      }
    })
  );
};

/**
 * Mock notification mappings
 */
export const makeNotificationsMapping = (): void => {
  store.dispatch(
    Insights.actions.fetchInsightNotificationMappings.fulfill([
      Mocks.Insights.insightMappingResponse(),
      Mocks.Insights.groupMappingResponse()
    ])
  );
};

/**
 * Get the real insight resolutions from the json file
 */
export const makeInsightResolutions = (): void => {
  store.dispatch(
    Insights.actions.fetchInsightResolutions.fulfill(resolutions as unknown as InsightResolutionEntity[])
  );
};

/**
 * Mock user preferences
 */
export const makeUserPreferences = (): void => {
  store.dispatch(
    Preferences.actions.fetchUserPreferences.fulfill(
      Mocks.UserPreferences.userPreferencesResponse(),
      {} as SandboxInfo
    )
  );
};

/**
 * Make a list of events
 */
export const makeEventList = (): void => {
  store.dispatch(
    Events.actions.fetchEvents.fulfill(
      Events.utils.makeEventHash(Entitifiers.farm()),
      Mocks.Events.eventListResponse([
        Mocks.Events.eventResponse({
          id: Entitifiers.event(),
          scope: {
            type: EventScope.farm,
            ids: [Entitifiers.farm()]
          },
          source: {
            type: Events.EventSourceType.genericInsight,
            id: Entitifiers.insight()
          }
        }),
        Mocks.Events.eventResponse({
          id: Entitifiers.event("3"),
          source: {
            type: Events.EventSourceType.genericInsight,
            id: Entitifiers.insight(2)
          }
        }),
        Mocks.Events.eventResponse({
          id: Entitifiers.event("5"),
          source: {
            type: Events.EventSourceType.genericInsight,
            id: Entitifiers.insight(3)
          }
        }),
        Mocks.Events.eventResponse({
          id: Entitifiers.event("7"),
          source: {
            type: Events.EventSourceType.genericLabel,
            id: +Entitifiers.label()
          }
        }),
        Mocks.Events.eventResponse({
          id: Entitifiers.event(8),
          source: {
            type: Events.EventSourceType.genericLabel,
            id: +Entitifiers.label()
          },
          context: {
            relatedAnnotationId: Entitifiers.event(7)
          }
        })
      ]) as unknown as Array<EventEntity>
    )
  );
};

/**
 * Mock the app state
 */
export const mockState = (): void => {
  login();
  makeFarmList();
  makeUserList();
  makeInsightLists();
  makeInsightSettings();
  makeInsightResolutions();
  makeUserPreferences();
  makeNotificationsMapping();
  makeEventList();
};
