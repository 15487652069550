export * from "./modules/base";
export * from "./modules/signup";
export * from "./modules/getting-started";

/**
 * Loco API endpoints for the subscriptions app
 */
export const endpoints = {
  loadPath: `https://translate-subscriptions.connecterra.workers.dev/?lang={{lng}}&env=${process.env.REACT_APP_LOCO_ENV}`,
  addPath: `https:/create-subscriptions-asset.connecterra.workers.dev/?env=${process.env.REACT_APP_LOCO_ENV}`
};
