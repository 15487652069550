import { createModule, from, makeAsset } from "../../utils";

export const kpi = createModule("kpi", {
  ...from("noFarmSupport", "noFarmConsent"),
  singleFarmChart: from("title", "description"),
  scorecard: {
    data: from("notAvailable")
  },
  displayName: makeAsset,
  category: makeAsset,
  acquisitionMethod: makeAsset,
  unsupported: from("title", "description")
});
