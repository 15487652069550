import * as _ from "lodash";

/**
 * Converts a given string to CapitalCase
 * @param {string} str
 * @example
 * ```ts
 * capitalCase("lorem ipsum dolor");
 * // "Lorem Ipsum Dolor"
 * ```
 */
export const capitalCase = (str: string): string => _.upperFirst(_.camelCase(str));
