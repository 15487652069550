import * as _ from "lodash";
import * as Sentry from "@sentry/react";

import { makeAzureApiURL, Request } from "../../utils/ajax";
import { Debug } from "@ctra/utils";

/**
 * Register an app with OneSignal
 * @param appName
 * @param oneSignalUserId
 */
const register = (appName: string, oneSignalUserId: string): void => {
  const url = makeAzureApiURL("hermes", "/self/device-registration")();

  /**
   * https://rxjs.dev/guide/testing/marble-testing#rxjs-code-that-consumes-promises-cannot-be-directly-tested
   */
  /* istanbul ignore next: this block looks like what they are referring to in the link above */
  const observable = Request.POST(url, {
    body: {
      appName,
      oneSignalUserId
    }
  }).subscribe({
    next: () => {
      if (_.isFunction(Sentry.captureMessage)) {
        Debug.pushNotifications.info(
          `OneSignal user id (${oneSignalUserId}) is successfully registered to Hermes.`
        );
      }
    },
    error: (error) => {
      if (_.isFunction(Sentry.captureException)) {
        Debug.pushNotifications.error(
          _.isString(error)
            ? error
            : `An error occurred while sending OneSignal user id (${oneSignalUserId}) to Hermes.`
        );
      }
    },
    complete: () => {
      observable.unsubscribe();
    }
  });
};

export default {
  register
};
