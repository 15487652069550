import { AsyncType, createCRUDAsyncTypes } from "@ctra/utils";

const ns = "app/generic-label";

/**
 * CRUD for GenericLabel
 * @category Type
 */
const [CREATE_GENERIC_LABEL, , UPDATE_GENERIC_LABEL, DELETE_GENERIC_LABEL]: Array<AsyncType> =
  createCRUDAsyncTypes(ns, "GENERIC_LABEL", {
    primary: "id"
  });

export default {
  CREATE_GENERIC_LABEL,
  UPDATE_GENERIC_LABEL,
  DELETE_GENERIC_LABEL
};
