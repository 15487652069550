import * as React from "react";

/**
 * Give mounting info on the component
 */
export const useDidMount = (): [boolean, boolean] => {
  const [isMounting, setIsMounting] = React.useState<boolean>(true);
  const [didMount, setDidMount] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsMounting(false);
    setDidMount(true);
  }, []);

  return [isMounting, didMount];
};
