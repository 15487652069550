import * as _ from "lodash";

import { Nullable, Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import { types as farmTypes, FarmDetailsRequestPayload } from "../farms";
import { types as userTypes, AccountDetailsResponse } from "../user";
import { SignupState } from "./typings";
import types from "./types";

export const initialState: SignupState = {
  role: "",
  dhms: {},
  farmDetails: {},
  accountDetails: {},
  remoteSession: {}
};

/**
 * Reducer for user preferences
 * @param state
 * @param action
 * @category Reducer
 */
export const reducer: Reducer<SignupState> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case farmTypes.FETCH_USER_FARM.fulfilled:
    case farmTypes.UPDATE_USER_FARM.fulfilled: {
      if (payload) {
        const { preferences, ...rest } = payload as FarmDetailsRequestPayload;
        const dhmsDetails = _.get(JSON.parse(preferences), "dhmsDetails", {});

        return {
          ...state,
          dhms: {
            ...state.dhms,
            ...dhmsDetails
          },
          farmDetails: {
            ...state.farmDetails,
            ...rest
          }
        };
      }

      return state;
    }
    case userTypes.FETCH_ACCOUNT_DETAILS.fulfilled:
    case userTypes.UPDATE_ACCOUNT_DETAILS.fulfilled: {
      const {
        entities: { user },
        result
      } = payload;

      const { email, firstName, lastName, phoneNumber, country } = user[result];

      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          email,
          firstName,
          lastName,
          country,
          phoneNumber
        }
      };
    }
    case types.GET_REMOTE_SESSION.fulfilled:
    case types.MAKE_REMOTE_SESSION.fulfilled: {
      return {
        ...state,
        remoteSession: payload
      };
    }
    case types.COMMIT: {
      return _.merge({}, state, payload);
    }
  }

  return state;
};

export default cleanup<SignupState>(initialState)(reducer);
