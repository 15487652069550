import { Reducer } from "@ctra/utils";

import { PensList } from "./typings";
import { types as farmTypes } from "../farms";
import { cleanup } from "../../utils/reducer";
import { NormalizedFarmList } from "../farms/schemas";

export const initialState: PensList = {};

/**
 * Pen reducer
 * @param state
 * @param action
 */
const reducer: Reducer<PensList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case farmTypes.FETCH_FARM_LIST.fulfilled: {
      const {
        entities: { pen }
      } = payload as NormalizedFarmList;

      return {
        ...state,
        ...pen
      };
    }
  }

  return state;
};

export default cleanup<PensList>(initialState)(reducer);
