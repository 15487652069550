import { memoize } from "@ctra/utils";

import { ChartDataSource, ChartData } from "../typings";

/**
 * Normalizer Base class
 *
 * @note This handles Iframe data as the default case
 */
class Base {
  protected entity: ChartDataSource;

  constructor(source: ChartDataSource) {
    this.entity = source;
  }

  /**
   * Formats the server response to a format we can use
   * @returns
   */
  @memoize
  format(): ChartData {
    return { data: this.formatData(), meta: this.formatMetadata() };
  }

  /**
   * Formats the server response data
   * @returns
   */
  @memoize
  formatData(): ChartData["data"] {
    return this.entity as unknown as ChartData["data"];
  }

  /**
   * Formats the server response to a format we can use
   * Contains data that we will translate app-side for use by the chart package
   * ...and other extra meta data
   * @returns
   */
  @memoize
  formatMetadata(): ChartData["meta"] {
    return {};
  }
}

export { Base };
