import { EpicBase } from "./Base";
import { DataPointsEpic } from "./DataPointsEpic";

import { ChartDataSourceType } from "../typings";

type RegisteredEpics = typeof DataPointsEpic | typeof EpicBase;

/**
 * A factory to create a nice API around the epics
 * @example
 * ```ts
 * Factory.create(source);
 * // instanceof IframeEpic extends BaseEpic
 * ```
 */
class Factory {
  /**
   * Keep all the registered epic here
   */
  private static _registry = new Map();

  /**
   * Register a class of epic
   * @param source
   * @param cls
   */
  static register<I extends RegisteredEpics>(source: ChartDataSourceType, cls: I): void {
    if (!Factory._registry.has(source)) {
      Factory._registry.set(source, cls);
    } else {
      throw new Error(`${source} is already set in the registry`);
    }
  }

  /**
   * Create an instance object
   * @param source
   * @returns
   */
  static create<I extends RegisteredEpics>(source: ChartDataSourceType): InstanceType<I> {
    let cls = Factory._registry.get(source);

    if (!cls) {
      console.error(`${source} is not yet in the registry. Using Epic Base as constructor`);
      cls = EpicBase;
    }

    return new cls();
  }
}

Factory.register(ChartDataSourceType.dataPoints, DataPointsEpic);

export { Factory };
