import { createModule, from, makeAsset } from "../../utils";

export const impactTracking = createModule("impactTracking", {
  ...from("pageHeader", "beta", "showProjections", "projectionsWarning"),
  timePeriod: makeAsset,
  description: from("before", "after", "projected"),
  empty: from("message", "description"),
  noFarm: from("message", "description"),
  noData: from("label", "message", "description"),
  whatElse: from("title", "description"),
  overlap: from("header", "hide", "show", "goToEvent"),
  add: { ...from("title", "placeholder", "cta"), alert: from("loading", "message", "description") }
});
