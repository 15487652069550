import * as _ from "lodash";

import { AuthEntity, AuthSource } from "./typings";

/**
 * This helps us match the response from new auth service which enterprise now uses
 * to the response of the old auth service
 * This is necessary as apps like classic uses the old auth service
 * and users are allowed to login from classic with the old token response object
 * @todo use a schema
 */
export const normalizeAuthEntity = ({
  username,
  token,
  expirationEpoch,
  expirationDate,
  refreshToken,
  refreshTokenExpirationDate,
  refreshTokenExpirationEpoch,
  impersonating
}: AuthSource): AuthEntity => {
  return {
    user: username,
    impersonating: !_.isNil(impersonating),
    token: {
      accessToken: token,
      expiresIn: expirationEpoch,
      tokenType: null,
      expires: expirationDate,
      refresh: refreshToken,
      issued: null,
      refreshTokenExpires: refreshTokenExpirationDate,
      refreshTokenExpiresIn: refreshTokenExpirationEpoch
    }
  };
};
