import { getStorageKey } from "../utils/versioning";

const countryCodesMap = {
  "en-US": "US" as const,
  "nl-NL": "NL" as const,
  "es-MX": "MX" as const
};

/**
 * Types of supported locale keys
 */
export type SupportedLocaleKeys = keyof typeof countryCodesMap;

/**
 * Country codes to be used for rendering flags
 */
export type SupportedCountryCodes = typeof countryCodesMap[SupportedLocaleKeys];

/**
 * Interface for supported locale types object
 */
export type SupportedLocales = {
  [key in SupportedLocaleKeys]: { title: string; countryCode: SupportedCountryCodes };
};

/**
 * Supported locales
 */
export const supportedLocales: SupportedLocales = {
  "en-US": { title: "English - USA", countryCode: countryCodesMap["en-US"] },
  "nl-NL": { title: "Nederlands", countryCode: countryCodesMap["nl-NL"] },
  "es-MX": { title: "Español - México", countryCode: countryCodesMap["es-MX"] }
};

/**
 * namespace for locale
 */
const localKey = `${getStorageKey()}.locale`;

/**
 * local storage setup for locale/language
 */
export const storeAppLocale = (locale: SupportedLocaleKeys): void => {
  localStorage.setItem(localKey, locale);
};

/**
 * Default locale
 */
export const defaultLocale = "en-US";

/**
 * Accepts a locale/local storage, verifies if it is supported,
 * returns it else returns the default locale
 * @param appLocale
 * @returns
 * @todo Might consider renaming this
 */
export const loadAppLocale = (appLocale?: SupportedLocaleKeys): SupportedLocaleKeys => {
  const locale = appLocale || localStorage.getItem(localKey);

  return locale && locale in supportedLocales ? (locale as SupportedLocaleKeys) : defaultLocale;
};
