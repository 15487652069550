import { createAsyncTypes } from "@ctra/utils";

const ns = "app/user-preferences";

/**
 * Fetch user preferences
 * @category Type
 */
const FETCH_USER_PREFERENCES = createAsyncTypes(ns, "FETCH_USER_PREFERENCES", {});

/**
 * set user preferences
 */
const SET_USER_PREFERENCES = createAsyncTypes(ns, "SET_USER_PREFERENCES", {});

export default {
  FETCH_USER_PREFERENCES,
  SET_USER_PREFERENCES
};
