import { AsyncType, createAsyncTypes, createCRUDAsyncTypes, MINUTE } from "@ctra/utils";

const ns = "app/event";

/**
 * Fetch events
 * @category Type
 */
const FETCH_EVENTS: AsyncType = createAsyncTypes(ns, "FETCH_EVENTS", {
  primary: "hash", // unique combination of farm id and params
  expires: MINUTE,
  replays: true
});

/**
 * CRUD events
 * @category Type
 */
const [CREATE_EVENT, , UPDATE_EVENT, DELETE_EVENT] = createCRUDAsyncTypes(ns, "EVENT", {
  primary: "id",
  preservesPayload: true
});

export default {
  FETCH_EVENTS,
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT
};
