import { Observable, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";
import * as _ from "lodash";

import { Action, Debug } from "@ctra/utils";

import { makeAzureApiURL } from "../../utils/ajax";
import types from "./types";
import actions from "./actions";
import { RemoteSession } from "./typings";

/**
 * Get the remote session status
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchRemoteSession: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.GET_REMOTE_SESSION.pending),
    switchMap(() =>
      Request.GET(makeAzureApiURL("accounts", "/self/remote-session")()).pipe(
        map<{ response: RemoteSession }, Action>(({ response }) => {
          return actions.getRemoteSession.fulfill(response);
        }),
        catchError<unknown, Observable<Action>>(({ response }) => {
          const error = _.get(response, ["error"]);
          const statusCode = _.get(response, ["statusCode"]);

          if (error) {
            Debug.remoteSessionApi.error(error);
          }

          return of(actions.getRemoteSession.reject(error, statusCode));
        })
      )
    )
  );

/**
 * Make a remote session
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const makeRemoteSession: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.MAKE_REMOTE_SESSION.pending),
    switchMap(() =>
      Request.PUT(makeAzureApiURL("accounts", "/self/remote-session")()).pipe(
        map<{ response: RemoteSession }, Action>(({ response }) => {
          return actions.makeRemoteSession.fulfill(response);
        }),
        catchError<unknown, Observable<Action>>(({ response }) => {
          const error = _.get(response, ["error"]);
          const statusCode = _.get(response, ["statusCode"]);

          if (error) {
            Debug.remoteSessionApi.error(error);
          }

          return of(actions.makeRemoteSession.reject(error, statusCode));
        })
      )
    )
  );

export default {
  fetchRemoteSession,
  makeRemoteSession
};
