import { default as reducer, initialState } from "./reducer";
import { Debug } from "@ctra/utils";

Debug.create("userApi", "User API", { transports: true });

export { default as types } from "./types";
export { default as actions } from "./actions";
export { default as epics } from "./epics";
export { default as utils } from "./utils";
export { reducer, initialState };

export * from "./typings";

export default reducer;
