import { createAsyncActions } from "@ctra/utils";

import types from "./types";
import { GenericLabelSource } from "./typings";

/**
 * Create a new GenericLabel object
 * @category Action
 */
const createGenericLabel = createAsyncActions(
  types.CREATE_GENERIC_LABEL,
  (params: Omit<GenericLabelSource, "GenericLabelID">) => params,
  (response: GenericLabelSource) => response,
  (error: unknown) => ({ error })
);

/**
 * Delete an existing GenericLabel object
 * @category Action
 */
const deleteGenericLabel = createAsyncActions(
  types.DELETE_GENERIC_LABEL,
  (id: GenericLabelSource["GenericLabelID"]) => ({ id }),
  (id: GenericLabelSource["GenericLabelID"]) => ({ id }),
  (id: GenericLabelSource["GenericLabelID"], error: unknown) => ({ id, error })
);

export default {
  createGenericLabel,
  deleteGenericLabel
};
