import * as _ from "lodash";

import { memoize } from "@ctra/utils";

import { DataPointsBase } from "./DataPointsBase";

import { BarData, BarSource } from "../typings";
import { Series } from "../";

/**
 * Normalizer class for bar chart data
 */
class BarNormalizer extends DataPointsBase {
  protected entity: BarSource;
  constructor(source: BarSource) {
    super(source);
    this.entity = source;
  }

  /**
   * Process the incoming bar chart data
   */
  @memoize
  formatData(): BarData["data"] {
    /**
     * First map through and return an array of points type, then
     * order by the value desc
     */

    return _.orderBy(
      _.flatMap(this.entity.data, (item, key) => {
        const series = Series.create(key, this.entity.meta.seriesType);
        const seriesField = series.getField();

        return seriesField
          ? {
              /**
               * @note for bar charts, y is x and x is y, so, this is correct
               */
              x: item.points.y,
              y: seriesField,
              seriesField
            }
          : [];
      }),
      ["x"],
      ["desc"]
    );
  }

  /**
   * Format the metadata for further processing e.g translations
   * @returns
   */
  @memoize
  formatMetadata(): BarData["meta"] {
    return _.reduce<NonNullable<BarSource["data"]>, NonNullable<BarData["meta"]>>(
      this.entity.data,
      (acc, item, key) => {
        const series = Series.create(key, this.entity.meta.seriesType);
        const seriesField = series.getField();

        /**
         * Adding the series and axis labels to the meta data
         * so that they can be translated by the consuming app.
         * @note Using _.setWith as it allows for a customizer as
         * _.set creates arrays when the series are numeric values.
         */
        if (seriesField) {
          _.setWith(acc, ["series", "keys", seriesField], seriesField, Object);
        }

        _.setWith(acc, ["axis", "x", "labels", "short", item.points.y], item.points.y, Object);

        return acc;
      },
      { ...this.entity.meta }
    );
  }
}

export { BarNormalizer };
