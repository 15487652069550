import { NormalizedSchema, schema } from "normalizr";

import { HerdGroupEntity, HerdGroupList } from "./typings";

const herdGroup = new schema.Entity(
  "herdGroup",
  {},
  {
    idAttribute: ({ id, herdGroupId }) => herdGroupId || id,
    processStrategy: ({ herdGroupId, id, ...rest }) => ({
      id: herdGroupId || id,
      schema: "herdGroup",
      ...rest
    })
  }
);

export type NormalizedHerdGroupList = NormalizedSchema<
  { herdGroup: HerdGroupList },
  Array<HerdGroupEntity["id"]>
>;

export default {
  herdGroup
};
