export const SECOND = 1;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const FORTNIGHT = 2 * WEEK;
export const MONTH = 31 * DAY;
export const QUARTER = 3 * MONTH;
export const HALFYEAR = 2 * QUARTER;
export const YEAR = 2 * HALFYEAR;
