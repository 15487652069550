import { FC } from "react";
import { Row, Col, Typography } from "antd";

import { classname } from "@ctra/utils";
import styles from "./Empty.module.less";
import { EmptyProps } from "./types";

const { Title, Paragraph } = Typography;

/**
 * Empty component for any layout
 * @param {string | undefined} title
 * @param {string | undefined} description
 * @param {string | undefined} className
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} illustration
 * @return {JSX.Element}
 * @constructor
 */
const Empty: FC<EmptyProps> = ({ title, description, className, illustration }) => (
  <Row
    gutter={[0, 12]}
    className={classname("ctra-layouts-empty", styles.Empty, className)}
    justify="space-around"
    align="middle"
  >
    <Col span={24}>{illustration}</Col>
    <Col span={24} className={styles.Text}>
      {title && <Title level={4}>{title}</Title>}
      {description && <Paragraph>{description}</Paragraph>}
    </Col>
  </Row>
);

export default Empty;
