/**
 * Convert an Array type to Record using the members of the given array as keys.
 * @typeParam A - the Array to convert to Record
 */
type FromArray<A extends string[]> = Record<A[number], string>;

/**
 * Create a { [key1]: null, [key2]: null } object from a given [key1, key2] array.
 * @typeParam A - list of copy keys to convert
 * @param {string[]} copyKey
 * @return {{}}
 * @example
 * ```ts
 * from("a", "b", "c");
 * // { a: null, b: null, c: null }
 * ```
 */
export const from = <A extends string[]>(...copyKey: A): FromArray<A> =>
  copyKey.reduce((o, i) => ({ ...o, [i]: i }), {} as FromArray<A>);
