/* eslint @typescript-eslint/no-explicit-any: 0 */
// @ts-ignore - we can ignore this, it sits in global.d.ts
import keymirror from "key-mirror-nested";
import * as _ from "lodash";

import { Nullable } from "@ctra/utils";

/**
 * Get all the function arguments apart from the 1st one.
 * As the asset creator is a result of a _.partial, we rely on this one.
 */
type TailParameters<F extends (...args: any) => any> = F extends (head: any, ...tail: infer T) => any
  ? T
  : never;

export type AssetError = {
  error: string;
  copyKey: string;
  identity: string;
  rest: string[];
  result: string;
};

/**
 * Asset definition: string or null
 */
type AssetDefinition = Nullable<string>;

/**
 * Create a string asset (copy key) by joining the arguments of the function.
 */
type AssetCreatorDefinition = (copyKey: string, ...suffix: string[]) => string | AssetError;

/**
 * i18n content definition
 * @note exported for unit testing
 */
export type ContentDefinition = {
  [assetId: string]: ContentDefinition | AssetDefinition | AssetCreatorDefinition;
};

/**
 * String asset (copy key)
 */
type Asset = string;

/**
 * Asset creator
 */
export type AssetCreator = (
  ...suffix: TailParameters<AssetCreatorDefinition>
) => ReturnType<AssetCreatorDefinition>;

/**
 * i18n module
 * @typeParam C - content definition
 */
export type I18NModule<C extends ContentDefinition> = {
  [assetId in keyof C]: C[assetId] extends ContentDefinition
    ? I18NModule<C[assetId]>
    : C[assetId] extends AssetCreatorDefinition
    ? AssetCreator
    : Asset;
};

type I18NModuleCreator = <C extends ContentDefinition>(moduleName: string, content: C) => I18NModule<C>;

export const assetSeparator = "-";

/**
 * Create content module by generating translation keys from a nested object.
 * @param {string} moduleName
 * @param {{}} content
 * @example
 * ```ts
 * const module = createModule("moduleName", {
 *   key1: "value1",
 *   nested: {
 *     key2: "value2",
 *     deeper: {
 *       key3: "value3"
 *     }
 *   },
 *   key4: (copyKey, suffix = "") => `${copyKey}-${suffix}`
 * });
 *
 * console.log(module.key1); // "moduleName.value"
 * console.log(module.nested.key2); // "moduleName.nested-key2"
 * console.log(module.nested.deeper.key3); // "moduleName.nested-deeper-key3"
 * console.log(module.key4("any-suffix")); // "moduleName.key4-any-suffix"
 * ```
 */
export const createModule: I18NModuleCreator = (moduleName, content): any => {
  if (!_.isObject(content)) {
    throw new Error("An i18n module must be an object.");
  }

  return keymirror<typeof content, I18NModule<typeof content>>(content, {
    connChar: assetSeparator,
    /**
     * Customize output
     * @param {*} oldVal
     * @param {string} newVal
     * @return {string|function}
     */
    custFunc: (oldVal: AssetDefinition | AssetCreatorDefinition, newVal: string): Asset | AssetCreator => {
      const copyKey = `${moduleName}.${newVal}`;

      if (_.isFunction(oldVal)) {
        return _.partial(oldVal, copyKey);
      }

      return copyKey;
    }
  });
};
