import { NormalizedSchema, schema } from "normalizr";
import { DIMGroupEntity, DIMGroupList } from "./typings";

const dimGroup = new schema.Entity(
  "dimGroup",
  {},
  {
    idAttribute: "key",
    processStrategy: ({ key, ...rest }) => ({
      id: key,
      ...rest
    })
  }
);

export type NormalizedDIMGroupList = NormalizedSchema<
  { dimGroup: DIMGroupList },
  Array<DIMGroupEntity["id"]>
>;

export default {
  dimGroup
};
