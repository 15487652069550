import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/scorecard";

/**
 * Fetch dashboard layout
 * @category Type
 */
const FETCH_SCORECARD_VALUE: AsyncType = createAsyncTypes(ns, "FETCH_SCORECARD_VALUE", {
  primary: "hash" // unique combination of data descriptor id, farm id, aggregator and range
});

export default {
  FETCH_SCORECARD_VALUE
};
