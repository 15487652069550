import { Nullable, UnitSystem } from "@ctra/utils";
import { DataDescriptorEntity } from "../data-dictionary";

export interface AnnotationResultsResponse {
  farmId: number;
  eventId: string;
  availableTimeSpanDays: number[];
  context: {
    originalAnnotationId: string;
  };
  state: AnnotationState;
  data: {
    [key: string]: {
      [key: DataDescriptorEntity["id"]]: {
        [key in UnitSystem]: Result;
      };
    };
  };
}

export enum AnnotationState {
  processing = "Processing",
  done = "Done",
  failed = "Failed"
}

export enum DataAvailability {
  none = "None",
  beforeOnly = "BeforeOnly",
  afterOnly = "AfterOnly",
  full = "Full",
  processing = "Processing"
}

export enum MissingDataReason {
  NoDataForTimeWindow = "NoDataForTimeWindow",
  NoDataForChart = "NoDataForChart",
  TimeWindowNotPassed = "TimeWindowNotPassed",
  EventScopeDataNotFound = "EventScopeDataNotFound",
  HerdGroupDataNotFound = "HerdGroupDataNotFound"
}

export interface Result {
  before: number;
  after: Nullable<number>;
  projected: Nullable<number>;
  dataAvailability: DataAvailability;
  descriptorType: Nullable<"Custom">;
  dataUnavailabilityReason: Nullable<MissingDataReason>;
}

export interface AnnotationResults {
  id: string;
  eventID: string;
  farmID: number;
  timeSpanDays: number[];
  state: AnnotationState;
  results: AnnotationResultsResponse["data"];
}

export type AnnotationList = Record<string, AnnotationResults>;

export interface ImpactTrackingState {
  annotations: AnnotationList;
}
