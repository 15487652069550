import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/saved-charts";

/**
 * Fetch saved charts
 * @category Type
 */
const FETCH_SAVED_CHARTS: AsyncType = createAsyncTypes(ns, "FETCH_SAVED_CHARTS");

/**
 * Add a new card
 * @category Type
 */
const ADD_CARD: AsyncType = createAsyncTypes(ns, "ADD_CARD");

/**
 * Add a new tab/group
 * @category Type
 */
const ADD_TAB: AsyncType = createAsyncTypes(ns, "ADD_TAB");

/**
 * Update tabs
 */
const UPDATE_TABS: AsyncType = createAsyncTypes(ns, "UPDATE_TABS");

/**
 * Update the list of charts
 */
const UPDATE_CHARTS: AsyncType = createAsyncTypes(ns, "UPDATE_CHARTS");

export default {
  FETCH_SAVED_CHARTS,
  UPDATE_TABS,
  UPDATE_CHARTS,
  ADD_CARD,
  ADD_TAB
};
