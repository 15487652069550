import { FC, ReactNode } from "react";
import { Col, Row, Typography } from "antd";

import styles from "./CarouselHeader.module.less";
import CarouselPagination from "../CarouselPagination";
import { useCarousel } from "../../providers";

const { Text } = Typography;

export interface CarouselHeaderProps {
  /**
   * title
   */
  title: string;
  /**
   * icon
   */
  icon?: ReactNode;
}

/**
 * Carousel header component
 */
const CarouselHeader: FC<CarouselHeaderProps> = ({ title, icon }) => {
  const {
    meta: { list }
  } = useCarousel();

  return (
    <Row justify="space-between" align="middle" className={styles.Wrapper}>
      <Row gutter={[30, 0]} className="title" align="middle">
        <Col style={{ lineHeight: 0 }}>{icon}</Col>
        <Text>{title}</Text>
      </Row>
      <CarouselPagination className={list.length > 1 ? void 0 : styles.Hidden} />
    </Row>
  );
};

export default CarouselHeader;
