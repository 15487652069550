import { Epic, ofType } from "redux-observable";
import { catchError, mergeMap, map } from "rxjs/operators";
import { Observable, of } from "rxjs";

import { Action, Debug, Nullable } from "@ctra/utils";

import { makeAzureApiURL } from "../../utils/ajax";
import types from "./types";
import actions from "./actions";

/**
 * Create a new farm summary
 * @param action$
 * @param state$
 * @param Request
 */
const createFarmSummary: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.CREATE_FARM_SUMMARY.pending),
    mergeMap<ReturnType<typeof actions.createFarmSummary.start>, Observable<Promise<unknown>>>(
      ({ payload: { start, end, farmID } }) =>
        Request.POST(makeAzureApiURL("accounts", "/self/farm-summaries")(), {
          body: { startDate: start, endDate: end, farmId: farmID }
        }).pipe(
          map<{ response: Record<string, unknown> }, Action>(({ response }) =>
            actions.createFarmSummary.fulfill(response)
          ),
          catchError<
            { status: number; message: string; response: Nullable<{ error: string; statusCode: number }> },
            Observable<Action>
          >(({ response, message, status }) => {
            Debug.farmSummariesAPI.error("Failed to create farm summary", {
              start,
              end,
              farmID,
              response,
              message,
              status
            });

            return of(
              actions.createFarmSummary.reject(response ? response : { statusCode: status, error: message })
            );
          })
        )
    )
  );

export default {
  createFarmSummary
};
