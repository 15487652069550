import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/token";

/**
 * Request a bearer token, aka. log in
 * @category Type
 */
const REQUEST_TOKEN: AsyncType = createAsyncTypes(ns, "REQUEST_TOKEN", { preservesPayload: true });

/**
 * Inject token gotten from the URL.
 * @category Type
 */
const INJECT_TOKEN = `${ns}/INJECT_TOKEN`;

/**
 * Request access token renewal using the refresh token
 * @category Type
 */
const RENEW_TOKEN: AsyncType = createAsyncTypes(ns, "RENEW_TOKEN", {
  preservesPayload: ["error", "statusCode"]
});

/**
 * Set User gotten token from the URL.
 * @category Type
 */
const SET_USER = `${ns}/SET_USER`;

/**
 * Report a forgotten password
 * @category Type
 */
const REPORT_FORGOT_PASSWORD: AsyncType = createAsyncTypes(ns, "REPORT_FORGOT_PASSWORD");

/**
 * Reset password
 * @category Type
 */
const RESET_PASSWORD: AsyncType = createAsyncTypes(ns, "RESET_PASSWORD");

/**
 * Register user via referral link
 * @category Type
 */
const REGISTER_USER: AsyncType = createAsyncTypes(ns, "REGISTER_USER", {
  preservesPayload: ["error", "statusCode"]
});

/**
 * Accept Invite
 * @category Type
 */
const ACCEPT_INVITE: AsyncType = createAsyncTypes(ns, "ACCEPT_INVITE");

/**
 * Register user autonomously
 * @category Type
 */
const SIGN_UP_USER: AsyncType = createAsyncTypes(ns, "SIGN_UP_USER", {
  preservesPayload: ["error", "statusCode"]
});

/**
 * Activate user from email
 * @category Type
 */
const ACTIVATE_USER: AsyncType = createAsyncTypes(ns, "ACTIVATE_USER", {
  preservesPayload: ["error", "statusCode"]
});

/**
 * Activate user from email
 * @category Type
 */
const REQUEST_ACTIVATION: AsyncType = createAsyncTypes(ns, "REQUEST_ACTIVATION");

export default {
  REQUEST_TOKEN,
  INJECT_TOKEN,
  RENEW_TOKEN,
  SET_USER,
  REPORT_FORGOT_PASSWORD,
  RESET_PASSWORD,
  REGISTER_USER,
  ACCEPT_INVITE,
  SIGN_UP_USER,
  ACTIVATE_USER,
  REQUEST_ACTIVATION
};
