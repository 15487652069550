import { PlainObject } from "odata-query";
import * as _ from "lodash";

import { Epoch } from "@ctra/utils";
import { FarmEntity } from "../../farms";
import { InsightTypeEntity } from "../typings";

/**
 * When querying the insights, the dominant epoch to use is endEpoch
 */
const dominantEpoch = "createdEpoch";

/**
 * Make a "not older than" filter for epoch values
 * @param epoch
 */
function from(epoch: number): PlainObject;

/**
 * Make a "not older than" filter for time units, eg. 1 month
 * @param amount
 * @param unit
 */
function from(amount: number, unit: number): PlainObject;

/**
 * Create "not older than" filter
 * @param amount
 * @param unit
 */
function from(amount: number, unit?: number): PlainObject {
  const epoch = unit ? Epoch.midnight({ trail: true }) - unit : amount;

  return {
    [dominantEpoch]: {
      ge: epoch
    }
  };
}

/**
 * Make an "older than" filter for epoch values
 * @param epoch
 */
function until(epoch: number): PlainObject;

/**
 * Make an "older than" filter for time units, eg. 1 month
 * @param amount
 * @param unit
 */
function until(amount: number, unit: number): PlainObject;

/**
 * Maka an "older than" filter
 * @param amount
 * @param unit
 */
function until(amount: number, unit?: number): PlainObject {
  const epoch = unit ? Epoch.midnight({ trail: true }) - unit : amount;

  return {
    [dominantEpoch]: {
      lt: epoch
    }
  };
}

/**
 * Make an "or" query for the given values
 * @param key
 * @param values
 */
const union = (key: string, values: Array<unknown>): PlainObject => ({
  or: values.map((v) => ({ [key]: v }))
});

/**
 * Query for insight types
 * @param type
 */
const ofType = (type: InsightTypeEntity["typeName"] | Array<InsightTypeEntity["typeName"]>): PlainObject => ({
  "insightType/typeName": {
    [_.isArray(type) ? "in" : "eq"]: type
  }
});

/**
 * Make a filter for farm ids
 * @param farms
 * @todo think of a better name
 */
const onFarm = (farms: FarmEntity["id"] | Array<FarmEntity["id"]>): PlainObject =>
  _.isArray(farms)
    ? union("farmID", farms)
    : {
        farmID: {
          eq: farms
        }
      };

export { from, until, ofType, union, onFarm };
