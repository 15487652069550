import { AsyncType, createAsyncTypes, HOUR } from "@ctra/utils";

const ns = "app/chart";

/**
 * Fetch an iframe URL for the given chart ID and filters
 * @category Type
 */
const FETCH_CHART_DATA: AsyncType = createAsyncTypes(ns, "FETCH_CHART_DATA", {
  primary: "hash", // hash generated from the chart id and filters
  expires: HOUR / 2,
  replays: true
});

/**
 * Fetch farm correlations
 * @category Type
 */
const FETCH_FARM_CORRELATIONS: AsyncType = createAsyncTypes(ns, "FETCH_FARM_CORRELATIONS", {
  primary: "farmID"
});

/**
 * Fetch chart correlations
 * @category Type
 */
const FETCH_CHART_CORRELATIONS: AsyncType = createAsyncTypes(ns, "FETCH_CHART_CORRELATIONS", {
  primary: "farmID"
});

export default {
  FETCH_CHART_DATA,
  FETCH_FARM_CORRELATIONS,
  FETCH_CHART_CORRELATIONS
};
