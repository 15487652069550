import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/iofc";

/**
 * Fetch IOFC data
 * @category Type
 */
const FETCH_IOFC_DATA: AsyncType = createAsyncTypes(ns, "FETCH_IOFC_DATA", {
  preservesPayload: true,
  primary: "scenario"
});

export default {
  FETCH_IOFC_DATA
};
