import * as _ from "lodash";
import { memoize, Optional } from "@ctra/utils";

class Base {
  protected id: string | number;

  constructor(id: string | number) {
    this.id = id;
  }

  @memoize
  getField(): Optional<string> {
    return _.trim(this.id?.toString());
  }
}

export { Base };
