import moment from "moment";
import * as _ from "lodash";
import { load, asMoment as epochToMoment } from "./epoch";
import { Nullable } from "../typings";

/**
 * Convert a timestamp to moment
 * @param {string} ts
 * @param {boolean} parseZone
 * @returns {moment.Moment}
 * @example
 * ```ts
 * asMoment("2021-02-10T14:30:19.192Z"); // Moment of "2021-02-10T14:30:19.192Z"
 * ```
 */
export const asMoment = (ts: string, { parseZone }: { parseZone?: boolean } = {}): moment.Moment =>
  parseZone ? moment.parseZone(ts) : moment(ts, moment.defaultFormatUtc);

/**
 * Serialise the given date time (epoch or moment)
 * @param {number|moment.Moment} dateTime
 * @example
 * ```ts
 * serialize(1234567890); // 2009-02-14T00:31:30Z
 * ```
 */
export const serialize = (dateTime: Nullable<number | moment.Moment>): Nullable<string> => {
  if (_.isNull(dateTime)) {
    return dateTime;
  } else if (_.isNumber(dateTime)) {
    return epochToMoment(load(dateTime)).format(moment.defaultFormatUtc);
  } else if (moment.isMoment(dateTime)) {
    return dateTime.format(moment.defaultFormatUtc);
  }

  throw new Error(`I cannot understand ${dateTime}`);
};

/**
 * Get the current timestamp
 * @example
 * ```ts
 * now(); // 2022-03-04T00:31:30Z
 * ```
 * @returns
 */
export const now = (): string => moment().utc().toISOString();
