import * as React from "react";
import { Layout, Spin } from "antd";
import { BasicProps } from "antd/lib/layout/layout";

import { classname } from "@ctra/utils";

import styles from "./AsyncLayout.module.less";

export type AsyncLayoutProps = {
  /**
   * Loading state
   */
  loading?: boolean;
  /**
   * Loading tip
   */
  tip?: string;
} & BasicProps;

const { Content } = Layout;

/**
 * Layout with spinner for async content
 * @param loading
 * @param tip
 * @param children
 * @param className
 * @param rest
 * @constructor
 */
const AsyncLayout: React.FC<AsyncLayoutProps> = ({ loading = false, tip, children, className, ...rest }) => (
  <Layout className={classname(styles.AsyncLayout, className)} {...rest}>
    <Spin spinning={loading} tip={tip}>
      <Content className={styles.Content}>{children}</Content>
    </Spin>
  </Layout>
);

export default AsyncLayout;
