import { normalize } from "normalizr";
import { Merge } from "type-fest";

import { createAsyncActions } from "@ctra/utils";

import types from "./types";
import schemas, { NormalizedUser, NormalizedUserList } from "./schemas";
import { AccountDetailsResponse, UserInfoRequestPayload, UserListResponse } from "./typings";
import { FarmEntity } from "../farms";

/**
 * Fetch a user
 * @category Action
 */
const fetchUser = createAsyncActions(
  types.FETCH_USER,
  (email?: string) => ({
    email
  }),
  (userResponse: Record<string, unknown>): NormalizedUser => normalize(userResponse, schemas.user),
  (error: string) => ({ error })
);

/**
 * Fetch a list of users
 */
const fetchUserList = createAsyncActions(
  types.FETCH_USER_LIST,
  (source: "compat" | "consent" = "compat", farmID: FarmEntity["id"] = -1) => ({ source, farmID }),
  (
    farmID: FarmEntity["id"],
    userListResponse: UserListResponse
  ): Merge<{ farmID: FarmEntity["id"] }, NormalizedUserList> => ({
    farmID,
    ...normalize(userListResponse, farmID > -1 ? schemas.usersByFarmConnection : schemas.usersByAppAccess)
  }),
  (farmID: FarmEntity["id"], error) => ({ farmID, error })
);

/**
 * Fetch account details (during subscription)
 * @type {AsyncActionCreator<() => {}, (userResponse: AccountDetailsResponse) => NormalizedUser, (error) => {error: any}>}
 * @category Action
 */
const fetchAccountDetails = createAsyncActions(
  types.FETCH_ACCOUNT_DETAILS,
  () => ({}),
  (userResponse: AccountDetailsResponse): NormalizedUser => normalize(userResponse, schemas.user),
  (error: string, statusCode: number, details: string) => ({ error, statusCode, details })
);

/**
 * Update user info (account details during subscription)
 * @type {AsyncActionCreator<(userInfo: UserInfoRequestPayload) => UserInfoRequestPayload, (userResponse: AccountDetailsResponse) => NormalizedUser, (error: string, statusCode: number, details: string) => {details: string, error: string, statusCode: number}>}
 * @category Action
 */
const updateAccountDetails = createAsyncActions(
  types.UPDATE_ACCOUNT_DETAILS,
  (userInfo: UserInfoRequestPayload): UserInfoRequestPayload => userInfo,
  (userResponse: AccountDetailsResponse): NormalizedUser => normalize(userResponse, schemas.user),
  (error: string, statusCode: number, details: string) => ({ error, statusCode, details })
);

type Role = "Advisor" | "Farmer" | "Explorer" | "Other";

/**
 * Update user info (account details during signup)
 * @type {AsyncActionCreator<(metadata: {role: string, isSignupCompleted: string}) => {role: string, isSignupCompleted: string}, () => {}, (error: string, statusCode: number, details: string) => {details: string, error: string, statusCode: number}>}
 */
const updateSignupDetails = createAsyncActions(
  types.UPDATE_SIGNUP_DETAILS,
  (metadata: { role: Role; isSignupCompleted: boolean }): { role: Role; isSignupCompleted: boolean } =>
    metadata,
  () => ({}),
  (error: string, statusCode: number, details: string) => ({ error, statusCode, details })
);

export default {
  fetchUser,
  fetchUserList,
  fetchAccountDetails,
  updateAccountDetails,
  updateSignupDetails
};
