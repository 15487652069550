import { Typography, Space } from "antd";
import { FC, HTMLProps } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";

import { classname } from "@ctra/utils";

import styles from "./NoCorrelations.module.less";

export interface NoCorrelationsProps extends HTMLProps<HTMLDivElement> {
  /**
   * description
   */
  description: string;
}

const { Title } = Typography;

/**
 * No correlations found component
 */
const NoCorrelations: FC<NoCorrelationsProps> = ({ description, className, ...rest }) => (
  <div className={classname("ctra-correlations-empty", styles.EmptyPanel, className)} {...rest}>
    <Space size="large" align="center" direction="horizontal">
      <InfoCircleOutlined />
      <Title level={5}>{description}</Title>
    </Space>
  </div>
);

export default NoCorrelations;
