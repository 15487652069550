import * as _ from "lodash";

import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import { types as farmTypes, FarmDetailsRequestPayload } from "../farms";
import { types as userTypes } from "../user";
import { AccountState } from "./typings";

export const initialState: AccountState = {
  dhms: {},
  farmDetails: {},
  accountDetails: {}
};

/**
 * Reducer for account details
 * @note may contain som duplicates to the user, but this is the data the user filled in when registering
 * @param state
 * @param action
 * @category Reducer
 */
export const reducer: Reducer<AccountState> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case farmTypes.FETCH_USER_FARM.fulfilled: {
      if (payload) {
        const { preferences, ...rest } = payload as FarmDetailsRequestPayload;
        const dhmsDetails = _.get(JSON.parse(preferences), "dhmsDetails", {});

        return {
          ...state,
          dhms: {
            ...state.dhms,
            ...dhmsDetails
          },
          farmDetails: {
            ...state.farmDetails,
            ...rest
          }
        };
      }

      return state;
    }
    case userTypes.FETCH_ACCOUNT_DETAILS.fulfilled: {
      const {
        entities: { user },
        result
      } = payload;

      const { email, firstName, lastName, phoneNumber, country } = user[result];

      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          email,
          firstName,
          lastName,
          country,
          phoneNumber
        }
      };
    }
  }

  return state;
};

export default cleanup<AccountState>(initialState)(reducer);
