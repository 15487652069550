import { tap, ignoreElements } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";

import { localColorStoreKey } from "@ctra/utils";

import types from "./types";

/**
 * Clear local storage
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} localStorage
 * @return {Observable<unknown>}
 */
const logout: Epic = (action$, state$, { localStorage }) => {
  return action$.pipe(
    ofType(types.LOGOUT),
    tap(() => {
      localStorage.removeItem(localColorStoreKey);
    }),
    ignoreElements()
  );
};

export default {
  logout
};
