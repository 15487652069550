import { Observable, of } from "rxjs";
import { Epic, ofType } from "redux-observable";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import * as _ from "lodash";

import { Action, Debug, Epoch, TS } from "@ctra/utils";
import { makeAzureApiURL } from "../../utils/ajax";
import actions, { FetchEventsPendingPayload } from "./actions";
import types from "./types";
import { EventEntity, EventScope, EventSource } from "./typings";

/**
 * Fetch events
 * @param action$
 * @param state$
 * @param Request
 * @see API docs at https://github.com/Connecterra/Connecterra.Annotations/tree/develop
 */
const fetchEvents: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_EVENTS.pending),
    switchMap(
      ({
        payload: { hash, farmID, source, categories, startDate, endDate }
      }: {
        payload: FetchEventsPendingPayload;
      }) => {
        const url = makeAzureApiURL("annotations", "/annotations/farm/<%= farmID %>")({ farmID });

        return Request.GET(url, {
          body: {
            /**
             * @todo extend this via the query
             */
            excludeInsightTypeIds: 72, // exclude sensor insights
            source,
            categories,
            startDate,
            endDate
          }
        }).pipe(
          map<{ response: { data: Array<EventSource> } }, Action>(({ response }) =>
            actions.fetchEvents.fulfill(hash, response.data)
          ),
          catchError<unknown, Observable<Action>>((error) => of(actions.fetchEvents.reject(hash, error)))
        );
      }
    )
  );

/**
 * Create a new event
 * @param action$
 * @param state$
 * @param Request
 * @see API docs at https://github.com/Connecterra/Connecterra.Annotations/tree/develop
 */
const createEvent: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.CREATE_EVENT.pending),
    mergeMap<ReturnType<typeof actions.createEvent.start>, Observable<unknown>>(
      ({
        payload: { hash, farmID, description, category, subCategory, endTs, penNameList, startTs, title }
      }) =>
        Request.POST(makeAzureApiURL("annotations", "/annotations")(), {
          body: {
            startAt: startTs,
            createdAt: TS.serialize(Epoch.now()),
            endAt: _.defaultTo(endTs, startTs),
            source: null,
            scope: {
              type: _.isEmpty(penNameList) ? "Farm" : "Pen",
              ids: _.isEmpty(penNameList) ? [farmID.toString()] : _.map(penNameList, _.toString)
            },
            farmId: farmID,
            category,
            subCategory,
            title,
            description,
            state: "Live"
          }
        }).pipe(
          map<{ response: EventSource }, Action>(({ response }) =>
            actions.createEvent.fulfill(response, hash)
          ),
          catchError<unknown, Observable<Action>>((error) => {
            Debug.eventApi.error(error);
            return of(actions.createEvent.reject(error));
          })
        )
    ),
    catchError<unknown, Observable<Action>>((error) => {
      Debug.eventApi.error(error);
      return of(actions.createEvent.reject(error));
    })
  );

/**
 * Update event
 * @param action$
 * @param state$
 * @param Request
 * @see API docs at https://github.com/Connecterra/Connecterra.Annotations/tree/develop
 */
const updateEvent: Epic = (action$, state$, { Request }) => {
  return action$.pipe(
    ofType(types.UPDATE_EVENT.pending),
    mergeMap<ReturnType<typeof actions.updateEvent.start>, Observable<unknown>>(({ payload }) => {
      const { id, farmID, description, category, subCategory, endTs, penNameList, startTs, title } = payload;
      const { source, context } = _.get(state$, ["value", "events", "entities", id!]) as EventEntity;
      const url = makeAzureApiURL("annotations", "/annotations/<%= id %>")({ id });

      return Request.PUT(url, {
        body: {
          startAt: startTs,
          createdAt: TS.serialize(Epoch.now()),
          endAt: _.defaultTo(endTs, startTs),
          source: source,
          scope: {
            type: _.isEmpty(penNameList) ? EventScope.farm : EventScope.pen,
            ids: _.isEmpty(penNameList) ? [farmID.toString()] : _.map(penNameList, _.toString)
          },
          context,
          farmId: farmID,
          category,
          subCategory,
          title,
          description,
          state: "Live",
          id
        }
      }).pipe(
        map<{ response: EventSource }, Action>(({ response }) => actions.updateEvent.fulfill(response, id!)),
        catchError<unknown, Observable<Action>>((error) => {
          Debug.eventApi.error(error);
          return of(actions.updateEvent.reject(error));
        })
      );
    }),
    catchError<unknown, Observable<Action>>((error) => {
      Debug.eventApi.error(error);
      return of(actions.updateEvent.reject(error));
    })
  );
};

/**
 * Delete a timeline event
 * @param action$
 * @param stat$
 * @param Request
 */
const deleteEvent: Epic = (action$, stat$, { Request }) =>
  action$.pipe(
    ofType(types.DELETE_EVENT.pending),
    mergeMap<ReturnType<typeof actions.deleteEvent.start>, Observable<unknown>>(({ payload: { id } }) => {
      const url = makeAzureApiURL("annotations", "/annotations/<%= id %>")({ id });

      return Request.DELETE(url).pipe(
        map<unknown, Action>(() => {
          Debug.eventApi.info(`Annotation ${id} is successfully deleted`);
          return actions.deleteEvent.fulfill(id);
        }),
        catchError<unknown, Observable<Action>>((error) => {
          if (_.get(error, "status")) {
            const { status } = error;

            /**
             * This means that the service bus has deleted the annotation already
             */
            if (status === 404) {
              Debug.eventApi.info(`Annotation ${id} is already deleted by the service bus`);
              return of(actions.deleteEvent.fulfill(id));
            }
          }

          Debug.eventApi.error("An error occurred during deleting annotation", { id, error });
          return of(actions.deleteEvent.reject(id, error));
        })
      );
    })
  );

export default {
  fetchEvents,
  createEvent,
  updateEvent,
  deleteEvent
};
