import * as _ from "lodash";
import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import { BreadcrumbList, BreadcrumbEntity } from "./typings";

export const initialState: BreadcrumbList = [];

/**
 * Breadcrumbs reducer
 * @category Reducer
 * @param state
 * @param action
 */
export const reducer: Reducer<BreadcrumbList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.DROP_BREADCRUMB: {
      return [...state, payload as BreadcrumbEntity];
    }
    case types.UPDATE_BREADCRUMB: {
      const { id, title, path } = payload as BreadcrumbEntity;

      const idx = _.findIndex(state, { id });
      const newState = _.concat([], state);

      newState.splice(idx, 1, {
        id,
        title,
        path
      });

      return newState;
    }
    case types.PICK_UP_BREADCRUMB: {
      const { id } = payload as { id: string };

      return _.reject(state, ["id", id]);
    }
  }

  return state;
};

export default cleanup<BreadcrumbList>(initialState)(reducer);
