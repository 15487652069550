import * as _ from "lodash";
import { createModule, from, makeAsset } from "../../utils";

/**
 * Shared content structure between the dashboard and the farm page.
 * @type {{[p: string]: string, popover: FromArray<[string, string]>}}
 */
const content = {
  ...from("header", "loading"),
  popover: from("title", "content")
};

/**
 * Welcome message for any page
 * @type {FromArray<[string, string]>}
 */
const pageIntro = {
  pageIntro: from("title", "description")
};

const section = {
  description: (copyKey: string, sectionTitle: string): string => `${copyKey}-${_.camelCase(sectionTitle)}`
};

export const layouts = createModule("layouts", {
  dashboard: {
    ...content,
    ...pageIntro,
    compareCharts: {
      ...pageIntro,
      title: "compareCharts",
      ...content
    },
    section,
    farmDetails: {
      title: "title"
    },
    callout: {
      title: makeAsset,
      description: makeAsset,
      navigation: "navigation"
    },
    viewAnalytics: "viewAnalytics"
  },
  insights: {
    ...pageIntro,
    ...content
  },
  gamePlans: {
    ...pageIntro,
    ...content
  },
  tasks: content,
  farm: {
    ...content,
    section
  },
  section: {
    subsection: from("navTitle", "projections"),
    title: (copyKey: string, sectionTitle: string): string => `${copyKey}-${_.camelCase(sectionTitle)}`,
    goto: "goto",
    markedNew: "markedNew",
    markedBeta: "markedBeta",
    unsupported: from("title", "description"),
    featuredNew: {
      title: "title",
      content: "content"
    }
  }
});
