import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/impact-tracking";

/**
 * Fetch results for annotation
 * @category Type
 */
const FETCH_ANNOTATION_RESULTS: AsyncType = createAsyncTypes(ns, "FETCH_RESULTS", {
  primary: "annotationID",
  replays: true
});

/**
 * Fetch results for annotation
 * @category Type
 */
const ADD_METRIC: AsyncType = createAsyncTypes(ns, "ADD_METRIC", {
  primary: "annotationID"
});

export default { FETCH_ANNOTATION_RESULTS, ADD_METRIC };
