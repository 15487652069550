import { Nullable } from "@ctra/utils";

/**
 * Using "Card" as the name
 * because a card can potentially contain info other than just charts in the future
 */
export interface SavedCardsResponse {
  userId: string;
  pages: Record<string, Array<Group>>;
}

/**
 * @todo if we end up with more type of display filters, add them here
 * @todo rename to DisaplyFilter when V2 is killed
 */
export interface V3DisplayFilter {
  type: "series";
  values: Array<string>;
}

export interface Filter {
  type: FilterConfig;
  values: Array<string | number>;
}

export type AddCardPayload = {
  variantId: string;
  chartType: string;
  dateRange: Nullable<Array<string>>;
  displayFilters?: Array<V3DisplayFilter>;
  dataFilters?: Array<Filter>;
};

export interface SavedCard extends AddCardPayload {
  id: string;
}

export type SavedCardsPayload = Array<SavedCard>;

export interface Group {
  id: string;
  groupName: string;
  cards: SavedCardsPayload;
}

export enum FilterConfig {
  dimGroup = "dimGroup",
  herdGroup = "herdGroup",
  penName = "penName"
}

export type SavedCardState = {
  id: string;
  timePeriod?: {
    startDate: string;
    endDate: string;
  };
  isoDuration: Nullable<string>;
  dataFilters?: Array<Filter>;
  displayFilters?: Array<V3DisplayFilter>;
  view: string;
};

export type SavedCardList = Array<Group>;
export type SavedCards = Record<string, SavedCardList>;
