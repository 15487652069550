import { FarmEntity } from "../farms";
import { UserEntity } from "../user";
import { Optional } from "@ctra/utils";

/**
 * Role of the user that is being invited
 */
export enum ReferralRole {
  owner = "Owner",
  herdManager = "HerdManager",
  farmEmployee = "FarmEmployee",
  nutritionist = "Nutritionist",
  veterinarian = "Veterinarian",
  geneticist = "Geneticist",
  other = "Other"
}

/**
 * Status of the referral
 */
export enum ReferralStatus {
  created = "Created",
  accepted = "Accepted",
  sent = "Sent",
  declined = "Declined",
  deleted = "Deleted"
}

export enum ReferralType {
  farmerToUser = "FarmerToUserInvitation",
  userToFarmer = "UserToFarmerRequest"
}

/**
 * Referral source from the GET endpoint
 */
export interface ReferralSource {
  invitationId: string;
  invitationCode: string;
  sourceUser: {
    sourceUserId: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  farmId: FarmEntity["id"];
  farmName: FarmEntity["name"];
  targetUser: {
    firstName: string;
    lastName: string;
    organizationName?: string;
    email: string;
    role: ReferralRole;
  };
  handler: {
    userId: UserEntity["id"];
    onBehalfOfUserGroupId: string;
  };
  invitationType: ReferralType;
  invitedDateTs: string;
  lastSentTs: string;
  acceptedTs: string;
  declinedTs: string;
  deletedTs: string;
  status: ReferralStatus;
}

/**
 * Referral entity converted from the source via the normalizer
 */
export interface ReferralEntity {
  targetUser: {
    firstName: ReferralSource["targetUser"]["firstName"];
    lastName: ReferralSource["targetUser"]["lastName"];
    org?: ReferralSource["targetUser"]["organizationName"];
    email: ReferralSource["targetUser"]["email"];
    role: ReferralSource["targetUser"]["role"];
  };
  sourceUser: {
    firstName: ReferralSource["sourceUser"]["firstName"];
    lastName: ReferralSource["sourceUser"]["lastName"];
    email: ReferralSource["sourceUser"]["email"];
  };
  id: ReferralSource["invitationId"];
  farmName: ReferralSource["farmName"];
  farmID: ReferralSource["farmId"];
  code: ReferralSource["invitationCode"];
  status: ReferralSource["status"];
  type: ReferralSource["invitationType"];
}

/**
 * Payload for the POST/PUT endpoint
 */
export interface ReferralPOSTPayload {
  invitationId?: ReferralEntity["id"];
  firstName: string;
  lastName: string;
  organizationName?: string;
  email: string;
  role: ReferralRole;
}

/**
 * Referral form values: nullable id + the rest of the fields
 */
export type ReferralFormValues = Omit<ReferralEntity, "targetUser" | "sourceUser"> & {
  id: Optional<ReferralEntity["id"]>;
  firstName: ReferralSource["targetUser"]["firstName"];
  lastName: ReferralSource["targetUser"]["lastName"];
  org?: ReferralSource["targetUser"]["organizationName"];
  email: ReferralSource["targetUser"]["email"];
  role: ReferralSource["targetUser"]["role"];
};

export type InvitationList = Record<ReferralEntity["id"], ReferralEntity>;

export enum InvitationType {
  sent = "sent",
  received = "received"
}

export interface InvitationState {
  entities: InvitationList;
  [InvitationType.sent]: Array<ReferralEntity["id"]>;
  [InvitationType.received]: Array<ReferralEntity["id"]>;
}
