import { normalize } from "normalizr";
import * as _ from "lodash";

import { createAsyncActions } from "@ctra/utils";

import types from "./types";
import schemas, { NormalizedInvitation, NormalizedInvitationList } from "./schemas";
import {
  ReferralEntity,
  ReferralFormValues,
  ReferralPOSTPayload,
  ReferralSource,
  ReferralType
} from "./typings";

/**
 * Fetch a single invitation based on the invitation code
 */
const fetchInvitation = createAsyncActions(
  types.FETCH_INVITATION,
  (id: ReferralEntity["id"]) => ({ invitationId: id }),
  (
    response: ReferralSource
  ): NormalizedInvitation & {
    invitationId: ReferralSource["invitationId"];
  } => ({
    invitationId: response.invitationId,
    ...normalize(response, schemas.invitation)
  }),
  ({ error, statusCode }) => ({ error, statusCode })
);

/**
 * Fetch a single invitation based on the invitation code
 */
const fetchInvitationByCode = createAsyncActions(
  types.FETCH_INVITATION_BY_CODE,
  (code: ReferralEntity["code"]) => ({ invitationCode: code }),
  (
    response: ReferralSource
  ): ReferralEntity & {
    invitationCode: ReferralSource["invitationCode"];
  } => {
    const {
      result,
      entities: { invitation }
    } = normalize(response, schemas.invitation);

    const entity = _.get(invitation, [result], {}) as ReferralEntity;

    return {
      invitationCode: entity.code,
      ...entity
    };
  },
  ({ invitationCode, error, statusCode }) => ({ invitationCode, error, statusCode })
);

/**
 * Create a single invitation
 */
const createInvitation = createAsyncActions(
  types.CREATE_INVITATION,
  ({
    org,
    ...rest
  }: ReferralFormValues): ReferralPOSTPayload & {
    invitationType: ReferralType;
  } => ({
    invitationType: ReferralType.farmerToUser,
    organizationName: org,
    ...rest
  }),
  (invitation: ReferralSource): NormalizedInvitation => normalize(invitation, schemas.invitation),
  ({ invitationId, error, statusCode }) => ({ invitationId, error, statusCode })
);

/**
 * Request farm access via email
 * @type {AsyncActionCreator<(email: ReferralPOSTPayload["email"]) => Pick<ReferralPOSTPayload, "email">, (response: ReferralSource) => NormalizedInvitation, ({invitationId, error, statusCode}: {invitationId: any, error: any, statusCode: any}) => {invitationId: any, error: any, statusCode: any}>}
 */
const requestFarmAccess = createAsyncActions(
  types.CREATE_INVITATION,
  (
    email: ReferralPOSTPayload["email"]
  ): Pick<ReferralPOSTPayload, "email"> & {
    invitationType: ReferralType;
  } => ({
    invitationType: ReferralType.userToFarmer,
    email
  }),
  (response: ReferralSource): NormalizedInvitation => normalize(response, schemas.invitation),
  ({ invitationId, error, statusCode }) => ({ invitationId, error, statusCode })
);

/**
 * Update an invitation
 */
const updateInvitation = createAsyncActions(
  types.UPDATE_INVITATION,
  ({ id, org, ...rest }: ReferralFormValues): ReferralPOSTPayload => ({
    invitationId: id,
    organizationName: org,
    ...rest
  }),
  (invitation: ReferralSource): NormalizedInvitation & { invitationId: ReferralSource["invitationId"] } => ({
    ...normalize(invitation, schemas.invitation),
    invitationId: invitation.invitationId
  }),
  ({ error, statusCode, invitationId }) => ({ invitationId, error, statusCode })
);

/**
 * Delete an invitation
 */
const deleteInvitation = createAsyncActions(
  types.DELETE_INVITATION,
  (id: ReferralEntity["id"]) => ({ invitationId: id }),
  (id: ReferralEntity["id"]) => ({ invitationId: id }),
  ({ invitationId, error, statusCode }) => ({ invitationId, error, statusCode })
);

/**
 * Fetch a list of invitation
 */
const fetchInvitationList = createAsyncActions(
  types.FETCH_INVITATION_LIST,
  () => ({}),
  (response: Array<ReferralSource>): NormalizedInvitationList =>
    normalize(response, schemas.invitationSchema),
  ({ error, statusCode }) => ({ error, statusCode })
);

/**
 * Accept an invitation
 */
const acceptInvitation = createAsyncActions(
  types.ACCEPT_INVITATION,
  (invitationCode: string) => ({ invitationCode }),
  (response: ReferralSource) => normalize(response, schemas.singleReferral),
  ({ error, statusCode }) => ({ error, statusCode })
);

export default {
  fetchInvitationByCode,
  fetchInvitation,
  createInvitation,
  updateInvitation,
  deleteInvitation,
  fetchInvitationList,
  acceptInvitation,
  requestFarmAccess
};
