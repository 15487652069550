import { AsyncType, createAsyncTypes, createCRUDAsyncTypes, MINUTE } from "@ctra/utils";

const ns = "app/insights";

/**
 * Fetch insight mappings
 */
const FETCH_INSIGHT_NOTIFICATION_MAPPINGS = createAsyncTypes(ns, "FETCH_INSIGHT_NOTIFICATION_MAPPINGS");

/**
 * Update the insight notification settings
 */
const UPDATE_INSIGHT_NOTIFICATION_SETTINGS = createAsyncTypes(ns, "UPDATE_INSIGHT_NOTIFICATION_SETTINGS");

/**
 * Fetch insights from the server
 */
const FETCH_INSIGHTS = createAsyncTypes(ns, "FETCH_INSIGHTS", {
  primary: "hash",
  expires: 15 * MINUTE
});

/**
 * KPI insight settings
 * @category Type
 */
const [, FETCH_KPI_INSIGHT_SETTINGS, UPDATE_KPI_INSIGHT_SETTINGS]: AsyncType[] = createCRUDAsyncTypes(
  ns,
  "KPI_INSIGHT_SETTINGS"
);

/**
 * Insight validation
 * @category Type
 */
const [CREATE_INSIGHT_VALIDATION, , UPDATE_INSIGHT_VALIDATION]: AsyncType[] = createCRUDAsyncTypes(
  ns,
  "INSIGHT_VALIDATION",
  {
    primary: "id"
  }
);

/**
 * Fetch all the insight resolutions
 * @category Type
 */
const FETCH_INSIGHT_RESOLUTIONS = createAsyncTypes(ns, "FETCH_INSIGHT_RESOLUTIONS");

/**
 * Update insight
 * @category Type
 */
const UPDATE_INSIGHT = createAsyncTypes(ns, "UPDATE_INSIGHT", { primary: "id" });

/**
 * Fetch the insight types to not be dependent on type names anymore
 */
const FETCH_INSIGHT_TYPES = createAsyncTypes(ns, "FETCH_INSIGHT_TYPES");

export default {
  FETCH_INSIGHTS,
  FETCH_KPI_INSIGHT_SETTINGS,
  UPDATE_KPI_INSIGHT_SETTINGS,
  FETCH_INSIGHT_NOTIFICATION_MAPPINGS,
  UPDATE_INSIGHT_NOTIFICATION_SETTINGS,
  CREATE_INSIGHT_VALIDATION,
  UPDATE_INSIGHT_VALIDATION,
  FETCH_INSIGHT_RESOLUTIONS,
  UPDATE_INSIGHT,
  FETCH_INSIGHT_TYPES
};
