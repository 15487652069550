import { NormalizedSchema, schema } from "normalizr";
import * as _ from "lodash";

import {
  InvitationList,
  InvitationType,
  ReferralEntity,
  ReferralRole,
  ReferralSource,
  ReferralType
} from "./typings";

/**
 * Referral entity
 * @type {schema.Entity<ReferralEntity>}
 */
const referral = new schema.Entity<ReferralEntity>(
  "invitation",
  {},
  {
    idAttribute: "invitationId",
    processStrategy: ({
      invitationId,
      invitationCode,
      farmName,
      farmId,
      invitationType,
      targetUser,
      sourceUser,
      status
    }: ReferralSource) => {
      const user = invitationType === ReferralType.farmerToUser ? targetUser : sourceUser;

      return {
        farmName,
        status,
        sourceUser: {
          firstName: sourceUser.firstName,
          lastName: sourceUser.lastName,
          email: sourceUser.email
        },
        targetUser: {
          firstName: targetUser.firstName,
          lastName: targetUser.lastName,
          email: targetUser.email,
          role: targetUser.role,
          org: _.get(user, ["organizationName"], "")
        },
        id: invitationId,
        code: invitationCode,
        farmID: farmId,
        type: invitationType
      };
    }
  }
);

/**
 * List of invitations
 * @type {Array}
 */
const referralList = new schema.Array(referral);

/**
 * Send and received invitations
 * @type {Object}
 */
const referralsByDirection = new schema.Object<Array<ReferralEntity>>({
  metadata: new schema.Values({}),
  sent: referralList,
  received: referralList
});

const singleReferral = new schema.Object<ReferralEntity>({
  invitation: referral,
  metadata: new schema.Values({})
});

export type NormalizedInvitation = NormalizedSchema<{ invitation: InvitationList }, ReferralEntity["id"]>;

export type NormalizedInvitationList = NormalizedSchema<
  { invitation: InvitationList },
  {
    sent: Array<ReferralEntity["id"]>;
    received: Array<ReferralEntity["id"]>;
    metadata: Record<string, unknown>;
  }
>;

export default {
  singleReferral,
  invitation: referral,
  invitationList: referralList,
  invitationSchema: referralsByDirection
};
