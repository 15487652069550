import { Action, createAsyncActions } from "@ctra/utils";

import types from "./types";
import { RemoteSession, SignupState } from "./typings";

/**
 * Commit the form values to the store
 * @category Action
 */
const commit = <T extends Partial<SignupState>>(values: T): Action => ({
  type: types.COMMIT,
  payload: values
});

/**
 * Push the form values to the server
 * @param {SignupState} state
 * @return {{payload: SignupState, type: string}}
 * @category Action
 */
const push = (state: Partial<SignupState>) => ({
  type: types.PUSH,
  payload: state
});

/**
 * Get a link and status for a remote session
 * @type {AsyncActionCreator<() => {}, (response: RemoteSession) => RemoteSession, (error: string, statusCode: number) => {error: string, statusCode: number}>}
 * @category Action
 */
const getRemoteSession = createAsyncActions(
  types.GET_REMOTE_SESSION,
  () => ({}),
  (response: RemoteSession) => response,
  (error: string, statusCode: number) => ({ error, statusCode })
);

/**
 * Make a remote session
 * @type {AsyncActionCreator<() => {}, (response: RemoteSession) => RemoteSession, (error: string, statusCode: number) => {error: string, statusCode: number}>}
 * @category Action
 */
const makeRemoteSession = createAsyncActions(
  types.MAKE_REMOTE_SESSION,
  () => ({}),
  (response: RemoteSession) => response,
  (error: string, statusCode: number) => ({ error, statusCode })
);

export default {
  commit,
  push,
  getRemoteSession,
  makeRemoteSession
};
