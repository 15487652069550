import * as _ from "lodash";
import { AssetError, assetSeparator } from "./create-module";

/**
 * Make a unique asset from anything
 * @param copyKey
 * @param identity
 * @param rest
 */
export const makeAsset = (copyKey: string, identity: string, ...rest: string[]): string | AssetError => {
  const passes = _.isString(identity) && (!rest.length || _.every(rest, _.isString));

  const convertedIdentity = _.upperFirst(_.camelCase(identity));
  const result = `${copyKey}-${convertedIdentity || identity}${
    rest.length ? `#${_.join(_.map(rest, _.camelCase), assetSeparator)}` : ""
  }`;

  return passes ? result : { error: "Invalid asset", copyKey, identity, rest, result };
};
