import { createModule, from, makeAsset } from "../../utils";

export const network = createModule("network", {
  farms: {
    ...from("title", "description"),
    table: {
      ...from("empty"),
      columns: from("name", "status", "actions"),
      actions: from("leave")
    },
    tabs: from("farms", "invitations"),
    actions: {
      ...from("invite", "revoke")
    }
  },
  referrals: {
    ...from("title", "description"),
    actions: from("accept", "decline", "invite", "revoke"),
    alerts: {
      invitationAccepted: from("title", "description", "cta")
    }
  },
  users: {
    ...from("title", "description"),
    table: {
      ...from("empty"),
      columns: from("name", "email", "userGroup", "actions"),
      actions: from("revoke"),
      values: {
        status: makeAsset
      }
    },
    tabs: from("users", "invitations", "requests"),
    actions: {
      ...from("invite", "revoke")
    }
  }
});
