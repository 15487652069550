import { filter, Observable, of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";
import * as _ from "lodash";

import { Action, Debug } from "@ctra/utils";

import { makeAzureApiURL } from "../../utils/ajax";
import { actions as signupActions, types as signupTypes } from "../signup";
import { UserInfoRequestPayload, AccountDetailsResponse, UserListResponse } from "./typings";
import types from "./types";
import actions from "./actions";

/**
 * Fetch a user
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchUser: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_USER.pending),
    switchMap<ReturnType<typeof actions.fetchUser.start>, Observable<Promise<unknown>>>(
      ({ payload: { email } }) => {
        return Request.GET(makeAzureApiURL("accounts", "/self/user-info")()).pipe(
          map<{ response: Record<string, unknown> }, Action>(({ response }) => {
            /**
             * @todo Uncomment this when the URL gets updated with email information
             */
            // if (response.username !== email) {
            //   return sessionActions.logout();
            // }
            return actions.fetchUser.fulfill(response);
          }),
          catchError<unknown, Observable<Action>>((error) => of(actions.fetchUser.reject(error)))
        );
      }
    )
  );

/**
 * Update the signup details
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const updateSignupDetails: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_SIGNUP_DETAILS.pending),
    switchMap<ReturnType<typeof actions.updateSignupDetails.start>, Observable<Promise<unknown>>>(
      ({ payload }) =>
        Request.PUT(makeAzureApiURL("accounts", "/self/signup-details")(), {
          body: payload
        }).pipe(
          map<{ response: unknown }, Action>(() => actions.updateSignupDetails.fulfill()),
          catchError<unknown, Observable<Action>>((response) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            if (error) {
              Debug.userApi.error(error);
            }

            return of(actions.updateSignupDetails.reject(error, statusCode, details));
          })
        )
    )
  );

/**
 * Fetch a list of users
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchUserList: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_USER_LIST.pending),
    switchMap<ReturnType<typeof actions.fetchUserList.start>, Observable<Promise<unknown>>>(
      ({ payload: { source, farmID } }) => {
        const url =
          source === "compat"
            ? makeAzureApiURL("consentregistry", "/connected-users")()
            : makeAzureApiURL("consents", "/farms/<%= farmID %>/connections")({ farmID });

        return Request.GET(url).pipe(
          map<{ response: UserListResponse }, Action>(({ response }) =>
            actions.fetchUserList.fulfill(farmID, response)
          ),
          catchError<unknown, Observable<Action>>((error) => of(actions.fetchUserList.reject(farmID, error)))
        );
      }
    )
  );

/**
 * Fetch account details (during subscription)
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchAccountDetails: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_ACCOUNT_DETAILS.pending),
    switchMap<ReturnType<typeof actions.fetchAccountDetails.start>, Observable<Promise<unknown>>>(() =>
      Request.GET(makeAzureApiURL("accounts", "/self/account-details")()).pipe(
        map<{ response: AccountDetailsResponse }, Action>(({ response }) =>
          actions.fetchAccountDetails.fulfill(response)
        ),
        catchError<unknown, Observable<Action>>((response) => {
          const error = _.get(response, ["error"]);
          const statusCode = _.get(response, ["statusCode"]);
          const details = _.get(response, ["details"]);

          if (error) {
            Debug.userApi.error(error);
          }

          return of(actions.fetchAccountDetails.reject(error, statusCode, details));
        })
      )
    )
  );

/**
 * Update user info
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const updateAccountDetails: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_ACCOUNT_DETAILS.pending),
    switchMap<ReturnType<typeof actions.updateAccountDetails.start>, Observable<Promise<unknown>>>(
      ({ payload }) =>
        Request.PUT(makeAzureApiURL("accounts", "/self/account-details")(), {
          body: payload
        }).pipe(
          map<{ response: AccountDetailsResponse }, Action>(({ response }) =>
            actions.updateAccountDetails.fulfill(response)
          ),
          catchError<unknown, Observable<Action>>((response) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            if (error) {
              Debug.userApi.error(error);
            }

            return of(actions.updateAccountDetails.reject(error, statusCode, details));
          })
        )
    )
  );

/**
 * Push the account details from the signup form to the server via the updateAccountDetails action
 * @param {Observable<any>} action$
 * @return {Observable<unknown>}
 */
const pushAccountDetails: Epic = (action$) =>
  action$.pipe(
    ofType(signupTypes.PUSH),
    filter<ReturnType<typeof signupActions.push>>(({ payload }) => _.has(payload, "accountDetails")),
    map<ReturnType<typeof signupActions.push>, Action>(({ payload }) =>
      actions.updateAccountDetails.start(_.get(payload, ["accountDetails"], {}) as UserInfoRequestPayload)
    )
  );

export default {
  fetchUser,
  fetchUserList,
  fetchAccountDetails,
  updateAccountDetails,
  pushAccountDetails,
  updateSignupDetails
};
