import * as _ from "lodash";
import { createAsyncActions, Action } from "@ctra/utils";

import { UserEntity } from "../user";

import {
  ForgotPasswordFormValues,
  LoginFormValues,
  TokenEntity,
  UserRegistrationFormValues,
  AuthSource,
  RenewTokenValues,
  UserSignupFormValues
} from "./typings";

import types from "./types";
import { normalizeAuthEntity } from "./utils";

/**
 * Request an access token with a username and a password
 * @category Action
 */
const requestToken = createAsyncActions(
  types.REQUEST_TOKEN,
  (
    username: LoginFormValues["username"],
    password: LoginFormValues["password"],
    rememberMe: LoginFormValues["rememberMe"]
  ): LoginFormValues => {
    /**
     * See if the user is trying to impersonate anyone
     */
    const [originalUser, ...impersonating] = _.map(_.split(username, ","), _.trim);

    return {
      username: originalUser,
      password,
      rememberMe,
      impersonating
    };
  },
  ({ username, ...response }: AuthSource, impersonating?: NonNullable<UserEntity["email"]>) => {
    const data = {
      ...response,
      impersonating,
      username: impersonating || username
    };

    return normalizeAuthEntity(data);
  },
  (error: string, statusCode: number) => ({ error, statusCode })
);

/**
 * Renew the access token
 * @type {AsyncActionCreator<(currentToken: RenewTokenValues["currentToken"], refreshToken: RenewTokenValues["refreshToken"], provider: RenewTokenValues["provider"]) => RenewTokenValues, (response: AuthSource) => AuthEntity, (error: string, statusCode: number) => {error: string, statusCode: number}>}
 */
const renewToken = createAsyncActions(
  types.RENEW_TOKEN,
  (
    currentToken: RenewTokenValues["currentToken"],
    refreshToken: RenewTokenValues["refreshToken"],
    provider: RenewTokenValues["provider"]
  ): RenewTokenValues => ({ currentToken, refreshToken, provider }),
  (response: AuthSource) => normalizeAuthEntity(response),
  (error: string, statusCode: number) => ({ error, statusCode })
);

/**
 * Report a forgotten password
 * @category Action
 */
const reportForgotPassword = createAsyncActions(
  types.REPORT_FORGOT_PASSWORD,
  (username): ForgotPasswordFormValues => ({ username }),
  (response) => response,
  (error: string) => ({ error })
);

/**
 * Reset the user password
 */
const resetPassword = createAsyncActions(
  types.RESET_PASSWORD,
  (userId, password, otp) => ({ userId, password, otp }),
  (response) => response,
  (error: string) => ({ error })
);

/**
 * Register user
 */
const registerUser = createAsyncActions(
  types.REGISTER_USER,
  /**
   * @todo change fields
   */
  (registrationFormValues: UserRegistrationFormValues) => registrationFormValues,
  (response) => response,
  (error) => ({ error })
);

/**
 * Accept Invite
 */
const acceptInvite = createAsyncActions(
  types.ACCEPT_INVITE,
  (opportunityID: string) => ({
    opportunityID
  }),
  (response) => response,
  (error: string) => ({ error })
);

/**
 * Sign up user
 */
const signupUser = createAsyncActions(
  types.SIGN_UP_USER,
  (signupFormValues: UserSignupFormValues) => signupFormValues,
  (response) => response,
  (error) => ({ error })
);

/**
 * Activate user
 */
const activateUser = createAsyncActions(
  types.ACTIVATE_USER,
  (token, eulaAccepted) => ({ token, eulaAccepted: eulaAccepted.toString() }),
  (response) => response,
  (error) => ({ error })
);

/**
 * Request activation and send email
 */
const requestActivation = createAsyncActions(
  types.REQUEST_ACTIVATION,
  (email) => ({ email }),
  (response) => response,
  (error) => ({ error })
);

/**
 * Inject the token gotten from the URL into state
 * @param payload
 * @returns
 * @category Action
 */
const injectToken = (payload: TokenEntity): Action => ({
  type: types.INJECT_TOKEN,
  payload
});

/**
 * Set the username
 * @param payload
 * @returns
 * @category Action
 */
const setUser = (payload: Pick<UserEntity, "username">): Action => ({
  type: types.SET_USER,
  payload
});

export default {
  reportForgotPassword,
  requestToken,
  resetPassword,
  registerUser,
  injectToken,
  setUser,
  renewToken,
  acceptInvite,
  signupUser,
  activateUser,
  requestActivation
};
