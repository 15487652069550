import { Typography, Space } from "antd";
import { FC, HTMLProps } from "react";
import { LikeOutlined } from "@ant-design/icons";

import { classname } from "@ctra/utils";

import { ContentWrapper } from "../../layout";
import styles from "./EmptyPanel.module.less";

export interface EmptyProps extends HTMLProps<HTMLDivElement> {
  /**
   * empty description
   */
  description: string;
}

const { Text } = Typography;

/**
 * Empty farm overview panel component
 */
const EmptyPanel: FC<EmptyProps> = ({ description, className, ...rest }) => (
  <ContentWrapper className={classname("ctra-farmOverview-empty", styles.Empty, className)}>
    <Space size={12} align="center" direction="horizontal">
      <LikeOutlined />
      <Text className={styles.Text}>{description}</Text>
    </Space>
  </ContentWrapper>
);

export default EmptyPanel;
