import * as _ from "lodash";

import { Action as ReduxAction } from "redux";
import { Action } from "@ctra/utils";

import { types } from "../modules/session";

/**
 * @todo add your action types which should result in cleanup
 */
const typesWhichCleanup: string[] = [types.LOGOUT, types.RESET];

type CleanReducer<S, A extends Action = Action> = (state: S, action: A) => S;

/**
 * Higher order reducer to clean up automatically
 * @param {T} initialState
 * @param {string} cleanupAction
 * @return {(reducer: CleanReducer<T, A>) => (state: T, action: A) => T}
 */
export const cleanup =
  <T, A extends ReduxAction = Action>(initialState: T, cleanupAction?: string) =>
  (reducer: CleanReducer<T, A>) =>
  (state = initialState, action: A): T => {
    if (_.isPlainObject(action) && action.type) {
      /**
       * Tell whether the state should be cleaned up
       * @type {boolean}
       */
      const shouldCleanup = cleanupAction
        ? cleanupAction === action.type
        : typesWhichCleanup.includes(action.type.toString());

      return shouldCleanup ? initialState : reducer(state, action);
    }

    return reducer(state, action);
  };
