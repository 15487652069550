import { normalize, denormalize } from "normalizr";
import { QueryOptions } from "odata-query";
import * as _ from "lodash";

import { createAsyncActions, Epoch, readableHash } from "@ctra/utils";

import schemas, {
  NormalizedKPIInsightSettings,
  NormalizedInsightList,
  NormalizedInsightTypeList
} from "./schemas";

import types from "./types";

import {
  GenericInsightEntity,
  GenericInsightSource,
  NotificationMappingEntity,
  InsightResolutionEntity,
  InsightValidationEntity,
  InsightValidationSource,
  KPIInsightSettingsState,
  NotificationAction,
  NotificationType,
  InsightTypeSource,
  KPIInsightServerValues
} from "./typings";

export type FetchInsightsFulfilledPayload = NormalizedInsightList & {
  hash: string;
  list?: string;
};

/**
 * Fetch insight mappings
 */
const fetchInsightNotificationMappings = createAsyncActions(
  types.FETCH_INSIGHT_NOTIFICATION_MAPPINGS,
  () => ({}),
  (mappings) => normalize(mappings, schemas.mappingSchema),
  (error: string): { error: string } => ({ error })
);

/**
 * Update insight notification settings
 */
const updateInsightNotificationSettings = createAsyncActions(
  types.UPDATE_INSIGHT_NOTIFICATION_SETTINGS,
  (
    notificationType: NotificationType,
    notificationMappingID: NotificationMappingEntity["id"],
    notificationAction: NotificationAction
  ) => ({
    notificationType,
    notificationMappingID,
    notificationAction
  }),
  (
    notificationType: NotificationType,
    notificationMappingID: NotificationMappingEntity["id"],
    notificationAction: NotificationAction
  ) => ({
    notificationType,
    notificationMappingID,
    notificationAction
  }),
  (error: string): { error: string } => ({ error })
);

/**
 * Fetch insights from the oData endpoint
 */
const fetchInsights = createAsyncActions(
  types.FETCH_INSIGHTS,
  (query: Partial<QueryOptions<unknown>>, list?: string) => ({
    query,
    list,
    hash: readableHash(query)
  }),
  (hash: string, insights: Array<GenericInsightSource>, list?: string): FetchInsightsFulfilledPayload => ({
    hash,
    list,
    ...normalize(
      /**
       * Pre-parse the insight resolutions so normalization
       * can deal with it in a proper way.
       */
      _.map(insights, ({ insightResolution, ...rest }) => ({
        insightResolution:
          /* istanbul ignore next - this simple fallback needs no separate test case */ insightResolution
            ? JSON.parse(insightResolution)
            : [],
        ...rest
      })),
      schemas.insightList
    )
  }),
  (hash, error) => ({ hash, error })
);

/**
 * Fetch the KPI insight settings
 * @category Action
 */
const fetchKPIInsightSettings = createAsyncActions(
  types.FETCH_KPI_INSIGHT_SETTINGS,
  () => ({}),
  (kpiInsightSettingsResponse: { preferences: KPIInsightSettingsState }): NormalizedKPIInsightSettings =>
    normalize(kpiInsightSettingsResponse.preferences, schemas.kpiInsightSettings),
  (error: string): { error: string } => ({ error })
);

/**
 * Update the KPI insight settings
 * @category Action
 */
const updateKPIInsightSettings = createAsyncActions(
  types.UPDATE_KPI_INSIGHT_SETTINGS,
  (settings: KPIInsightServerValues) => settings,
  (settings: KPIInsightServerValues) => settings,
  (error: string): { error: string } => ({ error })
);

/**
 * Create a new insight validation
 */
const createInsightValidation = createAsyncActions(
  types.CREATE_INSIGHT_VALIDATION,
  (validation: InsightValidationEntity) => ({
    ...validation,
    validationEpoch: Epoch.now()
  }),
  (validation: InsightValidationSource) => normalize(validation, schemas.insightValidation),
  (error: string): { error: string } => ({ error })
);

/**
 * Update an existing insight validation
 */
const updateInsightValidation = createAsyncActions(
  types.UPDATE_INSIGHT_VALIDATION,
  (validation: InsightValidationEntity, updatedValues: Partial<InsightValidationEntity>) => ({
    ...validation,
    ...updatedValues,
    validationEpoch: Epoch.now()
  }),
  (validation: InsightValidationEntity) => validation,
  (
    id: InsightValidationEntity["id"],
    error: string
  ): {
    id: InsightValidationEntity["id"];
    error: string;
  } => ({ id, error })
);

/**
 * Fetch the insight rsolutions from the "server"
 */
const fetchInsightResolutions = createAsyncActions(
  types.FETCH_INSIGHT_RESOLUTIONS,
  () => ({}),
  (resolutions: Array<InsightResolutionEntity>) => normalize(resolutions, schemas.insightResolutionList),
  (error: string): { error: string } => ({ error })
);

/**
 * Update an insight
 */
const updateInsight = createAsyncActions(
  types.UPDATE_INSIGHT,
  (
    genericInsightID: GenericInsightEntity["id"],
    updates: Partial<GenericInsightEntity>,
    entities: Record<string, unknown> = {}
  ) => ({
    id: genericInsightID,
    updates,
    source: denormalize(
      {
        ...updates,
        id: genericInsightID
      },
      schemas.insight,
      entities
    )
  }),
  (id, insight: Partial<GenericInsightEntity>) => ({
    id,
    ...insight
  }),
  (
    id: GenericInsightEntity["id"],
    error: string
  ): {
    id: GenericInsightEntity["id"];
    error: string;
  } => ({
    id,
    error
  })
);

/**
 * Fetch all the insight types
 */
const fetchInsightTypes = createAsyncActions(
  types.FETCH_INSIGHT_TYPES,
  () => ({}),
  (insightTypeList: Array<InsightTypeSource>): NormalizedInsightTypeList =>
    normalize(insightTypeList, schemas.insightTypeList),
  (error) => ({ error })
);

export default {
  fetchInsights,
  fetchKPIInsightSettings,
  updateKPIInsightSettings,
  fetchInsightNotificationMappings,
  updateInsightNotificationSettings,
  createInsightValidation,
  updateInsightValidation,
  fetchInsightResolutions,
  updateInsight,
  fetchInsightTypes
};
