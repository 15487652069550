import hash from "object-hash";
import WordHash from "wordhash";

const wordHash = WordHash();

/**
 * Make a human-readable hash of an object
 * @param {Record<string, unknown>} obj
 * @returns {string}
 */
export const readableHash = (obj: Record<string, unknown>): string => {
  return wordHash.hash(hash(obj));
};
