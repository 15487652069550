import { filter, mergeMap, Observable, of } from "rxjs";
import { switchMap, map, catchError, mapTo } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";
import * as _ from "lodash";

import { Action, Debug } from "@ctra/utils";

import { makeAzureApiURL, withSandboxPrefix } from "../../utils/ajax";
import { types as signupTypes, actions as signupActions, DHMSDetails } from "../signup";
import types from "./types";
import actions from "./actions";
import { FarmDetailsRequestPayload, FarmDetailsUpdatePayload, FarmListResponse } from "./typings";

/**
 * Request a list of farms
 * @param action$
 * @param state$
 * @param Request
 */
const fetchFarms: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_FARM_LIST.pending),
    switchMap(() => {
      return Request.GET(
        makeAzureApiURL(withSandboxPrefix("analytics", state$), "/info/farm-details")()
      ).pipe(
        map<{ response: FarmListResponse }, Action>(({ response }) => {
          const { farms } = response;

          /**
           * Map the type of herdGroup and pen
           * @type {unknown[]}
           */
          const mappedPenTypes = _.map(farms, ({ pens, ...rest }) => {
            return {
              ...rest,
              pens: _.map(pens, ({ herdGroupId, ...rest }) => ({
                schema: herdGroupId ? "herdGroup" : "pen",
                herdGroupId,
                ...rest
              }))
            };
          });

          return actions.fetchFarmList.fulfill({
            farms: mappedPenTypes
          });
        }),
        catchError<unknown, Observable<Action>>((error) => {
          return of(actions.fetchFarmList.reject(error));
        })
      );
    })
  );

/**
 * Request the farm the user is registered with (during subscription)
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchUserFarm: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_USER_FARM.pending),
    switchMap<ReturnType<typeof actions.fetchUserFarm.start>, Observable<Promise<unknown>>>(() => {
      return Request.GET(makeAzureApiURL("accounts", "/self/farm-details")()).pipe(
        map<{ response: FarmDetailsRequestPayload }, Action>(({ response }) => {
          return actions.fetchUserFarm.fulfill(response);
        }),
        catchError<Action, Observable<Action>>(({ response }) => {
          const error = _.get(response, ["error"]);
          const statusCode = _.get(response, ["statusCode"]);
          const details = _.get(response, ["details"]);

          if (error) {
            Debug.farmApi.error(error);
          }

          return of(actions.fetchUserFarm.reject(error, statusCode, details));
        })
      );
    })
  );

/**
 * Update the farm the user is registered with (during subscription)
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const updateFarmDetails: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_USER_FARM.pending),
    switchMap<ReturnType<typeof actions.updateUserFarm.start>, Observable<Promise<unknown>>>(
      ({ payload }) => {
        return Request.PUT(makeAzureApiURL("accounts", "/self/farm-details")(), {
          body: _.pickBy(payload, _.negate(_.isEmpty))
        }).pipe(
          map<{ response: FarmDetailsRequestPayload }, Action>(({ response }) =>
            actions.updateUserFarm.fulfill(response)
          ),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            if (error) {
              if (error) {
                Debug.farmApi.error(error);
              }
            }

            return of(actions.updateUserFarm.reject(error, statusCode, details));
          })
        );
      }
    )
  );

/**
 * Push the farm details from the signup form to the server via the updateUserFarm action
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const pushFarmDetails: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(signupTypes.PUSH),
    filter<ReturnType<typeof signupActions.push>>(
      ({ payload }) => _.has(payload, "farmDetails") || _.has(payload, "dhms")
    ),
    map<ReturnType<typeof signupActions.push>, Action>(({ payload }) => {
      /**
       * Pick the farm details from the payload
       */
      const { name, city, country, address, postCode } = _.get(
        payload,
        ["farmDetails"],
        {}
      ) as FarmDetailsRequestPayload;

      /**
       * Pick the DHMS details from the payload
       */
      const { dhms, other, notification } = _.get(payload, ["dhms"]) as DHMSDetails;

      const params: FarmDetailsUpdatePayload = {
        farmName: name,
        zipCode: postCode,
        address,
        city,
        country,
        dhmsDetails: _.pickBy(
          {
            dhms,
            other,
            notification
          },
          _.negate(_.isEmpty)
        )
      } as FarmDetailsUpdatePayload;

      return actions.updateUserFarm.start(params);
    })
  );

const fetchDataIntegrations: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_DATA_INTEGRATIONS.pending),
    mergeMap<ReturnType<typeof actions.fetchDataIntegrations.start>, Observable<Promise<unknown>>>(
      ({ payload: { farmID } }) => {
        return Request.GET(
          makeAzureApiURL(withSandboxPrefix("analytics", state$), "/info/farms/<%= farmID %>")({ farmID })
        ).pipe(
          map<{ response: Record<string, unknown> }, Action>(({ response }) => {
            return actions.fetchDataIntegrations.fulfill(farmID, response);
          }),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);
            const details = _.get(response, ["details"]);

            if (error) {
              Debug.farmApi.error(error);
            }

            return of(actions.fetchDataIntegrations.reject(farmID, error, statusCode, details));
          })
        );
      }
    )
  );

export default {
  fetchUserFarm,
  fetchFarms,
  updateFarmDetails,
  pushFarmDetails,
  fetchDataIntegrations
};
