import { createContext, useState, useEffect, Provider, ReactNode, useContext } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import * as _ from "lodash";

import { makeTrackEvent } from "../../hoc";

export interface GAProviderDefaultValues {
  category: string;
}

export interface GAProviderAPI {
  readonly trackEvent: ReturnType<typeof makeTrackEvent>;
  readonly setContextValue: (key: string, value: unknown) => void;
}

export type GAProviderPublicAPI<T = Record<string, unknown>> = T & GAProviderAPI & GAProviderDefaultValues;

/**
 * Tell if the host is in sandbox mode
 * @return {boolean}
 */
const isSandbox = () => window.location.host === "demo.app.connecterra.ai";

/**
 * Default Google Analytics context with category and any extra info
 */
export const DefaultContext = createContext<
  Record<string, unknown> & GAProviderDefaultValues & GAProviderAPI
>({
  category: `${isSandbox() ? "[DEMO] " : ""}Uncategorized`,
  setContextValue: _.noop,
  trackEvent: _.noop
});

/**
 * GA custom provider that manages adhoc variables
 * @param value
 * @param children
 * @constructor
 */
function GAProvider<T extends Record<string, unknown>>({
  value,
  children
}: {
  value: T & GAProviderDefaultValues;
  children?: ReactNode;
}): ReturnType<Provider<GAProviderPublicAPI<T>>> {
  const { category } = value;
  const [currentContext, setCurrentContext] = useState(value);
  const trackEvent = makeTrackEvent(isSandbox() ? `[DEMO] ${category}` : category);

  useDeepCompareEffect(() => {
    setCurrentContext(value);
  }, [value]);

  const setContextValue = (name: string, value: unknown) => {
    setCurrentContext({ ...currentContext, [name]: value });
  };

  return (
    <DefaultContext.Provider value={{ ...currentContext, trackEvent, setContextValue }}>
      {children}
    </DefaultContext.Provider>
  );
}

GAProvider.$$typeof = Symbol("Google Analytics Context Provider");

export const GAContext = {
  Provider: GAProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Make a hook for Google Analytics
 * @return {GAProviderPublicAPI<T>}
 */
export const useGoogleAnalytics = <T extends Record<string, unknown>>(): GAProviderPublicAPI<T> =>
  // @ts-ignore - the value of DefaultContext is somewhat different to GAProviderPublicAPI, but that is our intention
  useContext<GAProviderPublicAPI<T>>(DefaultContext);
