import { ComponentProps, FC } from "react";
import { Collapse, List, Spin } from "antd";

import { classname } from "@ctra/utils";
import styles from "./OverviewPanel.module.less";

const { Panel } = Collapse;

export interface OverviewPanelProps extends ComponentProps<typeof Panel> {
  isLoading?: boolean;
  list: ComponentProps<typeof List>;
}

/**
 * Overview panel component for the farm overview Collapse
 * @param {string | undefined} className
 * @param {boolean | undefined} isLoading
 * @param {(<T>({pagination, prefixCls: customizePrefixCls, bordered, split, className, children, itemLayout, loadMore, grid, dataSource, size, header, footer, loading, rowKey, renderItem, locale, ...rest}: ListProps<T>) => JSX.Element) | {Item: import("./Item").ListItemTypeProps} extends React.JSXElementConstructor<infer P> ? P : ((<T>({pagination, prefixCls: customizePrefixCls, bordered, split, className, children, itemLayout, loadMore, grid, dataSource, size, header, footer, loading, rowKey, renderItem, locale, ...rest}: ListProps<T>) => JSX.Element) | {Item: import("./Item").ListItemTypeProps} extends keyof JSX.IntrinsicElements ? JSX.IntrinsicElements[(<T>({pagination, prefixCls: customizePrefixCls, bordered, split, className, children, itemLayout, loadMore, grid, dataSource, size, header, footer, loading, rowKey, renderItem, locale, ...rest}: ListProps<T>) => JSX.Element) | {Item: import("./Item").ListItemTypeProps}] : {})} list
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} header
 * @param {Pick<OverviewPanelProps & {children?: React.ReactNode | undefined}, "key" | "disabled" | "style" | "showArrow" | "prefixCls" | "forceRender" | "id" | "extra" | "collapsible" | "children">} props
 * @return {JSX.Element}
 * @constructor
 */
const OverviewPanel: FC<OverviewPanelProps> = ({ className, isLoading = false, list, header, ...props }) => {
  const { loadMore, ...restOfList } = list;

  return (
    <Panel
      className={classname("ctra-farmOverview-panel", styles.Panel, className)}
      header={<Spin spinning={isLoading}>{header}</Spin>}
      {...props}
    >
      <Spin spinning={isLoading}>
        <List
          {...restOfList}
          loadMore={loadMore ? <List.Item style={{ justifyContent: "center" }}>{loadMore}</List.Item> : null}
        />
      </Spin>
    </Panel>
  );
};

export default OverviewPanel;
