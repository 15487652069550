import { readableHash } from "@ctra/utils";

import { DataDescriptorEntity } from "../data-dictionary";
import { FarmEntity } from "../farms";
import { ScorecardValueParams } from "./typings";

/**
 * Make a scorecard hash from the given data descriptor id, farm id and optional params
 * @param dataDescriptorID
 * @param farmID
 * @param params
 * @example
 * ```ts
 * makeScorecardHash(
 *   "1234-asdf-2345-sdfg",
 *   123,
 *   {}
 * );
 * // "836a4b19e5901e519a5713e812929e3ced6ef246"
 * ```
 */
export const makeScorecardHash = (
  dataDescriptorID: DataDescriptorEntity["id"],
  farmID: FarmEntity["id"],
  params?: ScorecardValueParams
): string =>
  readableHash({
    dataDescriptorID,
    farmID,
    ...params
  });
