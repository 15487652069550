import { ComponentProps, FC } from "react";
import Markdown from "react-markdown";
import { Col, Row, Space, Tooltip, Typography } from "antd";

import { classname } from "@ctra/utils";

import { ContentWrapper } from "../../../layout";
import { ActionKey, ArrowsAltOutlined, BrowserOutlined, InfoCircleOutlined } from "../../../..";

import styles from "./MiniChartCard.module.less";

const { Text } = Typography;

export interface MiniChartCardProps extends ComponentProps<typeof ContentWrapper> {
  /**
   * Metric title
   */
  metric: string;
  /**
   * Variant title
   */
  variant: string;
  /**
   * Source title
   */
  source?: string;
  /**
   * tooltip info
   */
  tooltip?: string;
}

/**
 * MiniChartCard that wraps up the chart
 */
const MiniChartCard: FC<MiniChartCardProps> = ({
  metric,
  variant,
  source,
  children,
  tooltip,
  className,
  ...rest
}) => {
  return (
    <ContentWrapper className={classname(className, styles.ChartCard)} {...rest}>
      <Row wrap={false} justify="space-between">
        <Row align="middle" wrap={false}>
          <Text className={styles.Title}>{metric}</Text>
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Tooltip
              title={
                tooltip ? (
                  <Markdown
                    components={{
                      a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" />
                    }}
                  >
                    {tooltip}
                  </Markdown>
                ) : (
                  void 0
                )
              }
              placement="right"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </Row>
        <Text type="secondary" className={styles.Expand}>
          <ArrowsAltOutlined />
        </Text>
      </Row>
      <Row gutter={[24, 0]} className={styles.Metric}>
        <Col>
          <Space align="center">
            <ActionKey />
            <Text type="secondary">{variant}</Text>
          </Space>
        </Col>
        <Col>
          {source && (
            <Space align="center">
              <BrowserOutlined />
              <Text type="secondary">{source}</Text>
            </Space>
          )}
        </Col>
      </Row>
      <Row>{children}</Row>
    </ContentWrapper>
  );
};

export default MiniChartCard;
