import moment from "moment";

/**
 * Tell whether the given epoch is in a long format
 * @param {number} epoch
 * @private
 */
const _isLongEpoch = (epoch: number): boolean => epoch.toString().length === 13;

/**
 * Parse a 10 or 13 digit epoch to moment
 * @param {number} epoch
 * @example
 * ```ts
 * asMoment(1608208158000).isSame(asMoment(1608208158));
 * // true
 * ```
 */
export const asMoment = (epoch: number): moment.Moment => moment(_isLongEpoch(epoch) ? epoch : epoch * 1000);

/**
 * Convert the given epoch to Date
 * @param epoch
 * @example
 * ```ts
 * asDate(1234567890);
 * // Date of 1234567890
 * ```
 */
export const asDate = (epoch: number): Date => new Date(_isLongEpoch(epoch) ? epoch : epoch * 1000);

/**
 * Convert a given epoch to long format
 * @param epoch
 * ```ts
 * asLong(1234567890); // 1234567890000
 */
export const asLong = (epoch: number): number => (_isLongEpoch(epoch) ? epoch : epoch * 1000);

/**
 * Load an epoch and convert it to a 10 digit version
 * @param {number} epoch
 * @example
 * ```ts
 * load(1608208158000);
 * // 1608208158
 *
 * load(1608208158);
 * // 1608208158
 * ```
 */
export const load = (epoch: number): number => (_isLongEpoch(epoch) ? Math.round(epoch / 1000) : epoch);

/**
 * Get today midnight
 * @param trail
 * @example
 * ```ts
 * midnight();
 * // 1234567890
 * midnight({ trail: true });
 * // 2345678901
 * ```
 */
export const midnight = ({ trail }: { trail?: boolean } = {}): number =>
  load(+moment()[trail ? "endOf" : "startOf"]("day").utc());

/**
 * Get the current epoch
 * @example
 * ```ts
 * now();
 * // 1234567890
 * ```
 */
export const now = (): number => load(+moment.utc());

/**
 * Tell the difference between two epochs in days
 * @param start
 * @param end
 * @todo add example
 * @example
 * ```ts
 * ```
 */
export const daysBetween = (start: number, end: number): number =>
  asMoment(end).diff(asMoment(start), "days");

/**
 * Tell how much time is left until the given epoch
 * @param epoch
 * @todo add example
 * @example
 * ```ts
 * ```
 */
export const until = (epoch: number): string => moment.duration(daysBetween(epoch, now()), "days").humanize();
