import { Reducer } from "@ctra/utils";

import { types as sessionTypes } from "../session";
import { UserEntity } from "../user";
import { AuthEntity, AuthState } from "./typings";
import types from "./types";
import { TokenEntity } from ".";

export const initialState: AuthState = {
  user: null,
  /**
   * Track intentional logouts for more accurate user status
   */
  logout: false,
  token: {
    accessToken: null,
    expiresIn: null,
    tokenType: null,
    expires: null,
    issued: null,
    refresh: null
  },
  error: null
};

/**
 * Reducer for access token related actions
 * @param state
 * @param action
 * @category Reducer
 */
export const reducer: Reducer<AuthState> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case sessionTypes.LOGOUT: {
      return {
        ...initialState,
        logout: true
      };
    }
    case types.REQUEST_TOKEN.pending:
    case types.SET_USER: {
      const { username, impersonating } = payload as {
        username: UserEntity["username"];
        impersonating?: UserEntity["username"];
      };

      return {
        ...state,
        error: null,
        user: impersonating || username
      };
    }
    case types.REQUEST_TOKEN.fulfilled:
    case types.RENEW_TOKEN.fulfilled: {
      const { token, user, impersonating } = payload as AuthEntity;

      return {
        ...state,
        user,
        token,
        impersonating,
        error: null,
        logout: false
      };
    }
    case types.INJECT_TOKEN: {
      return {
        ...state,
        token: payload as TokenEntity,
        error: null,
        logout: false
      };
    }
    case types.REGISTER_USER.rejected: {
      const { error } = payload;
      return {
        ...initialState,
        error
      };
    }
  }

  return state || initialState;
};

export default reducer;
