import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";

import { Action } from "@ctra/utils";
import { Group, SavedCard, SavedCardsResponse } from "./typings";

import { makeAzureApiURL } from "../../utils/ajax";
import types from "./types";
import actions from "./actions";

/**
 * Fetch saved charts
 * @param action$
 * @param state$
 * @param Request
 */
const fetchSavedCharts: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_SAVED_CHARTS.pending),
    mergeMap(() =>
      Request.GET(makeAzureApiURL("accounts", "/self/cards")()).pipe(
        map<{ response: SavedCardsResponse }, Action>(({ response }) =>
          actions.fetchSavedCharts.fulfill(response)
        ),
        catchError<unknown, Observable<Action>>((error) => of(actions.fetchSavedCharts.reject(error)))
      )
    )
  );

/**
 * Add new card
 * @param action$
 * @param state$
 * @param Request
 */
const addCard: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.ADD_CARD.pending),
    mergeMap<ReturnType<typeof actions.addCard.start>, Observable<Promise<unknown>>>(
      ({ payload: { pageKey, groupID, groupName, cardPayload } }) =>
        Request.POST(
          makeAzureApiURL(
            "accounts",
            `/self/cards?pageKey=${pageKey}&cardGroupId=${groupID}&cardGroupName=${groupName}`
          )(),
          {
            body: cardPayload
          }
        ).pipe(
          map<{ response: SavedCard }, Action>(({ response }) => {
            return actions.addCard.fulfill(pageKey, groupID, response);
          }),
          catchError<unknown, Observable<Action>>((error) => of(actions.addCard.reject(error)))
        )
    )
  );

/**
 * Add new tab
 * @param action$
 * @param state$
 * @param Request
 */
const addTab: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.ADD_TAB.pending),
    mergeMap<ReturnType<typeof actions.addTab.start>, Observable<Promise<unknown>>>(
      ({ payload: { pageKey, groupName } }) =>
        Request.POST(makeAzureApiURL("accounts", `/self/cards/groups?pageKey=${pageKey}`)(), {
          body: { groupName, cards: [] }
        }).pipe(
          map<{ response: Group }, Action>(({ response }) => {
            return actions.addTab.fulfill(pageKey, response);
          }),
          catchError<unknown, Observable<Action>>((error) => of(actions.addTab.reject(error)))
        )
    )
  );

/**
 * Update tabs (add/remove/reorder)
 * @param action$
 * @param state$
 * @param Request
 */
const updateTabs: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_TABS.pending),
    mergeMap<ReturnType<typeof actions.updateTabs.start>, Observable<Promise<unknown>>>(
      ({ payload: { pageKey, savedCharts } }) =>
        Request.PUT(makeAzureApiURL("accounts", `/self/cards?pageKey=${pageKey}`)(), {
          body: savedCharts
        }).pipe(
          map<{ response: null }, Action>(() => actions.updateTabs.fulfill(pageKey, savedCharts)),
          catchError<unknown, Observable<Action>>((error) => of(actions.updateTabs.reject(error)))
        )
    )
  );

/**
 * Save chart to group/tab
 * @param action$
 * @param state$
 * @param Request
 */
const updateCharts: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_CHARTS.pending),
    mergeMap<ReturnType<typeof actions.updateCharts.start>, Observable<Promise<unknown>>>(
      ({ payload: { pageKey, savedCharts } }) =>
        Request.PUT(makeAzureApiURL("accounts", `/self/cards?pageKey=${pageKey}`)(), {
          body: savedCharts
        }).pipe(
          map<{ response: null }, Action>(() => actions.updateCharts.fulfill(pageKey, savedCharts)),
          catchError<unknown, Observable<Action>>((error) => of(actions.updateCharts.reject(error)))
        )
    )
  );

export default {
  updateCharts,
  updateTabs,
  fetchSavedCharts,
  addCard,
  addTab
};
