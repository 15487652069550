import { createAsyncActions, Nullable } from "@ctra/utils";
import { normalize } from "normalizr";

import schemas, { NormalizedFarmList } from "./schemas";
import types from "./types";
import { FarmListResponse, FarmDetailsRequestPayload, FarmDetailsUpdatePayload, FarmEntity } from "./typings";

/**
 * Fetch farm list for Ida or Ida Enterprise user
 * @type {AsyncActionCreator<(account: ("farmer" | "enterprise")) => {account: string}, (farmListResponse: Record<string, unknown>) => NormalizedFarmList, (error: string) => {error: string}>}
 * @category Action
 */
const fetchFarmList = createAsyncActions(
  types.FETCH_FARM_LIST,
  (account: "farmer" | "enterprise"): { account: string } => ({
    account
  }),
  (farmListResponse: FarmListResponse): NormalizedFarmList => normalize(farmListResponse, schemas.farmList),
  (error: string): { error: string } => ({ error })
);

/**
 * Fetch the farm the user is registered with (during subscription)
 * @type {AsyncActionCreator<() => {}, (FarmDetailsRequestPayload: FarmDetailsRequestPayload) => NormalizedSchema<{[p: string]: {[p: string]: {[p: string]: any, [p: number]: any, gpsCoordinates: {lon: number, lat: number}, id: any}} | undefined}, any>, (error: string, statusCode: number, details: string) => {error: string, statusCode: number, details: string}>}
 * @category Action
 */
const fetchUserFarm = createAsyncActions(
  types.FETCH_USER_FARM,
  () => ({}),
  (farmDetailsRequestPayload: Nullable<FarmDetailsRequestPayload>): Nullable<FarmDetailsRequestPayload> =>
    farmDetailsRequestPayload,
  (
    error: string,
    statusCode: number,
    details: string
  ): { error: string; statusCode: number; details: string } => ({
    error,
    statusCode,
    details
  })
);

/**
 * Update the farm the user is registered with (during subscription)
 * @type {AsyncActionCreator<(farmId: Nullable<FarmEntity["id"]>, farmDetails: FarmDetailsRequestPayload) => ({farmId: Nullable<FarmEntity["id"]>} & FarmDetailsRequestPayload), (FarmDetailsRequestPayload: FarmDetailsRequestPayload) => NormalizedSchema<{[p: string]: {[p: string]: {[p: string]: any, [p: number]: any, gpsCoordinates: {lon: number, lat: number}, id: any}} | undefined}, any>, (error: string, statusCode: number, details: string) => {error: string, statusCode: number, details: string}>}
 * @category Action
 */
const updateUserFarm = createAsyncActions(
  types.UPDATE_USER_FARM,
  (farmDetails: FarmDetailsUpdatePayload): FarmDetailsUpdatePayload => farmDetails,
  (FarmDetailsRequestPayload: FarmDetailsRequestPayload): FarmDetailsRequestPayload =>
    FarmDetailsRequestPayload,
  (
    error: string,
    statusCode: number,
    details: string
  ): { error: string; statusCode: number; details: string } => ({
    error,
    statusCode,
    details
  })
);

/**
 * Fetch data integrations
 * @type {AsyncActionCreator<() => {}, (dataIntegrations: Record<string, unknown>) => Record<string, unknown>, (error: string, statusCode: number, details: string) => {error: string, statusCode: number, details: string}>}
 */
const fetchDataIntegrations = createAsyncActions(
  types.FETCH_DATA_INTEGRATIONS,
  (farmID: FarmEntity["id"]) => ({ farmID }),
  (farmID: FarmEntity["id"], dataIntegrations: Record<string, unknown>): Record<string, unknown> => ({
    farmID,
    dataIntegrations
  }),
  (
    farmID: FarmEntity["id"],
    error: string,
    statusCode: number,
    details: string
  ): { error: string; statusCode: number; details: string; farmID: FarmEntity["id"] } => ({
    farmID,
    error,
    statusCode,
    details
  })
);

export default {
  fetchFarmList,
  fetchUserFarm,
  updateUserFarm,
  fetchDataIntegrations
};
