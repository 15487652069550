import { default as reducer, initialState } from "./reducer";
import { Debug } from "@ctra/utils";

Debug.create("authApi", "Auth API", { transports: true });
Debug.create("signupApi", "Signup API", { transports: true });

export { default as types } from "./types";
export { default as actions } from "./actions";
export { default as epics } from "./epics";
export { reducer, initialState };

export * from "./typings";
export * as utils from "./utils";

export default reducer;
