import * as _ from "lodash";
import { Action as ReduxAction } from "redux";
import { AsyncStates, AsyncType, AsyncTypeStates, AsyncTypeString } from "./types";
import { Nullable } from "../typings";

/**
 * Parameters which may be used during checking the status of any async action.
 */
interface StatusCheckerParams<T extends boolean = false> {
  maybe?: boolean;
  primaryValue?: string | number | null;
  withPayload?: T;
}

type AsyncTypeCreator = (...args: unknown[]) => AsyncType;

/**
 * Action payload creator for converting the given arguments to a new format.
 * @see createAsyncActions
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PayloadCreator = (...args: any[]) => any;

/**
 * Redux action extended with an optional `payload` field.
 */
export type AbstractAction<P = void> = P extends void
  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ReduxAction & { payload?: any }
  : ReduxAction & { payload: P };

/**
 * Async action with "async-enabled" `type` field.
 */
export type AsyncAction<P = void> = P extends void
  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { type: AsyncTypeString; payload?: any }
  : { type: AsyncTypeString; payload: P };

/**
 * Replacement of Redux `Action` which supports an async `type` field.
 */
export type Action<P = void> = AbstractAction<P> | AsyncAction<P>;

/**
 * Action creator with ensured payload
 */
type ActionCreator<F extends PayloadCreator> = (...args: Parameters<F>) => Action<ReturnType<F>>;

/**
 * Async action creator with `start`, `fulfill` and `reject` API.
 * @typeParam S - action creator for `start`
 * @typeParam F - action creator for `fulfill`
 * @typeParam R - action creator for `reject`
 */
export interface AsyncActionCreator<
  A extends PayloadCreator,
  B extends PayloadCreator,
  C extends PayloadCreator
> {
  start: ActionCreator<A>;
  fulfill: ActionCreator<B>;
  reject: ActionCreator<C>;
}

/**
 * Make an async action creator from the given async action type.
 * @param {AsyncType | AsyncTypeCreator} typeObject
 * @param {PayloadCreator} createPendingPayload
 * @param {PayloadCreator} createFulfilledPayload
 * @param {PayloadCreator} createRejectedPayload
 * @example
 * ```typescript
 * import { createAsyncTypes } from "@ctra/utils";
 *
 * const type = createAsyncTypes("test", "TEST_ACTION");
 * const actions = createAsyncActions(
 *   type,
 *   (id, value1, value2) => ({ id, values: [value1, value2] })
 * );
 *
 * actions.start(1, 2, 3);
 * // { type: "TEST_ACTION.PENDING", payload: { id: 1, values: [2, 3] } }
 * ```
 */
export const createAsyncActions = <
  A extends PayloadCreator,
  B extends PayloadCreator,
  C extends PayloadCreator
>(
  typeObject: AsyncType | AsyncTypeCreator,
  createPendingPayload: A = _.stubObject as A,
  createFulfilledPayload: B = _.stubObject as B,
  createRejectedPayload: C = _.stubObject as C
): AsyncActionCreator<A, B, C> => {
  /**
   * Get the async action type by either returning the AsyncType object or passing the payload to
   * get one on the fly. This may be handy when you want to use the same action creator to yield different
   * actions based on the payload.
   * @param {*[]} args
   * @return {AsyncType}
   */
  const getType = (...args: unknown[]): AsyncType => {
    if (typeof typeObject === "function") {
      return typeObject(...args);
    } else {
      return typeObject;
    }
  };

  return {
    start: (...args) =>
      ({
        type: getType(...args).pending,
        payload: createPendingPayload(...args)
      } as AsyncAction<ReturnType<A>>),
    fulfill: (...args) =>
      ({
        type: getType(...args).fulfilled,
        payload: createFulfilledPayload(...args)
      } as AsyncAction<ReturnType<B>>),
    reject: (...args) =>
      ({
        type: getType(...args).rejected,
        payload: createRejectedPayload(...args)
      } as AsyncAction<ReturnType<C>>)
  };
};

type StatusWithPayload<P> = [boolean, P];
type StatusCheckValue<T, P> = T extends true ? StatusWithPayload<P> : boolean;

/**
 * Tell if the given async type has a certain status in the store.
 * Do not use it unless you need, there are better options below.
 * @param state
 * @param asyncType
 * @param status
 * @param params
 * @see isPending
 * @see isFulfilled
 * @see isRejected
 */
export function hasStatus<S extends Record<string, unknown>, T extends boolean = false, P = unknown>(
  state: S,
  asyncType: AsyncType,
  status: string,
  params: {
    maybe?: boolean;
    primaryValue?: string | number | null;
    withPayload?: T;
  } = {
    maybe: false,
    primaryValue: null,
    withPayload: false as unknown as T
  }
): StatusCheckValue<T, P> {
  const { primary, title } = asyncType.meta;
  const { maybe, primaryValue, withPayload } = params;

  if (primary && _.isUndefined(primaryValue)) {
    throw new Error(
      [
        `"${title}" has a primary key of "${primary}", but you forgot to pass its value to find in the state.`,
        `Example: hasStatus(state, myTypes.anAsyncType, "PENDING", { primaryValue: <primaryValue> })`
      ].join(" ")
    );
  }

  /**
   * Create a lookup path in the state to find the requested async value
   */
  const path = primaryValue ? ["async", title, primaryValue] : ["async", title];

  /**
   * Find the value in the store: pending, fulfilled, rejected
   */
  // @ts-ignore - "status" and "payload" will exist in the result
  const { status: value, payload }: { status: Nullable<keyof AsyncStates>; payload: Nullable<P> } = _.get<
    typeof state,
    string,
    { status: Nullable<keyof AsyncStates>; payload: Nullable<P> }
  >(state, path.join("."), {
    status: null,
    payload: null
  });

  const matches = value === _.lowerCase(status);
  const result = maybe ? !value || matches : matches;

  return (withPayload ? [result, payload] : result) as StatusCheckValue<T, P>;
}

/**
 * Tell whether the given action is pending.
 * @param state
 * @param asyncType
 * @param params
 * @return {boolean}
 * @example
 * ```typescript
 * import { useSelector } from "react-redux";
 * import { myAsyncType } from "./my-types";
 *
 * ...
 *
 * const isPending = useSelector(state => isPending(state, myAsyncType));
 * // true | false
 *
 * ```
 */
export const isPending = <S extends Record<string, unknown>, T extends boolean = false, P = unknown>(
  state: S,
  asyncType: AsyncType,
  params?: StatusCheckerParams<T>
): StatusCheckValue<T, P> => hasStatus<S, T, P>(state, asyncType, AsyncTypeStates.pending, params);

/**
 * Tell whether an action is fulfilled.
 * @param state
 * @param action
 * @param params
 * @return {boolean}
 * @example
 * ```typescript
 * import { useSelector } from "react-redux";
 * import { myAsyncType } from "./my-types";
 *
 * ...
 *
 * const isFulfilled = useSelector(state => isFulfilled(state, myAsyncType));
 * // true | false
 *
 * ```
 */
export const isFulfilled = <S extends Record<string, unknown>, T extends boolean = false, P = unknown>(
  state: S,
  action: AsyncType,
  params?: StatusCheckerParams<T>
): StatusCheckValue<T, P> => hasStatus(state, action, AsyncTypeStates.fulfilled, params);

/**
 * Tell whether an action is rejected.
 * @param state
 * @param action
 * @param params
 * @return {boolean}
 * @example
 * ```typescript
 * import { useSelector } from "react-redux";
 * import { myAsyncType } from "./my-types";
 *
 * ...
 *
 * const isRejected = useSelector(state => isRejected(state, myAsyncType));
 * // true | false
 *
 * ```
 */
export const isRejected = <S extends Record<string, unknown>, T extends boolean = false, P = unknown>(
  state: S,
  action: AsyncType,
  params?: StatusCheckerParams<T>
): StatusCheckValue<T, P> => hasStatus(state, action, AsyncTypeStates.rejected, params);

/**
 * Tell whether an action is settled.
 * @param state
 * @param action
 * @param params
 * @return {boolean}
 * @example
 * ```ts
 * import { useSelector } from "react-redux";
 * import { myAsyncType } from "./my-types";
 *
 * ...
 *
 * const isSettled = useSelector(state => isSettled(state, myAsyncType));
 * // true | false
 * ```
 */
export const isCompleted = <S extends Record<string, unknown>, T extends boolean = false, P = unknown>(
  state: S,
  action: AsyncType,
  params?: StatusCheckerParams<T>
): StatusCheckValue<T, P> => isFulfilled(state, action, params) || isRejected(state, action, params);

/**
 * Tell whether the give action hs already been dispatched
 * @param state
 * @param action
 * @param params
 * @return {boolean}
 * @example
 * ```ts
 * import { useSelector } from "react-redux";
 * import { myAsyncType } from "./my-types";
 *
 * ...
 *
 * const isSettled = isDispatched(state => isSettled(state, myAsyncType));
 * // true | false
 * ```
 */
export const isDispatched = <S extends Record<string, unknown>>(
  state: S,
  action: AsyncType,
  params?: StatusCheckerParams
): boolean => {
  const args: [S, AsyncType, StatusCheckerParams] = [
    state,
    action,
    { ...params, withPayload: false, maybe: false }
  ];

  return isPending(...args) || isFulfilled(...args) || isRejected(...args);
};
