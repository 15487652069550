import { default as reducer, initialState } from "./reducer";
import * as utils from "./utils";
import { Debug } from "@ctra/utils";

Debug.create("chartApi", "Chart API", { transports: true });

export { default as types } from "./types";
export { default as actions } from "./actions";
export { default as epics } from "./epics";
export { reducer, initialState };
export { utils };

export * from "./typings";
export * from "./series";
export * from "./normalizer";

export default reducer;
