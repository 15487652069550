import { memoize, Optional } from "@ctra/utils";

import { Base } from "./Base";

import { Enterprise } from "../../../enterprise";
import { FarmEntity } from "../../farms";

class FarmSeries extends Base {
  protected entity: Optional<FarmEntity>;

  constructor(id: number) {
    super(id);

    try {
      this.entity = Enterprise.entities.getFarm(Enterprise.store.getState(), { farmID: id });
    } catch (e) {}
  }

  @memoize
  getField(): Optional<string> {
    return this.entity?.name as string;
  }
}

export { FarmSeries };
