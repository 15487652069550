import { getCookie, setCookie, removeCookie } from "typescript-cookie";
import * as _ from "lodash";
import { isLocal, isProduction } from "../env";

/**
 * Domain for cookies
 * @type {string}
 */
const domain = isLocal() ? "localhost" : isProduction() ? "connecterra.ai" : "connecterra.ai";

/**
 * Namespace for cookies
 * @type {string}
 */
const ns = "ctra";

/**
 * Get cookie by key
 * @param {string} key
 * @return {string | undefined}
 */
const get = (key: string) => getCookie(_.startsWith(key, ns) ? key : `${ns}.${key}`);

/**
 * Set cookie by key
 * @param {string} key
 * @param {string} value
 * @return {string}
 */
const set = (key: string, value: string) =>
  setCookie(_.startsWith(key, ns) ? key : `${ns}.${key}`, value, {
    expires: 365,
    domain,
    path: "/"
  });

/**
 * Remove cookie by key
 * @param {string} key
 */
const remove = (key: string) =>
  removeCookie(_.startsWith(key, ns) ? key : `${ns}.${key}`, {
    domain,
    path: "/"
  });

/**
 * CTRA global cookie interface
 * @type {{set: (key: string, value: string) => string, get: (key: string) => string | undefined, remove: (key: string) => void}}
 */
export const Cookies = {
  get,
  set,
  remove
};

export enum CookieKeys {
  cookieConsent = "cookieConsent",
  invitationCode = "invitationCode",
  referrer = "referrer"
}
