import * as React from "react";
import MockDate from "mockdate";

type MockDateProps = {
  /**
   * Date string to set the current date to
   */
  date: string;
};

/**
 * Mock a date for the children component
 */
class MockDateComponent extends React.Component<MockDateProps> {
  constructor(props: MockDateProps) {
    MockDate.set(props.date);
    super(props);
  }

  componentWillUnmount(): void {
    MockDate.reset();
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}

export default MockDateComponent;
