import { NormalizedSchema, schema } from "normalizr";
import { AnnotationList, AnnotationResults, AnnotationResultsResponse } from "./typings";
import { convertKeysToLowerCase } from "@ctra/utils";

const annotationResult = new schema.Entity(
  "annotationResult",
  {},
  {
    idAttribute: ({ context: { originalAnnotationId } }) => originalAnnotationId,
    processStrategy: ({
      context: { originalAnnotationId },
      eventId,
      farmId,
      availableTimeSpanDays,
      data,
      state
    }: AnnotationResultsResponse): AnnotationResults => {
      return {
        id: originalAnnotationId,
        eventID: eventId,
        farmID: farmId,
        timeSpanDays: availableTimeSpanDays,
        state,
        results: convertKeysToLowerCase(data, ["SI", "Imperial"]) as AnnotationResults["results"]
      };
    }
  }
);

export type NormalizedAnnotationResults = NormalizedSchema<
  { annotationResult: AnnotationList },
  AnnotationList
>;

export default {
  annotationResult
};
