import { createStore, applyMiddleware, Action, Store, StoreEnhancer, Middleware } from "redux";
import * as _ from "lodash";
import { persistStore } from "redux-persist";
import { routerMiddleware } from "connected-react-router";
import { createEpicMiddleware, EpicMiddleware } from "redux-observable";
import { createBrowserHistory, History } from "history";
import { composeWithDevTools } from "redux-devtools-extension";
import { ajax } from "rxjs/ajax";
import * as Sentry from "@sentry/react";

import authMiddleware, { rehydrate } from "../modules/auth/middleware";
import { Request } from "../utils/ajax";
import { Auth, Session } from "../modules";
import asyncMiddleware from "../modules/async/middleware";
import createRootReducer from "./reducer";
import rootEpic from "./epic";

/**
 * Send actions to Sentry without auth info
 */
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer() {
    return null;
  }
});

const epicMiddleware: EpicMiddleware<Action> = createEpicMiddleware({
  dependencies: { ajax, Request, localStorage }
});

export const history: History = createBrowserHistory();

/**
 * Cache timeouts
 */
const timeouts: Record<string, number> = {};

/**
 * Store enhancers
 */
const enhancers: Array<StoreEnhancer> = [sentryReduxEnhancer];

/**
 * Store middleware
 */
const middleware: Array<Middleware> = [
  epicMiddleware,
  routerMiddleware(history),
  asyncMiddleware.cacheMiddleware(timeouts),
  authMiddleware.sessionCookiesMiddleware(["auth"])
];

const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware), ...enhancers);

/**
 * Rehydrate auth state from cookie
 * @type {AuthState|null}
 */
const authFromCookie = rehydrate();

/**
 * Redux store
 */
const store: Store & { reset: () => void } = createStore<
  Record<string, unknown>,
  Action,
  { reset: () => void },
  Record<string, unknown>
>(
  createRootReducer(history),
  { auth: _.defaultTo(authFromCookie, Auth.initialState) },
  composedEnhancers as StoreEnhancer<{ reset: () => void }, Record<string, unknown>>
);

/**
 * Implement reset store for unit tests.
 */
store.reset = () => {
  store.dispatch({ type: Session.types.RESET });
  _.map(_.values(timeouts), _.unary(clearTimeout));
};

/**
 * Store persistor
 */
export const persistor = persistStore(store);

epicMiddleware.run(rootEpic);

export default store;
