import * as _ from "lodash";
import { ReactNode, ComponentProps, PropsWithChildren, ReactElement } from "react";
import { Dropdown, Menu } from "antd";
import { MenuProps } from "antd/es/menu";

import { DefaultTFuncReturn } from "@ctra/i18n";
import { Nullable } from "@ctra/utils";

import { Button } from "../../../elements";

export type SimpleDropdownProps<T = string | number> = ComponentProps<typeof Dropdown> & {
  /**
   * All the possible values the dropdown needs to display
   */
  values: Array<T>;
  /**
   * The currently active value
   */
  currentValue: Nullable<T>;
  /**
   * Whether or not to show the label
   */
  showLabel?: boolean;
  /**
   * Handler to call when the user clicks on the list items
   */
  onChange: MenuProps["onClick"];
  /**
   * Get a human readable label from the value
   * @param {Nullable<T>} key
   * @param {{isToggle: boolean}} options
   * @return {DefaultTFuncReturn | React.ReactNode | T}
   */
  getLabel?: (key: Nullable<T>, options: { isToggle: boolean }) => T | DefaultTFuncReturn | ReactNode;
  /**
   * Render an icon for the list item
   * @param {string} key
   */
  renderIcon?: (key: Nullable<T>) => ReactNode;
  /**
   * Render an extra MenuItem for the list
   */
  renderExtra?: ReactNode;
};

/**
 * Wrapper around the dropdown to make the API a bit simpler
 * @param {Array<V>} values
 * @param {<V>} currentValue
 * @param {boolean | undefined} showLabel
 * @param {((info: MenuInfo) => void) | undefined} onChange
 * @param {(<V>(key: <V>) => (DefaultTFuncReturn | V)) | undefined} getLabel
 * @param {(<V>(key: <V>) => React.ReactNode) | undefined} renderIcon
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} renderExtra
 * @param {boolean | undefined} disabled
 * @param {Pick<DropdownProps & {children?: React.ReactNode | undefined} & {values: Array<V>, currentValue: <V>, showLabel?: boolean, onChange: MenuProps["onClick"], getLabel?: <V>(key: <V>) => (DefaultTFuncReturn | V), renderIcon?: <V>(key: <V>) => React.ReactNode, renderExtra?: React.ReactNode}, keyof React.PropsWithChildren<SimpleDropdownProps<V>> extends ("getLabel" | "onChange" | "values" | "renderIcon" | "renderExtra" | "disabled" | "currentValue" | "showLabel") ? never : keyof React.PropsWithChildren<SimpleDropdownProps<V>>>} rest
 * @return {React.ReactElement}
 * @constructor
 */
function SimpleDropdown<V extends string | number>({
  values,
  currentValue,
  showLabel = true,
  onChange,
  getLabel = (key) => key,
  renderIcon = () => null,
  renderExtra,
  disabled,
  ...rest
}: PropsWithChildren<SimpleDropdownProps<V>>): ReactElement {
  const dropdownToggle = (
    <Button disabled={disabled || values.length <= 1} icon={renderIcon(currentValue)}>
      {showLabel && getLabel(currentValue, { isToggle: true })}
    </Button>
  );

  return values.length > 1 ? (
    <Dropdown
      trigger={["click"]}
      overlay={() => (
        <Menu onClick={onChange}>
          {_.without(values, currentValue).map((value) => (
            <Menu.Item key={value} icon={renderIcon(value)}>
              {getLabel(value, { isToggle: false })}
            </Menu.Item>
          ))}
          {renderExtra}
        </Menu>
      )}
      placement="bottomRight"
      disabled={disabled}
      {...rest}
    >
      {dropdownToggle}
    </Dropdown>
  ) : (
    dropdownToggle
  );
}

export default SimpleDropdown;
