const ns = "app/async";

/**
 * Clean up the expired entry. This is used only in the cacheMiddleware,
 * so nothing you may need to manually play around with.
 */
export const CLEANUP = `${ns}/__CLEANUP__`;

/**
 * Replay an action. Based on some parameters, expired actions may
 * be re-dispatched. Eg. refresh a chart's data every N minutes.
 * The cacheMiddleware needs this, not you. :)
 */
export const REPLAY = `${ns}/__REPLAY__`;

export default {
  CLEANUP,
  REPLAY
};
