import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import { AnnotationState, ImpactTrackingState } from "./typings";
import { NormalizedAnnotationResults } from "./schemas";

export const initialState: ImpactTrackingState = {
  annotations: {}
};

/**
 * Reducer for impact tracking
 * @param state
 * @param action
 * @category Reducer
 */
export const reducer: Reducer<ImpactTrackingState> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_ANNOTATION_RESULTS.fulfilled: {
      const {
        entities: { annotationResult }
      } = payload as NormalizedAnnotationResults;

      return {
        annotations: {
          ...state.annotations,
          ...annotationResult
        }
      };
    }

    case types.ADD_METRIC.fulfilled: {
      const { annotationID } = payload;

      return {
        annotations: {
          [annotationID]: {
            ...state.annotations[annotationID],
            state: AnnotationState.processing
          }
        }
      };
    }
  }

  return state;
};

export default cleanup<ImpactTrackingState>(initialState)(reducer);
