import { NormalizedSchema, schema } from "normalizr";
import * as _ from "lodash";

import { DairyCowEntity, schemas as cowSchemas } from "../cows";

import { TS } from "@ctra/utils";

import {
  GenericInsightSource,
  KPIInsightSettingsState,
  InsightTypeSource,
  InsightTypeEntity,
  GenericInsightEntity,
  InsightValidationEntity,
  InsightValidationSource,
  NotificationMappingEntity,
  NotificationMappingSource,
  InsightResolutionEntity,
  NotificationMappingsState,
  MappingGroups,
  InsightWorkflowState
} from "./typings";

const insightType = new schema.Entity(
  "insightType",
  {},
  {
    idAttribute: "typeName",
    processStrategy: ({ InsightTypeID, ...rest }: InsightTypeSource): InsightTypeEntity => ({
      id: InsightTypeID,
      ...rest
    })
  }
);

const insightTypeList = new schema.Array(insightType);

export type NormalizedInsightTypeList = NormalizedSchema<
  { insightType: Record<InsightTypeEntity["id"], InsightTypeEntity> },
  Array<InsightTypeEntity["typeName"]>
>;

const insightValidation = new schema.Entity(
  "insightValidation",
  {},
  {
    idAttribute: "InsightValidationID",
    processStrategy: ({
      InsightValidationID,
      genericInsightID,
      validation,
      validationByID
    }: InsightValidationSource): InsightValidationEntity => ({
      genericInsightID,
      validation,
      id: InsightValidationID,
      validatedBy: validationByID
    })
  }
);

const insightResolution = new schema.Entity<InsightResolutionEntity>("insightResolution", {});

const insightMapping = new schema.Entity(
  "insightMapping",
  {},
  {
    idAttribute: ({ _id }: NotificationMappingSource) => _id,
    processStrategy: ({ _id, ...rest }: NotificationMappingSource): NotificationMappingEntity => ({
      id: _id,
      ...rest
    })
  }
);

const mappingGroup = new schema.Entity(
  "mappingGroup",
  {
    mappings: new schema.Array(insightMapping)
  },
  {
    idAttribute: ({ groupName }: { groupName: string }) => groupName
  }
);

/**
 * Mapping schema
 * seperate groups and mappings
 */
const mappingSchema = new schema.Array(
  {
    insightMapping,
    mappingGroup
  },
  ({ groupName }) => (groupName ? "mappingGroup" : "insightMapping")
);

const insight = new schema.Entity(
  "insight",
  {
    validations: new schema.Array(insightValidation),
    resolution: new schema.Array(insightResolution),
    dairyCow: cowSchemas.dairyCow,
    insightType
  },
  {
    idAttribute: "GenericInsightID",
    processStrategy: ({
      GenericInsightID,
      parentGenericInsightID,
      farmID,
      herdGroupID,
      insightType,
      nodeID,
      insightValidations,
      insightResolution,
      insightMetadata,
      insightContext,
      createdEpoch,
      startEpoch,
      endEpoch,
      seenByUserIds,
      dairyCow,
      workflowState,
      workflowStateLastModifiedDate,
      insightTitle,
      insightDescription
    }: GenericInsightSource & {
      insightResolution: Array<InsightResolutionEntity["id"]>;
      insightValidations: Array<InsightValidationEntity["id"]>;
      insightType: InsightTypeEntity["typeName"];
      dairyCow: DairyCowEntity["id"];
    }): GenericInsightEntity => {
      /**
       * Automatically assign states based on validations and resolution
       */
      const conditionalInsightState =
        insightValidations.length && !insightResolution?.length
          ? InsightWorkflowState.toFollowUp
          : insightValidations.length && insightResolution?.length
          ? InsightWorkflowState.done
          : InsightWorkflowState.toCheck;

      /**
       * If no workflow state present, find state based on the conditions */
      /* istanbul ignore next */
      const insightWorkflowState = workflowState || conditionalInsightState;

      /**
       * if no workflow modified date present, use the created epoch
       */
      /* istanbul ignore next */
      const insightWorkflowStateLastModifiedDate =
        workflowStateLastModifiedDate || (TS.serialize(createdEpoch) as string);

      return {
        id: GenericInsightID,
        parent: parentGenericInsightID,
        resolution: insightResolution || [],
        context: insightContext ? JSON.parse(insightContext) : {},
        metadata: {
          ...(insightMetadata ? JSON.parse(insightMetadata) : {}),
          developerTitle: insightTitle,
          developerDescription: insightDescription
        },
        validations: insightValidations,
        farm: farmID,
        herdGroup: herdGroupID,
        node: nodeID,
        seenBy: seenByUserIds ? _.map(_.split(seenByUserIds, ","), _.trim) : [],
        workflowState: insightWorkflowState,
        workflowStateLastModifiedDate: insightWorkflowStateLastModifiedDate,
        dairyCow,
        createdEpoch,
        startEpoch,
        endEpoch,
        insightType
      };
    }
  }
);

const insightList = new schema.Array(insight);
const kpiInsightSettings = new schema.Object({});
const insightResolutionList = new schema.Array(insightResolution);

export type NormalizedKPIInsightSettings = NormalizedSchema<Record<string, unknown>, KPIInsightSettingsState>;

export type NormalizedInsightMappingList = NormalizedSchema<
  {
    insightMapping: NotificationMappingsState;
    mappingGroup: MappingGroups;
  },
  Array<NotificationMappingEntity["id"]>
>;

export type NormalizedInsightList = NormalizedSchema<
  {
    insight: Record<GenericInsightEntity["id"], GenericInsightEntity>;
    insightType: Record<InsightTypeEntity["typeName"], InsightTypeEntity>;
    dairyCow: Record<DairyCowEntity["id"], DairyCowEntity>;
    insightValidation: Record<NonNullable<InsightValidationEntity["id"]>, InsightValidationEntity>;
    insightResolution: Record<NonNullable<InsightResolutionEntity["id"]>, InsightResolutionEntity>;
  },
  Array<GenericInsightEntity["id"]>
>;

export default {
  mappingSchema,
  insight,
  insightList,
  kpiInsightSettings,
  insightResolutionList,
  insightValidation,
  insightTypeList
};
