import { Epic, ofType } from "redux-observable";
import { mergeMap, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import * as _ from "lodash";

import { Action, Debug } from "@ctra/utils";

import { makeApiURL } from "../../utils/ajax";
import { GenericLabelSource } from "./typings";
import types from "./types";
import actions from "./actions";

/**
 * Create a GenericLabel object
 * @param action$
 * @param state$
 * @param Request
 */
const createGenericLabel: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.CREATE_GENERIC_LABEL.pending),
    mergeMap<ReturnType<typeof actions.createGenericLabel.start>, Observable<Promise<unknown>>>(
      ({ payload }) => {
        const url = makeApiURL("https://api<%= env %>.connecterra.ai/odata/GenericLabels")({});

        return Request.POST(url, {
          body: payload
        }).pipe(
          map<{ response: GenericLabelSource }, Action>(({ response }) => {
            Debug.genericLabelApi.info("GenericLabel object is successfully created", response);
            return actions.createGenericLabel.fulfill(response);
          }),
          catchError<unknown, Observable<Action>>((error) => {
            Debug.genericLabelApi.error("An error occurred during creating the GenericLabel object", {
              error
            });

            return of(actions.createGenericLabel.reject(error));
          })
        );
      }
    )
  );

/**
 * Delete a GenericLabel object via odata
 * @param action$
 * @param state$
 * @param Request
 */
const deleteGenericLabel: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.DELETE_GENERIC_LABEL.pending),
    mergeMap<ReturnType<typeof actions.deleteGenericLabel.start>, Observable<Promise<unknown>>>(
      ({ payload: { id } }) => {
        const url = makeApiURL("https://api<%= env %>.connecterra.ai/odata/GenericLabels(<%= id %>)")({ id });

        return Request.DELETE(url).pipe(
          map<unknown, Action>(() => actions.deleteGenericLabel.fulfill(id)),
          catchError<unknown, Observable<Action>>((error) => {
            if (_.get(error, "status")) {
              const { status } = error;

              // istanbul ignore next - we don't cover for non-404 statuses as it is done by the error handler
              if (status === 404) {
                Debug.genericLabelApi.info(
                  `The GenericLabel object ${id} does not exist, probably removed earlier`
                );

                return of(actions.deleteGenericLabel.fulfill(id));
              }
            }

            Debug.genericLabelApi.error("An error occurred during deleting the GenericLabel object", {
              error
            });

            return of(actions.deleteGenericLabel.reject(id, error));
          })
        );
      }
    )
  );

export default {
  createGenericLabel,
  deleteGenericLabel
};
