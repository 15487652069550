import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";
import { Observable, of, forkJoin, combineLatest } from "rxjs";

import { Action, Debug } from "@ctra/utils";

import { makeAzureApiURL } from "../../utils/ajax";
import { SandboxInfo, UserPreferencesSource } from "./typings";
import types from "./types";
import actions from "./actions";
import * as _ from "lodash";

const url = makeAzureApiURL("accounts", "/self/preferences/<%= service %>");

/**
 * Request the user preferences for the logged in user
 * @param action$
 * @param state$
 * @param Request
 */
const fetchUserPreferences: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_USER_PREFERENCES.pending),
    mergeMap<ReturnType<typeof actions.fetchUserPreferences.start>, Observable<Action>>(() =>
      forkJoin([
        Request.GET(
          url({
            service: "localization"
          })
        ),
        Request.GET(
          url({
            service: "sandbox"
          })
        )
      ]).pipe(
        map<Array<{ response: unknown }>, Action>(
          ([{ response: localizationResponse }, { response: sandboxResponse }]) =>
            actions.fetchUserPreferences.fulfill(
              localizationResponse as UserPreferencesSource,
              sandboxResponse as SandboxInfo
            )
        ),
        catchError<Action, Observable<Action>>(({ response }) => {
          const error = _.get(response, ["error"]);
          const statusCode = _.get(response, ["statusCode"]);

          if (error) {
            Debug.preferencesApi.error(error);
          }

          return of(actions.fetchUserPreferences.reject(error, statusCode));
        }),
        takeUntil(action$.pipe(ofType(types.FETCH_USER_PREFERENCES.pending)))
      )
    )
  );

/**
 * Update/set the user preferences for the logged in user
 * @param action$
 * @param state$
 * @param Request
 */
const setUserPreferences: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.SET_USER_PREFERENCES.pending),
    mergeMap<ReturnType<typeof actions.setUserPreferences.start>, Observable<Action>>(
      ({ payload: { sandbox, ...preferences } }) =>
        combineLatest(
          /**
           * Do not make an extra request if any of the request payloads is empty.
           */
          [
            _.isEmpty(preferences)
              ? of({ response: null })
              : Request.PUT(
                  url({
                    service: "localization"
                  }),
                  {
                    body: preferences
                  }
                ),
            _.isEmpty(sandbox)
              ? of({ response: null })
              : Request.PUT(
                  url({
                    service: "sandbox"
                  }),
                  {
                    body: sandbox
                  }
                )
          ]
        ).pipe(
          map<Array<{ response: unknown }>, Action>(
            ([{ response: localizationResponse }, { response: sandboxResponse }]) =>
              actions.setUserPreferences.fulfill(
                _.defaultTo(localizationResponse, preferences) as UserPreferencesSource,
                _.defaultTo(sandboxResponse, {}) as SandboxInfo
              )
          ),
          catchError<Action, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);

            if (error) {
              Debug.preferencesApi.error(error);
            }

            return of(actions.setUserPreferences.reject(error, statusCode));
          })
        )
    )
  );

export default {
  fetchUserPreferences,
  setUserPreferences
};
