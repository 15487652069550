import * as _ from "lodash";

import { readableHash } from "@ctra/utils";

import { EventListQueryParams } from "./typings";
import { FarmEntity } from "../farms";

/**
 * Make an event hash from the given farm id and optional params
 * @param farmID
 * @param params
 * @example
 * ```ts
 * makeEventHash(
 *   123,
 *   { source: "GenericInsights", startDate: 1234567890, endDate: 2345678901 }
 * );
 * // "836a4b19e5901e519a5713e812929e3ced6ef246"
 * ```
 */
export const makeEventHash = (farmID?: FarmEntity["id"], params?: Partial<EventListQueryParams>): string =>
  readableHash({
    farmID,
    ..._.pick(params, "categories", "source", "startDate", "endDate")
  });
