import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import { ScorecardValueList } from "./typings";
import { NormalizedScorecardValue } from "./schemas";

export const initialState: ScorecardValueList = {};

/**
 * Scorecard value reducer to store the current value of KPIs
 * @param state
 * @param action
 * @category Reducer
 */
export const reducer: Reducer<ScorecardValueList> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_SCORECARD_VALUE.fulfilled: {
      const {
        entities: { scorecardValue },
        hash,
        result
      } = payload as NormalizedScorecardValue & { hash: string };

      return {
        ...state,
        [hash]: scorecardValue[result as number]
      } as ScorecardValueList;
    }
  }

  return state;
};

export default cleanup<ScorecardValueList>(initialState)(reducer);
