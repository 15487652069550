/* eslint-disable @typescript-eslint/no-use-before-define */

import * as _ from "lodash";
import { compile, PathFunction } from "path-to-regexp";

interface NestedRouteDefinition {
  [route: string]: string | RouteDefinition;
}

type RouteDefinition = NestedRouteDefinition & {
  __root?: string;
  _root?: string;
};

/**
 * Make a public route by removing the `_root` and the `__root`
 * keys from the given route definition.
 */
type Public<R> = Omit<R, "_root" | "__root">;

/**
 * Route object made of the given route definition.
 * @typeParam R - route definition type to convert
 * @example
 * ```typescript
 * type Definition = {
 *   _root: string,
 *   page: string,
 *   blog: {
 *     _root: string,
 *     value: string
 *   }
 * }
 *
 * type AppRoutes = Route<Definition>;
 *
 * const routes: AppRoutes = {
 *   index: "/app",
 *   page: "/app/page",
 *   blog: {
 *     index: "/app/blog,
 *     value: "/app/blog/value"
 *   }
 * }
 * ```
 */
export type Route<R> = {
  index: string;
} & {
  [K in keyof Public<R>]: R[K] extends string ? R[K] : Route<R[K]>;
};

/**
 * Build routes from the given route definitions.
 * @param routes
 * @example
 * ```typescript
 * buildRoutes({ _root: "/uri", namedRoute: "/route" });
 * // { index: "/uri", namedRoute: "/uri/route" }
 * ```
 */
export const buildRoutes = <R extends RouteDefinition = RouteDefinition>(routes: R): Route<R> => {
  const { __root = "", _root = "", ...children } = routes;
  const rootURI = `${__root}${_root}`;

  // I decided to ts-ignore it because
  // this error is just pure BULLSHIT.
  // My linter just fails on this randomly: once it is good, once it is not.
  // Fuck you!
  // @ts-ignore
  const result: Route<R> = {
    index: rootURI
  };

  for (const [name, path] of Object.entries(children)) {
    if (_.isString(path)) {
      _.set(result, name, `${rootURI}${path}`);
    }

    if (_.isPlainObject(path)) {
      _.set(result, name, buildRoutes({ __root: rootURI, ...path }));
    }
  }

  return result;
};

/**
 * Compiled route type
 */
type Compiled<R> = {
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  [K in keyof R]: R[K] extends string ? PathFunction : Compiled<R[K]>;
};

/**
 * Compile routes to callable functions
 * @param routes
 * @example
 * ```ts
 * const makeURL = compileRoutes({ index: "/uri", namedRoute: "/uri/:id" });
 * makeURL.namedRoute({ id: 1 });
 * // "/uri/1"
 * ```
 */
export const compileRoutes = <R>(routes: R): Compiled<R> => {
  // Again, this work perfectly, but TS cries.
  // I suspect mapValues to be not covering all the cases.
  // @ts-ignore
  return _.mapValues(routes, (value) => {
    if (_.isPlainObject(value)) {
      return compileRoutes(value);
    }

    return compile(value);
  });
};
