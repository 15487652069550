import { AsyncType, createAsyncTypes, createCRUDAsyncTypes, SECOND } from "@ctra/utils";

const ns = "app/invitation";

/**
 * CRUD for single invitation
 */
const [CREATE_INVITATION, FETCH_INVITATION, UPDATE_INVITATION, DELETE_INVITATION]: Array<AsyncType> =
  createCRUDAsyncTypes(ns, "INVITATION", {
    expires: 10 * SECOND,
    primary: "invitationId",
    preservesPayload: ["error", "statusCode"]
  });

/**
 * Fetch an invitation by code
 * @category Type
 */
const FETCH_INVITATION_BY_CODE: AsyncType = createAsyncTypes(ns, "FETCH_INVITATION_BY_CODE", {
  primary: "invitationCode",
  preservesPayload: true
});

/**
 * Fetch a list of invitations
 * @category Type
 */
const FETCH_INVITATION_LIST: AsyncType = createAsyncTypes(ns, "FETCH_INVITATION_LIST", {
  preservesPayload: ["error", "statusCode"]
});

/**
 * Fetch a list of invitations
 * @category Type
 */
const ACCEPT_INVITATION: AsyncType = createAsyncTypes(ns, "ACCEPT_INVITATION");

export default {
  FETCH_INVITATION_BY_CODE,
  CREATE_INVITATION,
  FETCH_INVITATION,
  UPDATE_INVITATION,
  DELETE_INVITATION,
  FETCH_INVITATION_LIST,
  ACCEPT_INVITATION
};
