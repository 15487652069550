import * as _ from "lodash";

type TypeOptions = {
  primary?: string | null;
  async?: boolean;
  replays?: boolean;
  expires?: number;
  preservesPayload?: boolean | string | string[];
};

type AsyncTypeMetadata = {
  title: string;
  primary: string | null;
};

/**
 * Async-enabled action type using the String constructor.
 * It adds some extra fields which our utils can use.
 */
export interface AsyncTypeString extends String {
  primary?: string | null;
  async?: boolean;
  replays?: boolean;
  expires?: number;
  preservesPayload?: boolean | string | string[];
}

type AsyncTypeValues<A> = Record<keyof A, AsyncTypeString>;

/**
 * All the possible async states.
 */
export type AsyncStates = {
  pending: "PENDING";
  fulfilled: "FULFILLED";
  rejected: "REJECTED";
};

/**
 * Async action type with metadata field.
 */
export interface AsyncType extends AsyncTypeValues<AsyncStates> {
  meta: AsyncTypeMetadata;
}

/**
 * Create a special Redux type which has additional metadata appended
 * @param {string} from
 * @param {TypeOptions} options
 */
const createType = (from: string, options: TypeOptions): AsyncTypeString => {
  // eslint-disable-next-line no-new-wrappers
  const type = new String(from);

  /**
   * Default options
   */
  const defaults: TypeOptions = {
    primary: null,
    async: true,
    replays: false,
    expires: 0,
    preservesPayload: false
  };

  const { primary, async, expires, replays, preservesPayload } = { ...defaults, ...options };

  Object.defineProperties(type, {
    primary: {
      value: primary,
      writable: false
    },
    async: {
      value: async,
      writable: false
    },
    expires: {
      value: expires,
      writable: false
    },
    replays: {
      value: replays,
      writable: false
    },
    preservesPayload: {
      value: preservesPayload,
      writable: false
    }
  });

  return type;
};

/**
 * Delimiters used in async action types.
 * @todo switch to enum
 * @example
 * ```typescript
 * "app/namespace/FETCH_SOME_DATA.PENDING"
 * ```
 */
export const Delimiters = {
  action: "/",
  status: "."
};

/**
 * Possible async type statuses.
 * @note This is a constant. If you are looking for the type...
 * @see AsyncStates
 */
export const AsyncTypeStates: AsyncStates = {
  pending: "PENDING",
  fulfilled: "FULFILLED",
  rejected: "REJECTED"
};

/**
 * Create async types from the given namespace and title.
 * @param {string} ns
 * @param {string} title
 * @param {TypeOptions} options
 * @example
 * ```typescript
 * const asyncType = createAsyncTypes("app", "TEST_ACTION", {
 *   primary: "id", // expects action payload to have an `id` field
 *   async: true, // default true for async actions
 *   replays: true, // replays after the timeout specified in `expires`
 *   expires: 60 * 1000 // timeout before the action automatically gets dispatched again
 * });
 * ```
 */
export const createAsyncTypes = (ns: string, title: string, options: TypeOptions = {}): AsyncType => {
  if (!title) {
    throw new Error("All async action types need a title.");
  }

  /**
   * Default options
   */
  const defaults: TypeOptions = {
    primary: null,
    async: true,
    replays: false,
    expires: 0,
    preservesPayload: false
  };

  /**
   * Metadata which stores the status-free title of the
   */
  const meta: AsyncTypeMetadata = {
    title: `${ns}/${title}`,
    primary: _.get(options, "primary", null)
  };

  /**
   * PENDING, FULFILLED and REJECTED statuses
   */
  const values: AsyncTypeValues<AsyncStates> = _.mapValues<AsyncStates, AsyncTypeString>(
    AsyncTypeStates,
    (value) => {
      const typeString = `${ns}${Delimiters.action}${title}${Delimiters.status}${value}`;

      return createType(typeString, { ...defaults, ...options });
    }
  );

  return {
    meta,
    ...values
  };
};

/**
 * Create CRUD async action types with one command.
 * @param {string} ns
 * @param {string} title
 * @param {TypeOptions} options
 * @see createAsyncTypes
 * @example
 * ```typescript
 * const types = createCRUDAsyncTypes("test", "USER");
 *
 * const [CREATE_USER, FETCH_USER, UPDATE_USER, DELETE_USER] = types;
 * ```
 */
export const createCRUDAsyncTypes = (
  ns: string,
  title: string,
  options: TypeOptions & {
    forcePrimary?: boolean;
  } = {}
): Array<AsyncType> => {
  const RUD = ["FETCH", "UPDATE", "DELETE"];

  return [
    /**
     * Create does may need a primary (self-explanatory)
     */
    createAsyncTypes(
      ns,
      `CREATE_${title}`,
      _.omit(options, _.compact(["forcePrimary", options.forcePrimary ? null : "primary"]))
    ),
    ..._.map(RUD, (action) => createAsyncTypes(ns, `${action}_${title}`, _.omit(options, "forcePrimary")))
  ];
};
