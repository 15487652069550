import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/data-dictionary";

/**
 * Fetch metrics
 * @category Type
 */
const FETCH_METRICS: AsyncType = createAsyncTypes(ns, "FETCH_METRICS");

const FETCH_ENUMS: AsyncType = createAsyncTypes(ns, "FETCH_ENUMS");

export default {
  FETCH_METRICS,
  FETCH_ENUMS
};
