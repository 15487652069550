import { createAsyncActions } from "@ctra/utils";

import types from "./types";
import { AddCardPayload, Group, SavedCard, SavedCardsResponse } from "./typings";

/**
 * Fetch saved chart cards
 */
const fetchSavedCharts = createAsyncActions(
  types.FETCH_SAVED_CHARTS,
  () => ({}),
  (response: SavedCardsResponse) => {
    return response.pages;
  },
  (error: string) => ({ error })
);

/**
 * Add new chart card
 */
const addCard = createAsyncActions(
  types.ADD_CARD,
  (pageKey, groupID, groupName, cardPayload: AddCardPayload) => ({
    pageKey,
    groupID,
    groupName,
    cardPayload
  }),
  (pageKey, groupID, newCard: SavedCard) => ({ pageKey, groupID, newCard }),
  (error: string) => ({ error })
);

/**
 * Remove, add or reorder a tab/group
 */
const updateTabs = createAsyncActions(
  types.UPDATE_TABS,
  (pageKey, savedCharts) => ({ pageKey, savedCharts }),
  (pageKey, savedCharts) => ({ pageKey, savedCharts }),
  (error: string) => ({ error })
);

/**
 * Add a tab
 */
const addTab = createAsyncActions(
  types.ADD_TAB,
  (pageKey, groupName) => ({ pageKey, groupName }),
  (pageKey, response: Group) => ({ pageKey, response }),
  (error: string) => ({ error })
);

/**
 * Save a new chart to a dashboard
 */
const updateCharts = createAsyncActions(
  types.UPDATE_CHARTS,
  (pageKey, savedCharts) => ({ pageKey, savedCharts }),
  (pageKey, savedCharts) => ({ pageKey, savedCharts }),
  (error: string) => ({ error })
);

export default {
  updateCharts,
  updateTabs,
  addTab,
  fetchSavedCharts,
  addCard
};
