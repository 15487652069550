import { Middleware } from "redux";
import * as _ from "lodash";
import { Action, AsyncTypeStates, Delimiters, Debug } from "@ctra/utils";

import { types as sessionTypes } from "../session";
import types from "./types";

/**
 * Create a debug instance for the cache middleware
 */
Debug.create("reduxCaching", "Cache Strategy", { prefixColor: "#3d3d3d" });

/**
 * Clear all timeouts in the given list
 * @param {Record<string, number | Record<string, number>>} timeouts
 */
const clearTimeouts = (timeouts: Record<string, number | Record<string, number>>) => {
  _.forEach(timeouts, (timeoutId) => {
    if (_.isNumber(timeoutId)) {
      clearTimeout(timeoutId);
    } else if (_.isPlainObject(timeoutId)) {
      clearTimeouts(timeoutId);
    }
  });
};

/**
 * Manage application cache by replaying/cleaning up certain async actions periodically
 * @param timeouts
 */
const cacheMiddleware =
  (timeouts: Record<string, number>): Middleware =>
  (store) =>
  (next) =>
  (action: Action) => {
    const { type, payload } = action;

    if (type === sessionTypes.LOGOUT) {
      Debug.reduxCaching.info("Clearing all timeouts", { timeouts });

      clearTimeouts(timeouts);
    } else {
      const { async, expires, replays, primary } = type;

      /**
       * Pick the primary value from the payload
       */
      const id = primary ? payload[primary] : null;

      if (async) {
        const [name, variant] = type.split(Delimiters.status);

        if (variant === AsyncTypeStates.pending && expires) {
          const path = id ? [name, id] : [name];
          const timeoutId = _.get(timeouts, path);

          if (timeoutId) {
            Debug.reduxCaching.info("Clearing timeout", { name, id });
            clearTimeout(timeoutId);
          }

          Debug.reduxCaching.info("Setting timeout", {
            name,
            id,
            action: { ...action, type: action.type.toString() }
          });

          _.set(
            timeouts,
            path,
            setTimeout(() => {
              if (replays) {
                store.dispatch({
                  type: types.REPLAY,
                  payload: action
                });

                store.dispatch(action);
              } else {
                store.dispatch({
                  type: types.CLEANUP,
                  payload: {
                    name,
                    id
                  }
                });
              }
            }, expires * 1000)
          );
        }
      }
    }

    next(action);
  };

export default {
  cacheMiddleware
};
