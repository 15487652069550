import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/generic";

/**
 * Send user feedback
 * @category Type
 */
const SEND_FEEDBACK: AsyncType = createAsyncTypes(ns, "SEND_FEEDBACK");

export default {
  SEND_FEEDBACK
};
