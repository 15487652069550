// istanbul ignore file - dev tool

import logdown, { Logger, LogdownOptions, TransportFunction, LoggerState } from "logdown";
import * as Sentry from "@sentry/react";
import * as _ from "lodash";
import { HOUR } from "../date";

const systemKey = "system";

type AdvancedLogger = Logger & {
  state: LoggerState & {
    transports?: boolean;
    tags?: Record<string, unknown>;
  };
};

/**
 * Record a message to Sentry
 * @param {string} message
 * @param {Record<string, unknown>} extra
 * @param {Record<string, string>} tags
 */
const sentryMessage = (message: string, extra?: Record<string, unknown>, tags?: Record<string, string>) => {
  Sentry.withScope((scope) => {
    if (extra) {
      for (const key in extra) {
        if (!_.startsWith("_", key)) {
          scope.setExtra(key, extra[key]);
        }
      }
    }

    scope.setLevel("info");
    Sentry.captureMessage(message, {
      tags: _.merge({ diagnostics: "debugger" }, _.defaultTo(tags, {}))
    });
  });
};

/**
 * Record an exception to Sentry
 * @param {Error} err
 * @param {Record<string, unknown>} extra
 * @param {Record<string, string>} tags
 */
const sentryException = (err: Error, extra?: Record<string, unknown>, tags?: Record<string, string>) => {
  Sentry.withScope((scope) => {
    if (extra) {
      for (const key in extra) {
        if (!_.startsWith("_", key)) {
          scope.setExtra(key, extra[key]);
        }
      }
    }

    scope.setLevel("error");
    Sentry.captureException(err, {
      tags: _.merge({ diagnostics: "debugger" }, _.defaultTo(tags, {}))
    });
  });
};

/**
 * Cache for the transport function (keep messages up to an hour)
 * @type {Map<string, number>}
 */
const transportCache = new Map<string, number>();

/**
 * Sentry transport function
 * @param {string} msg
 * @param {string} level
 * @param {any[]} args
 * @param {logdown.LoggerState} state
 */
const sentryTransport: TransportFunction = ({ msg, level, args, state }) => {
  const cleanMessage = msg.replace("[[", "[").replace("]]", "]");
  const now = +new Date();
  const encoded = btoa(cleanMessage);

  if (_.get(state, ["transports"], false)) {
    const last = transportCache.get(encoded);

    if (!last || now - last >= HOUR * 1000) {
      transportCache.set(encoded, now);

      if (level === "error") {
        sentryException(
          new Error(cleanMessage),
          {
            args
          },
          _.get(state, ["tags"])
        );
      } else if (level === "info") {
        sentryMessage(
          cleanMessage,
          {
            args
          },
          _.get(state, ["tags"])
        );
      }
    }
  }
};

logdown.transports = [sentryTransport];

/**
 * Make a system logger and make it default enabled
 * @type {logdown.Logger}
 */
const _systemLogger = logdown("[system]", { prefixColor: "#468699" });
_systemLogger.state.isEnabled = true;

/**
 * Local state of active loggers
 * @type {{system: {ns: string, logger: logdown.Logger}}}
 */
const _loggers: Record<
  string,
  {
    ns: string;
    logger: Logger | AdvancedLogger;
  }
> = {
  [systemKey]: {
    ns: "system",
    logger: _systemLogger
  }
};

/**
 * Tell if a logger is enabled in the local storage
 * @param {string} key
 * @return {boolean}
 */
const isEnabled = (key: string) => {
  /**
   * Get all enabled loggers
   * @type {string[]}
   */
  const enabled = _.defaultTo(window.localStorage.getItem("debug"), "").split(",");

  return enabled.includes(key);
};

/**
 * Create a new logger instance and append it to the list of loggers
 * @param {string} key
 * @param {string} ns
 * @param {logdown.LogdownOptions & {transports?: boolean, tags?: Record<string, string>}} options
 * @return {logdown.Logger | AdvancedLogger}
 */
const create = (
  key: string,
  ns: string,
  options: LogdownOptions & { transports?: boolean; tags?: Record<string, string> }
): Logger | AdvancedLogger => {
  if (_loggers[key]) {
    _loggers.system.logger.error(`${key} is already registered as a logger.`);

    return _loggers[key].logger;
  } else {
    const logger = logdown(`[${ns}]`, options);

    if (isEnabled(key)) {
      logger.state.isEnabled = true;
    }

    if (options.transports) {
      (logger as AdvancedLogger).state.transports = true;

      (logger as AdvancedLogger).state.tags = _.merge(
        {
          diagnostics: key
        },
        options.tags
      );
    }

    _loggers[key] = {
      ns,
      logger
    };

    return logger;
  }
};

/**
 * Enable some or all loggers
 * @param {string} loggers
 */
const enable = (...loggers: Array<string>): string => {
  /**
   * Get all enabled loggers
   * @type {string[]}
   */
  const enabled = _.defaultTo(window.localStorage.getItem("debug"), "").split(",");

  /**
   * All the loggers
   * @type {string[]}
   */
  const all = _.map(_loggers, (v, k) => k);

  /**
   * Make a new list: all or the selected ones
   * @type {string}
   */
  const list = _.compact(
    _.uniq(loggers.includes("all") ? all : _.concat(enabled, _.intersection(all, loggers)))
  );

  /**
   * Manually enable them to break free of the node variable logdown uses
   */
  _.forEach(list, (key) => {
    const logger = _.get(_loggers, [key, "logger"]);

    if (logger) {
      logger.state.isEnabled = true;
    }
  });

  window.localStorage.setItem("debug", list.join(","));

  return `Enabled loggers: ${list.join(", ")}.`;
};

/**
 * Disable some or all loggers
 * @param {string} loggers
 */
const disable = (...loggers: Array<string>): string => {
  const enabled = _.defaultTo(window.localStorage.getItem("debug"), "").split(",");

  /**
   * Make a new list: none or without the selected ones
   * @type {string}
   */
  const list = _.compact(_.uniq(loggers.includes("all") ? [systemKey] : _.without(enabled, ...loggers)));

  /**
   * Manually disable them to break free of the node variable logdown uses
   */
  _.forEach(list, (key) => {
    const logger = _.get(_loggers, [key, "logger"]);

    if (logger) {
      logger.state.isEnabled = false;
    }
  });

  window.localStorage.setItem("debug", list.join(","));

  return `Enabled loggers: ${list.join(", ")}.`;
};

/**
 * Debug module public interface
 * @type {{get(target: ({[p: string]: logdown.Logger} & {create: (key: string, ns: string, options: logdown.LogdownOptions) => logdown.Logger}), prop: string): (((key: string, ns: string, options: logdown.LogdownOptions) => logdown.Logger) | logdown.Logger)}}
 */
const _intf = {
  get(
    target: {
      [p: string]: Logger;
    },
    prop: string
  ) {
    const logger = prop in _loggers ? _loggers[prop].logger : _loggers.system.logger;

    switch (prop) {
      case "create":
        return create;
      default:
        return logger;
    }
  }
};

type Public = Record<string, Logger> & {
  create: typeof create;
};

/**
 * CLI commands for the debugger
 * @type {{debug: {fn: ([command, ...args]: Array<string>) => (string | string), description: string}}}
 * @example
 * ```
 * > debug list
 * a,b,c,other,logger
 * > debug enable a b c
 * Enabled loggers: system,a,b,c
 * > debug enable all
 * Enabled loggers: system,a,b,c,other,logger
 * > debug disable a b c
 * Enabled loggers: system,other,logger
 * > debug disable all
 * Enabled loggers: system
 * ```
 */
export const debuggerCLI = {
  debug: {
    description: `Manage the debug tool.
      * list - list all loggers
      * enable [all | logger | logger,logger,logger] - enables one or more logger(s)
      * disable [all | logger | logger,logger,logger] - disables one or more logger(s)
    `,
    fn: ([command, ...args]: Array<string>) => {
      switch (command) {
        case "list":
          return _.map(_loggers, (v, k) => k).join(", ");
        case "enable": {
          return enable(...args);
        }
        case "disable":
          return disable(...args);
        default:
          return "Invalid command.";
      }
    }
  }
};

/**
 * Any logger previously created can be referred to by its key, falling back to "system"
 * @type {{[p: string]: ReturnType<typeof logdown>}}
 * @example
 * ```ts
 * Debug.gamePlan.log("Log something.");
 * Debug.gamePlan.error("An error occurred.");
 * ```
 */
export const Debug = new Proxy(_.mapValues(_loggers, "logger"), _intf) as Public;

if (window) {
  Object.defineProperty(window, "Debug", {
    get() {
      return {
        list: () => _.map(_loggers, (v, k) => k).join(", "),
        enable: (...args: string[]) => enable(...args),
        disable: (...args: string[]) => disable(...args)
      };
    }
  });
}
