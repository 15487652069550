import { createModule, from, makeAsset } from "../../utils";

export const insights = createModule("insights", {
  modal: from("loading"),
  enable: "enable",
  list: {
    headers: from("insight", "feedback", "farm", "period"),
    pageIntro: from("title", "description"),
    empty: from("title", "description", "action"),
    viewAll: "viewAll"
  },
  entity: {
    title: makeAsset,
    description: makeAsset,
    navigation: from("details")
  },
  extensions: {
    score: from("label"),
    createGamePlan: from("title", "description", "action"),
    cowProfile: from("title"),
    onboarding: {
      footer: from("title", "description"),
      chart: {
        ...from("title", "description"),
        axis: from("x", "y")
      }
    },
    bloodScreeningSchedule: {
      ...from("title", "description", "exportLabel"),
      tabs: from("thisWeek", "nextWeek"),
      table: {
        ...from("showMore"),
        columns: from("cowTagNumber", "calvingDate", "screeningResult"),
        testResults: {
          risk: from("high", "medium", "low"),
          untested: null
        }
      }
    },
    additiveFeedingSchedule: {
      ...from("title", "description"),
      calendar: {
        entry: {
          title: makeAsset
        }
      }
    },
    highRiskCows: {
      ...from("title", "description", "exportLabel"),
      table: {
        ...from("showMore"),
        columns: from("cowTagNumber", "riskSeverity", "riskType"),
        risk: from("high", "medium", "low")
      }
    }
  },
  manage: from("title", "edit"),
  popover: from("title", "content"),
  workflowState: from("toCheck", "toFollowUp", "done"),
  stateSince: makeAsset,
  total: makeAsset,
  unseen: "unseen"
});

export const insightValidations = createModule("insight-validations", {
  steps: {
    question: makeAsset,
    rollback: null
  },
  validationValue: from("yes", "no", "maybe", "na"),
  validationDescription: makeAsset
});

export const insightResolutions = createModule("insight-resolutions", {
  ...from("placeholder", "action", "other", "customLabel"),
  title: makeAsset
});
