import { readableHash } from "@ctra/utils";

import { GenericInsightEntity, InsightValidation, InsightWorkflowState } from "./typings";

/**
 * The sole purpose of this one is to hide the `objectHash` api
 * so we do not need to add it as a dependency in other apps.
 * @param query
 */
export const makeQueryHash = (query: Record<string, unknown>): string => readableHash(query);

/**
 * get the new workflow state of the insight based on validation
 */
export const getWorkflowState = (validation: InsightValidation): InsightWorkflowState => {
  switch (validation) {
    case InsightValidation.no:
      return InsightWorkflowState.done;
    case InsightValidation.maybe:
    case InsightValidation.yes:
      return InsightWorkflowState.toFollowUp;
    case InsightValidation.na:
      return InsightWorkflowState.toCheck;
  }
};

/**
 * Tell if the given entity is an insight
 * @param e
 * @return {e is GenericInsightEntity}
 */
export function isInsight(e: unknown): e is GenericInsightEntity {
  return (e as GenericInsightEntity).hasOwnProperty("insightType");
}
