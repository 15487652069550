import { NormalizedSchema, schema } from "normalizr";

import { DairyCowEntity, DairyCowSource, DairyCowList } from "./typings";

const dairyCow = new schema.Entity(
  "dairyCow",
  {},
  {
    idAttribute: "DairyCowID",
    processStrategy: ({
      DairyCowID,
      cowStatus,
      isDeleted,
      watchEnabled,
      DoNotBreed,
      lactationNumber,
      lastCalvingEpoch,
      lastEstrusEpoch,
      cowTagNumber,
      herdGroupID
    }: DairyCowSource): DairyCowEntity => ({
      id: DairyCowID,
      status: cowStatus,
      flags: {
        isDeleted,
        watchEnabled,
        doNotBreed: DoNotBreed
      },
      cowTagNumber,
      lactationNumber,
      lastCalvingEpoch,
      lastEstrusEpoch,
      herdGroupID
    })
  }
);

export type NormalizedCowList = NormalizedSchema<{ dairyCow: DairyCowList }, Array<DairyCowEntity["id"]>>;

export default {
  dairyCow
};
