import { FC, HTMLProps } from "react";
import { Space } from "antd";

import { classname } from "@ctra/utils";

import { Typography, Icon, Barn } from "../../../../elements";
import styles from "./FarmProgress.module.less";

const { Text } = Typography;

export interface FarmProgressProps extends HTMLProps<HTMLDivElement> {
  /**
   * progress value between 0 and 1
   */
  progress: number;
  /**
   * pointer label
   */
  pointer?: string;
  /**
   * widget label
   */
  label?: string;
}

/**
 * FarmProgress component
 * @param propName
 * @todo rename and implement
 */
const FarmProgress: FC<FarmProgressProps> = ({ progress, pointer, label, className, ...rest }) => {
  /**
   * Pointer element
   * @type {JSX.Element}
   */
  const pointerElement = pointer ? (
    <Space>
      <Icon component={Barn} />
      <Text size="md">{pointer}</Text>
    </Space>
  ) : (
    <Icon component={Barn} />
  );

  const progressPercent = `${progress * 100}%`;

  const position =
    progress <= 0.5
      ? {
          left: `max(10px, ${progressPercent})`
        }
      : {
          right: `max(10px, calc(100% - ${progressPercent}))`
        };

  const translate = {
    transform: progress <= 0.5 ? "translateX(-50%)" : "translateX(50%)"
  };

  return (
    <section
      className={classname("ctra-insight-generic-farmProgress", styles.FarmProgress, className)}
      {...rest}
    >
      <div className="ctra-insight-generic-farmProgress-pointerLabel-wrapper">
        <div
          className="ctra-insight-generic-farmProgress-pointerLabel"
          style={{
            ...position,
            ...translate
          }}
        >
          {pointerElement}
        </div>
      </div>
      <div
        className="ctra-insight-generic-farmProgress-pointer"
        style={{
          ...position,
          ...translate
        }}
      ></div>
      <div className="ctra-insight-generic-farmProgress-gradient"></div>
      {label && <Text size="md">{label}</Text>}
    </section>
  );
};

export default FarmProgress;
