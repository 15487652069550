// istanbul ignore file
import { version } from "../package.json";
import { Nullable } from "./typings";

/**
 * Get the version from the package.json
 * @return {number}
 */
export const getVersion = (): number => {
  return parseFloat(version.replace(/\./g, ""));
};

/**
 * Get the storage key prefix
 * @return {`ctra.utils.v${number}`}
 */
export const getStorageKey = () => {
  return `ctra.utils.v${getVersion()}`;
};

/**
 * Check if the key matches the current version
 * @param {Nullable<string>} key
 * @return {key is string}
 */
const _matches = (key: Nullable<string>): key is string => {
  const matchesUtils = key && key.match(/^ctra\.utils/);
  const matchesVersion = key && key.match(new RegExp(getVersion().toString(), "gi"));

  return !!matchesUtils && !matchesVersion;
};

/**
 * Purge the storage
 */
export const purgeStaleData = () => {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);

    if (_matches(key)) {
      sessionStorage.removeItem(key);
    }
  }

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (_matches(key)) {
      localStorage.removeItem(key);
    }
  }
};
