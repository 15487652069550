import withIconDefaultStyles from "./withIconDefaultStyles";

import { ReactComponent as UserIcon } from "../../../assets/svg/user.svg";
import { ReactComponent as Logout } from "../../../assets/svg/logout.svg";
import { ReactComponent as Analytics } from "../../../assets/svg/analytics.svg";
import { ReactComponent as GamePlans } from "../../../assets/svg/game-plans.svg";
import { ReactComponent as Settings } from "../../../assets/svg/settings.svg";
import { ReactComponent as Notifications } from "../../../assets/svg/notifications.svg";
import { ReactComponent as Password } from "../../../assets/svg/password.svg";
import { ReactComponent as Overview } from "../../../assets/svg/overview.svg";
import { ReactComponent as DueDate } from "../../../assets/svg/due-date.svg";
import { ReactComponent as ProductUpdates } from "../../../assets/svg/product-updates.svg";
import { ReactComponent as Email } from "../../../assets/svg/email.svg";
import { ReactComponent as Phone } from "../../../assets/svg/phone.svg";
import { ReactComponent as KPITracking } from "../../../assets/svg/kpi-tracking.svg";
import { ReactComponent as Tasks } from "../../../assets/svg/tasks.svg";
import { ReactComponent as GPSummary } from "../../../assets/svg/gp-summary.svg";
import { ReactComponent as _EmptyInsight } from "../../../assets/svg/empty-insight.svg";
import { ReactComponent as _EmptyTask } from "../../../assets/svg/empty-task.svg";
import { ReactComponent as _EmptyGamePlan } from "../../../assets/svg/empty-gameplan.svg";
import { ReactComponent as _EmptyEventList } from "../../../assets/svg/empty-eventlist.svg";

export { default as Icon } from "@ant-design/icons";
export { ReactComponent as Smile } from "../../../assets/branding/logo.svg"; // export logo as "Smile"
export { ReactComponent as Barn } from "../../../assets/svg/barn.svg";
export { ReactComponent as Barns } from "../../../assets/svg/barns.svg";
export { ReactComponent as ChartComparison } from "../../../assets/svg/comparison.svg";
export { ReactComponent as Analysis } from "../../../assets/svg/analysis.svg";
export { ReactComponent as KpiUnavailable } from "../../../assets/svg/kpi-unavailable.svg";
export { ReactComponent as KpiLocked } from "../../../assets/svg/kpi-locked.svg";
export { ReactComponent as ConnecterraLogo } from "../../../assets/branding/logo.svg";
export { ReactComponent as Undo } from "../../../assets/svg/undo.svg";
export { ReactComponent as KPIThresholdUp } from "../../../assets/insights/kpi-threshold-up.svg";
export { ReactComponent as KPIThresholdUpV2 } from "../../../assets/insights/kpi-threshold-up-v2.svg";
export { ReactComponent as KPIThresholdDown } from "../../../assets/insights/kpi-threshold-down.svg";
export { ReactComponent as KPIThresholdDownV2 } from "../../../assets/insights/kpi-threshold-down-v2.svg";
export { ReactComponent as KPIVarianceUp } from "../../../assets/insights/kpi-variance-up.svg";
export { ReactComponent as KPIVarianceDown } from "../../../assets/insights/kpi-variance-down.svg";
export { ReactComponent as KPIVarianceUpCircle } from "../../../assets/insights/kpi-variance-up-circle.svg";
export { ReactComponent as KPIVarianceDownCircle } from "../../../assets/insights/kpi-variance-down-circle.svg";
export { ReactComponent as Dot } from "../../../assets/svg/dot.svg";
export { ReactComponent as LelyMilkDropDetail } from "../../../assets/insights/lely-milk-drop-detail.svg";
export { ReactComponent as LelyMilkDropList } from "../../../assets/insights/lely-milk-drop-list.svg";
export { ReactComponent as LelyNoMilkDropDetail } from "../../../assets/insights/lely-no-milk-drop-detail.svg";
export { ReactComponent as LelyNoMilkDropList } from "../../../assets/insights/lely-no-milk-drop-list.svg";
export { ReactComponent as LelyKetosisDetail } from "../../../assets/insights/lely-ketosis-detail.svg";
export { ReactComponent as LelyKetosisList } from "../../../assets/insights/lely-ketosis-list.svg";
export { ReactComponent as Ajinomoto } from "../../../assets/insights/ajinomoto.svg";
export { ReactComponent as Cow } from "../../../assets/svg/cow.svg";
export { ReactComponent as AskIda } from "../../../assets/branding/logo.svg";
export { ReactComponent as AskIdaDuotone } from "../../../assets/branding/logo.svg";
export { ReactComponent as FarmEquipment } from "../../../assets/svg/farm-equipment.svg";
export { ReactComponent as Feed } from "../../../assets/svg/feed.svg";
export { ReactComponent as Housing } from "../../../assets/svg/housing.svg";
export { ReactComponent as Management } from "../../../assets/svg/management.svg";
export { ReactComponent as Other } from "../../../assets/svg/other.svg";
export { ReactComponent as Weather } from "../../../assets/svg/weather.svg";
export { ReactComponent as AvatarList } from "../../../assets/svg/avatar-list.svg";
export { ReactComponent as IncreasedBars } from "../../../assets/svg/increased-bars.svg";
export { ReactComponent as DecreasedBars } from "../../../assets/svg/decreased-bars.svg";
export { ReactComponent as UnchangedBars } from "../../../assets/svg/unchanged-bars.svg";
export { ReactComponent as UnchangedBarsInsufficient } from "../../../assets/svg/unchanged-bars-insufficient.svg";
export { ReactComponent as BrowserOutlined } from "../../../assets/svg/browser.svg";
export { ReactComponent as ActionKey } from "../../../assets/svg/action-key.svg";
export { ReactComponent as Copilot } from "../../../assets/svg/copilot.svg";
export { ReactComponent as StarHalfFilled } from "../../../assets/svg/star-half-filled.svg";

export { ReactComponent as FeedCircle } from "../../../assets/svg/feed-circle.svg";
export { ReactComponent as HousingCircle } from "../../../assets/svg/housing-circle.svg";
export { ReactComponent as ManagementCircle } from "../../../assets/svg/management-circle.svg";
export { ReactComponent as OtherCircle } from "../../../assets/svg/other-circle.svg";
export { ReactComponent as WeatherCircle } from "../../../assets/svg/weather-circle.svg";
export { ReactComponent as FarmEquipmentCircle } from "../../../assets/svg/farm-equipment-circle.svg";
export { ReactComponent as Radar } from "../../../assets/svg/radar.svg";
export { ReactComponent as CowGroup } from "../../../assets/svg/cow-group.svg";
export { ReactComponent as InnerJoin } from "../../../assets/svg/inner-join.svg";

// export some source URLs for use in chart event visuals
export { default as FarmEquipmentSRC } from "../../../assets/svg/farm-equipment.svg";
export { default as FeedSRC } from "../../../assets/svg/feed.svg";
export { default as HousingSRC } from "../../../assets/svg/housing.svg";
export { default as ManagementSRC } from "../../../assets/svg/management.svg";
export { default as OtherSRC } from "../../../assets/svg/other.svg";
export { default as WeatherSRC } from "../../../assets/svg/weather.svg";

export { ReactComponent as CheckCircle } from "../../../assets/svg/check-circle.svg";
export { ReactComponent as BulbCircle } from "../../../assets/svg/bulb-circle.svg";

/**
 * Export custom icons with the same names as the ant icons in use
 */
export const UserOutlined = withIconDefaultStyles(UserIcon);
export const ExportOutlined = withIconDefaultStyles(Logout);
export const LineChartOutlined = withIconDefaultStyles(Analytics);
export const CompassOutlined = withIconDefaultStyles(GamePlans);
export const SettingOutlined = withIconDefaultStyles(Settings);
export const NotificationOutlined = withIconDefaultStyles(Notifications);
export const UnlockOutlined = withIconDefaultStyles(Password);
export const AppstoreOutlined = withIconDefaultStyles(Overview);
export const ClockCircleOutlined = withIconDefaultStyles(DueDate);
export const GiftOutlined = withIconDefaultStyles(ProductUpdates);
export const MailOutlined = withIconDefaultStyles(Email);
export const PhoneOutlined = withIconDefaultStyles(Phone);
export const BarChartOutlined = withIconDefaultStyles(KPITracking);
export const CheckSquareOutlined = withIconDefaultStyles(Tasks);
export const ProfileOutlined = withIconDefaultStyles(GPSummary);

/**
 * Export custom generated icons for empty states
 */
export const EmptyInsight = withIconDefaultStyles(_EmptyInsight);
export const EmptyTask = withIconDefaultStyles(_EmptyTask);
export const EmptyGamePlan = withIconDefaultStyles(_EmptyGamePlan);
export const EmptyEventList = withIconDefaultStyles(_EmptyEventList);
