import * as _ from "lodash";
import { combineEpics, ofType, Epic } from "redux-observable";
import { takeUntil } from "rxjs/operators";

import { epics as authEpics } from "../modules/auth";
import { epics as signupEpics } from "../modules/signup";
import { epics as farmEpics } from "../modules/farms";
import { epics as userEpics } from "../modules/user";
import { epics as invitationEpics } from "../modules/invitation";

/**
 * @todo add new epics here
 */
const epics: Record<string, Epic> = {
  ...authEpics,
  ...signupEpics,
  ...farmEpics,
  ...userEpics,
  ...invitationEpics
};

/**
 * Combine all the imported epics into one observable stream
 */
const combinedEpics: Epic = combineEpics(..._.values(epics));

/**
 * @param action$
 * @param state$
 * @param rest
 */
const rootEpic: Epic = (action$, state$, ...rest) =>
  combinedEpics(action$, state$, ...rest).pipe(takeUntil(action$.pipe(ofType("END"))));

export default rootEpic;
