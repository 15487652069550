import * as _ from "lodash";
import { NormalizedSchema, schema } from "normalizr";

import {
  UserEntity,
  AccountDetailsResponse,
  UserInfoResponse,
  UserBase,
  UserGroup,
  FarmConnectionResponse
} from "./typings";

/**
 * Tell whether the source is a UserInfoResponse
 * @param source
 * @return {source is UserInfoResponse}
 */
const isUserInfoResponse = (source: unknown): source is UserInfoResponse => {
  return _.isObject(source) && _.has(source, "userGroups");
};

/**
 * Tell whether the source is a UserBase
 * @param source
 * @return {source is UserBase}
 */
const isMember = (source: unknown): source is UserBase => {
  return _.isObject(source) && _.isEqual(_.keys(source), ["email", "id", "lastName", "firstName"]);
};

const isFarmConnectionResponse = (source: unknown): source is FarmConnectionResponse => {
  return _.isObject(source) && _.has(source, "customerId");
};

/**
 * User schema
 * @type {schema.Entity<UserEntity>}
 */
const user = new schema.Entity<UserEntity>(
  "user",
  {},
  {
    /**
     * On the EmailSimple `id` is used, while `userID` is present on `connected-users`
     * @param id
     * @param userID
     */
    idAttribute: ({ id, userID }) => id || userID,
    /**
     * @param {UserInfoResponse | AccountDetailsResponse} user
     * @return {{firstName: string, lastName: string, country: null, userGroups: {id: string, name: string}[], phoneNumber: null, id: string, email: string, username: string} | {firstName: string, lastName: string, country: string, userGroups: null, phoneNumber: string, id: string, email: string, username: string}}
     */
    processStrategy: (user: UserInfoResponse | AccountDetailsResponse | UserBase) => {
      if (isMember(user)) {
        const { id, email, lastName, firstName } = user;

        return {
          id: id || (_.get(user, "userID") as string),
          email,
          firstName,
          lastName,
          username: null,
          country: null,
          phoneNumber: null,
          metadata: null,
          roles: null,
          farmAccess: null,
          // TODO reverse lookup from the parent
          userGroups: null
        };
      } else if (isUserInfoResponse(user)) {
        const { email, givenName, surName, id, userName, userGroups, roles, metadata, farmAccess } = user;

        return {
          id: id || (_.get(user, "userID") as string),
          metadata,
          roles,
          farmAccess,
          email,
          firstName: givenName,
          lastName: surName,
          username: userName,
          country: null,
          phoneNumber: null,
          // TODO normalize this one as well
          userGroups: _.map(userGroups, ({ id, name }) => ({ id, name }))
        };
      } else if (isFarmConnectionResponse(user)) {
        const { id, givenName, surName, email, phoneNumber, country, userName } = user;

        return {
          id,
          email,
          firstName: givenName,
          lastName: surName,
          username: userName,
          country,
          phoneNumber,
          metadata: null,
          roles: null,
          farmAccess: null,
          // TODO reverse lookup from the parent
          userGroups: null
        };
      } else {
        const { id, phoneNumber, surName, email, userName, givenName, country, rolesList } = user;

        return {
          email,
          id: id || (_.get(user, "userID") as string),
          firstName: givenName,
          lastName: surName,
          username: userName,
          country: country,
          phoneNumber: phoneNumber,
          userGroups: null,
          roles: rolesList,
          farmAccess: null,
          metadata: null
        };
      }
    }
  }
);

/**
 * List of users
 * @type {Array}
 */
const userList = new schema.Array(user);

/**
 * User group schema
 * @type {schema.Entity<UserGroup>}
 */
const userGroup = new schema.Entity<UserGroup>("userGroup", {
  members: userList
});

/**
 * List of user groups
 * @type {Array}
 */
const userGroupList = new schema.Array(userGroup);

/**
 * Users by farm connection schema
 * @type {Object}
 */
const usersByFarmConnection = new schema.Object({
  groups: userGroupList,
  members: userList,
  directUsers: userList
});

/**
 * ConsentUserList schema
 * @type {Object}
 */
const usersByGroup = new schema.Object({
  members: userList,
  userGroups: userGroupList,
  externalUsers: userGroupList
});

/**
 * Usr list by group schema
 * @type {Object}
 */
const usersByAppAccess = new schema.Object({
  directMembers: userList,
  userGroupMembers: userList,
  farmMembers: userList,
  allMembers: userList,
  assignableMembers: userList
});

export type NormalizedUser = NormalizedSchema<
  {
    user: Record<UserEntity["id"], UserEntity>;
  },
  UserEntity["id"]
>;

export type NormalizedUserList = NormalizedSchema<
  {
    userGroup?: Record<UserGroup["id"], UserGroup>;
    user: Record<UserEntity["id"], UserEntity>;
  },
  {
    members?: Array<UserEntity["id"]>;
    externalUsers?: Array<UserGroup["id"]>;
    userGroups?: Array<UserGroup["id"]>;
    directMembers: Array<UserEntity["id"]>;
    userGroupMembers?: Array<UserEntity["id"]>;
    farmMembers?: Array<UserEntity["id"]>;
    allMembers?: Array<UserEntity["id"]>;
    assignableMembers?: Array<UserEntity["id"]>;
    directUsers?: Array<UserEntity["id"]>;
  }
>;

export default {
  userGroup,
  usersByGroup,
  user,
  userList,
  usersByAppAccess,
  usersByFarmConnection
};
