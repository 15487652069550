import * as _ from "lodash";
import { InsightState } from "../typings";

/**
 * Default expands for every insight query
 */
const expandDefaults = (): Array<string> => ["insightType", "insightValidations", "dairyCow"];

/**
 * Only "live" insights (and "Hold" on staging)
 */
const insightStates = (): Array<string> =>
  _.compact([
    InsightState.live,
    InsightState.forcedLive,
    process.env.REACT_APP_BUILD_ENV === "staging" ? InsightState.hold : ""
  ]);

export { expandDefaults, insightStates };
