/**
 * @todo move to sensor when applicable
 */
import { Nullable } from "@ctra/utils";
import { FarmEntity } from "../farms";
import { HerdGroupEntity } from "../herd-groups";
import { UserEntity } from "../user";

export interface NodeSource {
  NodeID: number;
}

export enum MolenaarState {
  open = "open",
  pregnant = "pregnant",
  dry = "dry",
  inseminated = "inseminated"
}

export interface DairyCowSource {
  DairyCowID: number;
  DoNotBreed: boolean;
  PreviousNodeId: Nullable<NodeSource["NodeID"]>;
  advisorInsights: Array<unknown>;
  areaID: null;
  breedType: Nullable<string>;
  cowBirthDate: string;
  cowCurrentLocation: Nullable<string>;
  cowHealthState: Nullable<string>;
  cowName: Nullable<string>;
  cowStatus: MolenaarState;
  cowTagNumber: string;
  dairyLabel: Array<unknown>;
  dateCreated: string;
  expectedCalvingEpoch: number;
  farmID: FarmEntity["id"];
  herdGroupID: HerdGroupEntity["id"];
  isDeleted: boolean;
  lactationNumber: number;
  lastCalvingEpoch: number;
  lastEstrusEpoch: number;
  lastInseminationEpoch: number;
  mutedInsights: Nullable<unknown>;
  nextCalvingEpoch: number;
  nextEstrusEpoch: number;
  nodeID: Nullable<NodeSource["NodeID"]>;
  securityAuditUser: UserEntity["username"];
  securityGroupID: string;
  systemInsights: Array<unknown>;
  watchEnabled: boolean;
}

export interface DairyCowEntity {
  id: DairyCowSource["DairyCowID"];
  cowTagNumber: DairyCowSource["cowTagNumber"];
  lactationNumber: number;
  lastCalvingEpoch: number;
  lastEstrusEpoch: number;
  status: MolenaarState;
  flags: {
    doNotBreed: boolean;
    isDeleted: boolean;
    watchEnabled: boolean;
  };
  herdGroupID: HerdGroupEntity["id"];
}

export type DairyCowList = Record<DairyCowEntity["id"], DairyCowEntity>;
