import { Action } from "@ctra/utils";
import types from "./types";

/**
 * Drop a breadcrumb
 * @category Action
 */
const dropBreadcrumb = (id: string, title: string, path?: string): Action => ({
  type: types.DROP_BREADCRUMB,
  payload: {
    id,
    title,
    path
  }
});

/**
 * Update a breadcrumb
 * @category Action
 */
const updateBreadcrumb = (id: string, newTitle: string, newPath?: string): Action => ({
  type: types.UPDATE_BREADCRUMB,
  payload: {
    id,
    title: newTitle,
    ...(newPath ? { path: newPath } : {})
  }
});

/**
 * Pick up a breadcrumb from the floor
 * @category Action
 */
const pickUpBreadcrumb = (id: string): Action => ({
  type: types.PICK_UP_BREADCRUMB,
  payload: {
    id
  }
});

export default {
  dropBreadcrumb,
  updateBreadcrumb,
  pickUpBreadcrumb
};
