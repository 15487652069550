import { FC, ComponentProps } from "react";
import Icon from "@ant-design/icons";

import { ReactComponent as UserIcon } from "../../../assets/svg/user.svg";

/**
 * HOC thar returns icon component for custom icons
 * Would help us get rid of wrapping icons in <Icon component={ICON_HERE} />
 * @param icon
 */
const withIconDefaultStyles = (icon: typeof UserIcon): FC<ComponentProps<typeof Icon>> => {
  const IconWrapper: FC<ComponentProps<typeof Icon>> = (props) => <Icon component={icon} {...props} />;

  return IconWrapper;
};

export default withIconDefaultStyles;
