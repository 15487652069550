import { createModule, from } from "../../utils";

export const base = createModule("base", {
  languageSelector: from("label")
});

/**
 * Module for navigation related content
 * @todo don't pollute, this is not a trash for content without a category
 */
export const navigation = createModule("navigation", {
  main: from("gettingStarted", "helpCenter", "support", "collapse", "logout"),
  navicard: {
    owner: from("title", "description"),
    explorer: from("title", "description"),
    other: from("title", "description"),
    advisor: from("title", "description"),
    employee: from("title", "description")
  },
  language: from("label"),
  unitSystem: from("label", "imperial", "metric")
});

/**
 * Error handling
 */
export const errors = createModule("errors", {
  notFound: from("title", "subtitle", "action")
});
