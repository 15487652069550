import { ComponentProps, FC } from "react";
import { Select } from "antd";

import { classname } from "@ctra/utils";

import styles from "./FarmSelect.module.less";

export type FarmSelectProps = ComponentProps<typeof Select>;

/**
 * Farm selector component
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @param {any} className
 * @param {Pick<(<ValueType=any, OptionType=DefaultOptionType extends BaseOptionType | DefaultOptionType>(props: (SelectProps<ValueType, OptionType> & {children?: React.ReactNode} & {ref?: React.Ref<BaseSelectRef> | undefined})) => React.ReactElement) & {SECRET_COMBOBOX_MODE_DO_NOT_USE: string, Option: typeof Option, OptGroup: typeof OptGroup} extends React.JSXElementConstructor<infer P> ? P : ((<ValueType=any, OptionType=DefaultOptionType extends BaseOptionType | DefaultOptionType>(props: (SelectProps<ValueType, OptionType> & {children?: React.ReactNode} & {ref?: React.Ref<BaseSelectRef> | undefined})) => React.ReactElement) & {SECRET_COMBOBOX_MODE_DO_NOT_USE: string, Option: typeof Option, OptGroup: typeof OptGroup} extends keyof JSX.IntrinsicElements ? JSX.IntrinsicElements[(<ValueType=any, OptionType=DefaultOptionType extends BaseOptionType | DefaultOptionType>(props: (SelectProps<ValueType, OptionType> & {children?: React.ReactNode} & {ref?: React.Ref<BaseSelectRef> | undefined})) => React.ReactElement) & {SECRET_COMBOBOX_MODE_DO_NOT_USE: string, Option: typeof Option, OptGroup: typeof OptGroup}] : {}) & {children?: React.ReactNode | undefined}, never>} props
 * @return {JSX.Element}
 * @constructor
 */
const FarmSelect: FC<FarmSelectProps> & { Option: typeof Select.Option } = ({
  children,
  className,
  ...props
}) => (
  <div className={classname("ctra-farmSelector-select", styles.FarmSelect, className)}>
    <Select popupClassName={styles.Dropdown} {...props}>
      {children}
    </Select>
  </div>
);

FarmSelect.Option = Select.Option;

export default FarmSelect;
