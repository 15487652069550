import { AsyncType, createAsyncTypes } from "@ctra/utils";

const ns = "app/user";

/**
 * Fetch a user object
 * @type {AsyncType}
 * @category Type
 */
const FETCH_USER: AsyncType = createAsyncTypes(ns, "FETCH_USER");

/**
 * Fetch a user object
 * @type {AsyncType}
 * @category Type
 */
const FETCH_USER_LIST: AsyncType = createAsyncTypes(ns, "FETCH_USER_LIST", { primary: "farmID" });

/**
 * Fetch the account the user is registered with (during subscription)
 * @type {AsyncType}
 * @category Type
 */
const FETCH_ACCOUNT_DETAILS: AsyncType = createAsyncTypes(ns, "FETCH_ACCOUNT_DETAILS");

/**
 * Update the account (during subscription)
 * @type {AsyncType}
 * @category Type
 */
const UPDATE_ACCOUNT_DETAILS: AsyncType = createAsyncTypes(ns, "UPDATE_ACCOUNT_DETAILS");

/**
 * Update the account (during signup)
 * @type {AsyncType}
 */
const UPDATE_SIGNUP_DETAILS: AsyncType = createAsyncTypes(ns, "UPDATE_SIGNUP_DETAILS");

export default {
  FETCH_USER,
  FETCH_USER_LIST,
  UPDATE_SIGNUP_DETAILS,
  FETCH_ACCOUNT_DETAILS,
  UPDATE_ACCOUNT_DETAILS
};
