/**
 * @todo add more grammatical cases for translation accuracy
 * @see https://en.wikipedia.org/wiki/List_of_glossing_abbreviations
 */
enum Case {
  inessive = "INE",
  accusative = "ACC",
  superessive = "SUPESS"
}

/**
 * "The", "a", "an" or nothing
 */
enum Article {
  definite = "DEF",
  indefinite = "NDEF"
}

export const Grammar = {
  Case,
  Article
};
