import i18next, { TFunction } from "i18next";
import moment from "moment";
import ICU from "i18next-icu";
import Backend, { BackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import * as _ from "lodash";

import { loadAppLocale } from "../locales";

/**
 * Default language
 * use locale set in local storage or
 * fallbackLng (English-US) if not set
 * @type {string}
 */
const defaultLanguage = loadAppLocale();

/**
 * Paths to use to load and save assets from.
 */
interface Paths {
  loadPath: string;
  addPath: string;
}

interface Payload {
  id: string;
  text: string;
  default: string;
}

/**
 * Make an i18n instance for the given back-end.
 * @param {Paths} paths
 * @example
 * ```ts
 * const API = {
 *   loadPath: "https://translate-enterprise.connecterra.workers.dev/?lang={{lng}}",
 *   addPath: "https://create-enterprise-asset.connecterra.workers.dev/",
 * };
 *
 * init(API).then(() => {
 *   console.info("i18n successfully initialised");
 * });
 * ```
 */
export const init = (paths: Paths): Promise<TFunction> => {
  i18next.on("languageChanged", (lng) => moment.locale(lng));

  return i18next
    .use(initReactI18next)
    .use(ICU)
    .use(Backend)
    .init(
      {
        debug: true,
        lng: _.defaultTo(defaultLanguage, void 0),
        fallbackLng: _.defaultTo(defaultLanguage, void 0),
        saveMissing: true,

        backend: {
          ...paths,

          /**
           * Loco "new asset" format
           * @param {string} namespace
           * @param {string} key
           * @return {{id: string, text: string, default: string}}
           */
          parsePayload: (namespace: string, key: string): Payload => ({
            id: key,
            text: key,
            default: "incomplete"
          }),

          /**
           * Add some "cors" "no-cors" smartery as
           * workers.dev does not support
           * fetch with method "POST" & cors together.
           * @param {{}} payload
           */
          requestOptions: (
            payload: Payload
          ): {
            mode: string;
            credentials: string;
            cache: string;
          } => ({
            mode: payload ? "no-cors" : "cors",
            credentials: "same-origin",
            cache: "default"
          })
        } as BackendOptions,

        interpolation: {
          escapeValue: false
        },

        react: {
          bindI18n: "languageChanged loaded",
          bindI18nStore: "added removed",
          nsMode: "default"
        }
      },
      () => null
    );
};
