import store, { history, persistor } from "./store";
import * as entities from "./entities";
export * from "./state";

export const Enterprise = {
  store,
  history,
  persistor,
  entities
};
