import { Nullable } from "@ctra/utils";
import { HerdGroupEntity, HerdGroupResponse } from "../herd-groups";
import { DIMGroupEntity, DIMGroupResponse } from "../dim-groups";
import { PenEntity, PenResponse } from "../pens";

export enum FarmStatus {
  Active = "Active",
  Churned = "Churned",
  Created = "Created",
  Deactivated = "Deactivated",
  Deleted = "Deleted",
  Demo = "Demo",
  Integrated = "Integrated",
  Onboarding = "Onboarding",
  SaasOnly = "SaasOnly",
  Sandbox = "Sandbox",
  Suspended = "Suspended",
  Test = "Test"
}

export interface IntegrationData {
  name: string;
  dataSourceDate: string;
}

export interface Integration {
  name: string;
  dataTypes: IntegrationData[];
  mostRecentDataSourceDate: string;
}

export interface FarmEntity {
  id: number;
  address: Nullable<string>;
  city: Nullable<string>;
  country: Nullable<string>;
  gpsCoordinates: {
    lat: number;
    lon: number;
  };
  status: FarmStatus;
  name: Nullable<string>;
  postCode: Nullable<string>;
  assignedColor: Nullable<string>;
  herdGroups: Array<HerdGroupEntity["id"]>;
  dimGroups: Array<DIMGroupEntity["id"]>;
  pens: Array<PenEntity["id"]>;
  integrations?: Array<Integration>;
}

export interface FarmDetailsRequestPayload {
  id: number;
  customerId: string;
  name: string;
  address: string;
  city: string;
  country: string;
  postCode: string;
  description: string;
  type: string;
  gpsCoordinates: string;
  state: string;
  preferences: string;
  dateCreated: string;
}

export interface FarmDetailsUpdatePayload {
  farmName: string;
  address: string;
  city: string;
  country: string;
  zipCode: string;
  dhmsDetails: {
    dhms: string;
    notification: boolean;
    other?: string;
  };
}

export interface FarmListResponse {
  farms: Array<{
    address: string;
    city: string;
    country: string;
    consents: Array<string>;
    farmID: number;
    status: FarmStatus;
    gpsCoordinates: string;
    name: string;
    postcode: string;
    dimGroups: Array<DIMGroupResponse>;
    herdGroups: Array<HerdGroupResponse>;
    pens: Array<PenResponse>;
  }>;
}

export type FarmList = Record<FarmEntity["id"], FarmEntity>;
