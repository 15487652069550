import { catchError, map, mapTo, mergeMap, takeUntil } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";
import { Observable, of } from "rxjs";
import buildQuery from "odata-query";

import { Action } from "@ctra/utils";

import { makeApiURL, makeAzureApiURL } from "../../utils/ajax";
import types from "./types";
import actions from "./actions";

import {
  GenericInsightSource,
  InsightResolutionEntity,
  InsightTypeSource,
  InsightValidationSource,
  KPIInsightSettingsState,
  NotificationAction,
  RequestActionMap
} from "./typings";

import resolutions from "./insightResolutions.json";

/**
 * Notification settings "code"
 * @todo figure out what this is and how to clean it up
 */
const code =
  /* istanbul ignore next - not testing production env */ process.env.REACT_APP_BUILD_ENV === "production"
    ? "Xx5hFbxrATsrLplQfADRMU52zACbrlO392Whel2Ru0Rn3RIcgvmVJg=="
    : "PXXB0XsyAEqKtmHHHJgGU34fQ8GwFgjPSmQzaUBCHGuta2nogafe7w==";

/**
 * Fetch insight mappings
 * @param action$
 * @param state$
 * @param Request
 */
const fetchInsightNotificationMappings: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_INSIGHT_NOTIFICATION_MAPPINGS.pending),
    mergeMap<ReturnType<typeof actions.fetchInsightNotificationMappings.start>, Observable<Promise<unknown>>>(
      () => {
        const url = makeAzureApiURL("hermes", `/self/user-preferences?appName=oneida&code=${code}`)();

        return Request.GET(url).pipe(
          map<{ response: unknown }, Action>(({ response }) =>
            actions.fetchInsightNotificationMappings.fulfill(response)
          ),
          catchError<unknown, Observable<Action>>((error) =>
            of(actions.fetchInsightNotificationMappings.reject(error))
          )
        );
      }
    )
  );

/**
 * Update insight notification settings
 * @param action$
 * @param state$
 * @param Request
 */
const updateInsightNotificationSettings: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_INSIGHT_NOTIFICATION_SETTINGS.pending),
    mergeMap<
      ReturnType<typeof actions.updateInsightNotificationSettings.start>,
      Observable<Promise<unknown>>
    >(({ payload: { notificationType, notificationMappingID, notificationAction } }) => {
      const url = makeAzureApiURL("hermes", `/self/user-preferences?appName=oneIda&code=${code}`)();

      /**
       * Send a post or delete request based on the action i.e. enable or disable a channel
       */
      const requestMap: RequestActionMap = {
        [NotificationAction.enable]: "POST",
        [NotificationAction.disable]: "DELETE"
      };

      return Request[requestMap[notificationAction]](url, {
        body: {
          mappingId: notificationMappingID,
          channel: notificationType,
          appName: "oneida"
        }
      }).pipe(
        map<unknown, Action>(() =>
          actions.updateInsightNotificationSettings.fulfill(
            notificationType,
            notificationMappingID,
            notificationAction
          )
        ),
        catchError<unknown, Observable<Action>>((error) =>
          of(actions.updateInsightNotificationSettings.reject(error))
        )
      );
    })
  );

/**
 * Fetch insights from the generic insights endpoint
 * @param action$
 * @param state$
 * @param Request
 */
const fetchInsights: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_INSIGHTS.pending),
    mergeMap<ReturnType<typeof actions.fetchInsights.start>, Observable<Promise<unknown>>>(
      ({ payload: { hash, query, list } }) => {
        const url = makeApiURL(
          `https://api<%= env %>.connecterra.ai/odata/GenericInsights${buildQuery(query)}`,
          { dev: "staging" }
        )({});

        return Request.GET(url).pipe(
          map<{ response: { value: Array<GenericInsightSource> } }, Action>(({ response: { value } }) =>
            actions.fetchInsights.fulfill(hash, value, list)
          ),
          catchError<unknown, Observable<Action>>((error) => of(actions.fetchInsights.reject(hash, error)))
        );
      }
    )
  );

/**
 * Request the kpi insight settings for the logged in user
 * @param action$
 * @param state$
 * @param Request
 */
const fetchKPIInsightSettings: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_KPI_INSIGHT_SETTINGS.pending),
    mergeMap<ReturnType<typeof actions.fetchKPIInsightSettings.start>, Observable<Promise<unknown>>>(() => {
      const url = makeAzureApiURL("accounts", "/self/preferences/kpi-insights")();

      return Request.GET(url).pipe(
        map<{ response: { preferences: KPIInsightSettingsState } }, Action>(({ response }) =>
          actions.fetchKPIInsightSettings.fulfill(response)
        ),
        catchError<unknown, Observable<Action>>((error) => of(actions.fetchKPIInsightSettings.reject(error))),
        takeUntil(action$.pipe(ofType(types.FETCH_KPI_INSIGHT_SETTINGS.pending)))
      );
    })
  );

/**
 * Update the KPI insight settings of the given farm and data descriptor
 * @param action$
 * @param state$
 * @param Request
 */
const updateKPIInsightSettings: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_KPI_INSIGHT_SETTINGS.pending),
    mergeMap<ReturnType<typeof actions.updateKPIInsightSettings.start>, Observable<Promise<unknown>>>(
      ({ payload }) => {
        const url = makeAzureApiURL("accounts", "/self/preferences/kpi-insights")();

        return Request.PUT(url, {
          body: payload
        }).pipe(
          map<{ response: unknown }, Action>(() => actions.updateKPIInsightSettings.fulfill(payload)),
          catchError<unknown, Observable<Action>>((error) =>
            of(actions.updateKPIInsightSettings.reject(error))
          )
        );
      }
    )
  );

/**
 * Create a new insight validation
 * @param action$
 * @param state$
 * @param Request
 */
const createInsightValidation: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.CREATE_INSIGHT_VALIDATION.pending),
    mergeMap<ReturnType<typeof actions.createInsightValidation.start>, Observable<Promise<unknown>>>(
      ({ payload: { genericInsightID, validation, validatedBy, validationEpoch } }) => {
        const url = makeApiURL("https://api<%= env %>.connecterra.ai/odata/InsightValidations")({});

        return Request.POST(url, {
          body: {
            genericInsightID,
            validationEpoch,
            validation,
            validationByID: validatedBy
          }
        }).pipe(
          map<{ response: InsightValidationSource }, Action>(({ response }) =>
            actions.createInsightValidation.fulfill(response)
          ),
          catchError<unknown, Observable<Action>>((error) =>
            of(actions.createInsightValidation.reject(error))
          )
        );
      }
    )
  );

/**
 * Update insight validations
 * @param action$
 * @param state$
 * @param Request
 */
const updateInsightValidation: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_INSIGHT_VALIDATION.pending),
    mergeMap<ReturnType<typeof actions.updateInsightValidation.start>, Observable<Promise<unknown>>>(
      ({ payload: { id, genericInsightID, validation, validatedBy, validationEpoch } }) => {
        const url = makeApiURL(`https://api<%= env %>.connecterra.ai/odata/InsightValidations(<%= id %>)`)({
          id
        });

        return Request.PUT(url, {
          body: {
            InsightValidationID: id,
            validationByID: validatedBy,
            genericInsightID,
            validationEpoch,
            validation
          }
        }).pipe(
          map<{ response: InsightValidationSource }, Action>(({ response }) =>
            actions.updateInsightValidation.fulfill({
              id,
              genericInsightID,
              validation,
              validatedBy
            })
          ),
          catchError<unknown, Observable<Action>>((error) =>
            of(actions.updateInsightValidation.reject(id, error))
          )
        );
      }
    )
  );

/**
 * Fetch insight resolutions (which is local data at the moment)
 * @param action$
 */
const fetchInsightResolutions: Epic = (action$) =>
  action$.pipe(
    ofType(types.FETCH_INSIGHT_RESOLUTIONS.pending),
    mapTo(actions.fetchInsightResolutions.fulfill(resolutions as unknown as InsightResolutionEntity[]))
  );

/**
 * Update the insight. So far only resolutions.
 * @todo find a good solution for more versatile updates
 * @param action$
 * @param state$
 * @param Request
 */
const updateInsight: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.UPDATE_INSIGHT.pending),
    mergeMap<ReturnType<typeof actions.updateInsight.start>, Observable<Promise<unknown>>>(
      ({
        payload: {
          id,
          updates,
          source: { resolution: insightResolution, seenBy, workflowState, workflowStateLastModifiedDate }
        }
      }) => {
        const url = makeApiURL(`https://api<%= env %>.connecterra.ai/odata/GenericInsights(<%= id %>)`)({
          id
        });

        return Request.PUT(url, {
          body: {
            insightResolution: JSON.stringify(insightResolution),
            seenByUserIds: seenBy ? seenBy.join(",") : void 0,
            workflowState,
            workflowStateLastModifiedDate
          }
        }).pipe(
          map<{ response: InsightValidationSource }, Action>(({ response }) =>
            actions.updateInsight.fulfill(id, updates)
          ),
          catchError<unknown, Observable<Action>>((error) => of(actions.updateInsight.reject(id, error)))
        );
      }
    )
  );

/**
 * Request the kpi insight settings for the logged in user
 * @param action$
 * @param state$
 * @param Request
 */
const fetchInsightTypes: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_INSIGHT_TYPES.pending),
    mergeMap<ReturnType<typeof actions.fetchInsightTypes.start>, Observable<Promise<unknown>>>(() => {
      const url = makeApiURL("https://api<%= env %>.connecterra.ai/odata/InsightTypes")({});

      return Request.GET(url).pipe(
        map<{ response: { value: Array<InsightTypeSource> } }, Action>(({ response: { value } }) =>
          actions.fetchInsightTypes.fulfill(value)
        ),
        catchError<unknown, Observable<Action>>((error) => of(actions.fetchInsightTypes.reject(error))),
        takeUntil(action$.pipe(ofType(types.FETCH_INSIGHT_TYPES.pending)))
      );
    })
  );

export default {
  fetchInsights,
  fetchKPIInsightSettings,
  updateKPIInsightSettings,
  fetchInsightNotificationMappings,
  updateInsightNotificationSettings,
  fetchInsightResolutions,
  createInsightValidation,
  updateInsightValidation,
  updateInsight,
  fetchInsightTypes
};
