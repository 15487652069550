import * as _ from "lodash";

/**
 * Takes in array of words + conjunction and outputs a list of words grammatically seperated with conjunction
 * note: conjunction is a param because of its variability in different languages
 * @param arrayOfWords
 * @param conjunction
 */
export const makeList = (arrayOfWords: Array<string>, conjunction: string): string => {
  let commas: Array<string> = [];
  let withConjunction: Array<string> = [];

  /**
   * Array of required commas
   */
  if (arrayOfWords.length > 2) commas = _.fill(new Array(arrayOfWords.length - 2), ", ");

  /**
   * array of commas with conjunction
   * only conjunction if two words and no commas
   */
  if (arrayOfWords.length >= 2) withConjunction = _.concat(commas, ` ${conjunction} `);

  /**
   * zip array of words with commas and conjunction
   */
  const combined = _.compact(_.flatten(_.zip(arrayOfWords, withConjunction)));
  /**
   * convert array with commas and conjunction to proper sentence
   */
  const sentence = combined.join("");

  return sentence;
};
