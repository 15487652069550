export * from "./modules/auth";
export * from "./modules/kpi";
export * from "./modules/helloworld";
export * from "./modules/base";
export * from "./modules/layouts";
export * from "./modules/chart";
export * from "./modules/game-plans";
export * from "./modules/analytics";
export * from "./modules/settings";
export * from "./modules/insights";
export * from "./modules/cows";
export * from "./modules/ask-ida";
export * from "./modules/events";
export * from "./modules/grammar";
export * from "./modules/overview";
export * from "./modules/unit-system";
export * from "./modules/impact-tracking";
export * from "./modules/farm-summaries";
export * from "./modules/iofc";
export * from "./modules/network";

/**
 * Loco API endpoints for Ida Enterprise
 */
export const endpoints = {
  loadPath: `https://translate-enterprise-v3.connecterra.workers.dev/?lang={{lng}}&env=${process.env.REACT_APP_LOCO_ENV}`,
  addPath: `https://create-enterprise-v3-asset.connecterra.workers.dev/?env=${process.env.REACT_APP_LOCO_ENV}`
};
