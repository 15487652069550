import { FC } from "react";
import { Space } from "antd";
import { FallOutlined, MinusOutlined, RiseOutlined } from "@ant-design/icons";

import { CorrelationType } from "../CorrelationMetric";
import styles from "./Trend.module.less";

export interface TrendProps {
  /**
   * correlation type
   */
  correlationType?: CorrelationType;
  /**
   * text
   */
  text: string;
}

/**
 * Trend of correlation (increase/decrease/zero)
 * @param correlationType
 * @param text
 */
const Trend: FC<TrendProps> = ({ correlationType, text }) => {
  const icon =
    correlationType === CorrelationType.zero ? (
      <MinusOutlined className={styles.Icon} />
    ) : correlationType === CorrelationType.negative ? (
      <FallOutlined className={styles.Icon} />
    ) : (
      <RiseOutlined className={styles.Icon} />
    );

  return (
    <Space className={styles.Space} direction="horizontal" align="center">
      {icon}
      {text}
    </Space>
  );
};

export default Trend;
