import { Typography as T } from "antd";
import { ThemedTitle } from "./typography/Title";
import { ThemedText } from "./typography/Text";

export * from "./Icon";

export { default as MockDate } from "./MockDate";
export { default as Button } from "./Button";
export { default as Digits } from "./Digits";
export { default as CtraLogo } from "./Logo";

export const Typography = {
  ...T,
  Text: ThemedText,
  Title: ThemedTitle
};
