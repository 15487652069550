import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";

import { Action, Debug } from "@ctra/utils";

import types from "./types";
import actions, { FetchChartDataPendingPayload } from "./actions";
import { Epic as EpicFactory } from "./epic";
import { ChartDataSource, CorrelationsIndexResponse, CorrelationsResponse } from "./typings";
import { AjaxError } from "rxjs/ajax";
import { EnterpriseAppState } from "../../enterprise";
import { makeAzureApiURL, withSandboxPrefix } from "../../utils/ajax";

/**
 * Fetch a chart data
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @returns {Observable<unknown>}
 */
const fetchChartData: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_CHART_DATA.pending),
    mergeMap(
      ({
        payload: { chartID, sourceType, timePeriod, farmIDs, unitSystem, filters, hash }
      }: {
        payload: FetchChartDataPendingPayload;
      }) => {
        const epic = EpicFactory.create(sourceType);

        return epic
          .makeRequest({ chartID, timePeriod, farmIDs, unitSystem, filters }, { Request, state$ })
          .pipe(
            //@ts-ignore Getting a typescript warning here. Fix later
            map<{ response: ChartDataSource }, Action>(({ response }) => {
              return actions.fetchChartData.fulfill(chartID, hash, response);
            }),
            catchError<unknown, Observable<Action>>((error: AjaxError) => {
              const {
                auth: {
                  user,
                  token: { expires, expiresIn, refreshTokenExpires, refreshTokenExpiresIn }
                }
              } = state$.value as EnterpriseAppState;

              /**
               * Build context data to send to Sentry
               * @type {{authContext: {expiresIn: number | null | undefined, refreshTokenExpiresIn: number | null | undefined, expires: string | null | undefined, refreshTokenExpires: string | null | undefined, refresh: string | null | undefined, accessToken: string | null | undefined}, requestContext: {chartID: string, sourceType: ChartDataSourceType, timePeriod: ChartTimePeriod, farmIDs: Array<FarmEntity["id"]>, unitSystem: UnitSystemParam, filters: ChartFilters | undefined}}}
               */
              const contextData = {
                requestContext: { chartID, sourceType, timePeriod, farmIDs, unitSystem, filters },
                authContext: { user, expires, expiresIn, refreshTokenExpires, refreshTokenExpiresIn }
              };

              Debug.chartApi.error(error, { contextData });

              return of(actions.fetchChartData.reject(chartID, hash, error));
            })
          );
      }
    )
  );

/**
 * Request the user preferences for the logged in user
 * @param action$
 * @param state$
 * @param Request
 */
const fetchFarmCorrelations: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_FARM_CORRELATIONS.pending),
    mergeMap<ReturnType<typeof actions.fetchFarmCorrelations.start>, Observable<Promise<unknown>>>(
      ({ payload: { farmID } }) => {
        return Request.GET(
          makeAzureApiURL(withSandboxPrefix("analytics", state$), `/correlations/farm/${farmID}`, {})()
        ).pipe(
          map<{ response: CorrelationsIndexResponse }, Action>(({ response }) =>
            actions.fetchFarmCorrelations.fulfill(farmID, response)
          ),
          catchError<unknown, Observable<Action>>((error) =>
            of(actions.fetchFarmCorrelations.reject(farmID, error))
          )
        );
      }
    )
  );

/**
 * Fetch chart correlations
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const fetchChartCorrelations: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_CHART_CORRELATIONS.pending),
    mergeMap<ReturnType<typeof actions.fetchChartCorrelations.start>, Observable<Promise<unknown>>>(
      ({ payload: { farmID, dataDescriptorID, excludedDataDescriptorIDList } }) => {
        return Request.GET(
          makeAzureApiURL(
            withSandboxPrefix("analytics", state$),
            `/correlations/farm/${farmID}/variants/${dataDescriptorID}`
          )(),
          excludedDataDescriptorIDList
            ? {
                body: {
                  excludedVariants: excludedDataDescriptorIDList.join(",")
                }
              }
            : void 0
        ).pipe(
          map<{ response: CorrelationsResponse }, Action>(({ response }) =>
            actions.fetchChartCorrelations.fulfill(farmID, response)
          ),
          catchError<unknown, Observable<Action>>((error) =>
            of(actions.fetchChartCorrelations.reject(farmID, error))
          )
        );
      }
    )
  );

export default {
  fetchChartData,
  fetchFarmCorrelations,
  fetchChartCorrelations
};
