import * as _ from "lodash";
import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import { EventState, NormalizedEventList } from "./typings";

export const initialState: EventState = {
  entities: {},
  lists: {}
};

/**
 * Reducer for events
 * @param state
 * @param action
 * @category Reducer
 */
export const reducer: Reducer<EventState> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_EVENTS.fulfilled: {
      const {
        hash,
        entities: { event },
        result
      } = payload as NormalizedEventList & { hash: string };

      return {
        ...state,
        /**
         * This allows deleted events to stay
         * @todo leave it, it should not be harmful, but address it when necessary
         */
        entities: {
          ...state.entities,
          ...event
        },
        lists: {
          ...state.lists,
          [hash]: result
        }
      };
    }
    case types.CREATE_EVENT.fulfilled: {
      const {
        hash,
        entities: { event },
        result
      } = payload as NormalizedEventList & { hash: string };

      return {
        ...state,
        entities: {
          ...state.entities,
          ...event
        },
        lists: {
          ...state.lists,
          [hash]: state.lists[hash] ? [...state.lists[hash], result] : [result]
        }
      };
    }
    case types.UPDATE_EVENT.fulfilled: {
      const {
        entities: { event }
      } = payload as NormalizedEventList;

      return {
        ...state,
        entities: {
          ...state.entities,
          ...event
        }
      };
    }
    case types.DELETE_EVENT.fulfilled: {
      const { id } = payload;

      return {
        entities: _.omit(state.entities, id),
        lists: _.mapValues(state.lists, (list) => _.without(list, id))
      };
    }
  }

  return state;
};

export default cleanup<EventState>(initialState)(reducer);
