const ns = "app/breadcrumbs";

/**
 * Drop a breadcrumb (upon entering a place in the app)
 * @category Type
 */
const DROP_BREADCRUMB = `${ns}/DROP_BREADCRUMB`;

/**
 * Drop a breadcrumb (upon entering a place in the app)
 * @category Type
 */
const UPDATE_BREADCRUMB = `${ns}/UPDATE_BREADCRUMB`;

/**
 * Pick up a breadcrumb (when leaving a place in the app)
 * @category Type
 */
const PICK_UP_BREADCRUMB = `${ns}/PICK_UP_BREADCRUMB`;

export default {
  DROP_BREADCRUMB,
  PICK_UP_BREADCRUMB,
  UPDATE_BREADCRUMB
};
