import * as _ from "lodash";
import { Nullable } from "../typings";

/**
 * Make a "ctra" prefixed list of class names to be used in JSX
 * @param {string[]} classNames
 * @return {string}
 * @example
 * ```ts
 * classname("MyComponent", "any-other-classname");
 * // "ctra-MyComponent ctra-any-other-classname"
 * ```
 */
export const classname = (...classNames: Nullable<string>[]): string => {
  /**
   * Remove the falsy values
   */
  const compact = _.compact(classNames);

  if (!_.every(compact, _.isString)) {
    throw new Error("Only string arguments are allowed here.");
  }

  return compact.join(" ");
};
