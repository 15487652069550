import { NormalizedSchema, schema } from "normalizr";

import { PenEntity, PensList } from "./typings";

const pen = new schema.Entity("pen", {});

export type NormalizedPenList = NormalizedSchema<{ Pen: PensList }, Array<PenEntity["id"]>>;

export default {
  pen
};
