import * as _ from "lodash";

import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import { SavedCards } from "./typings";

export const initialState: SavedCards = {};

/**
 * Reducer of list of saved charts
 * @param state
 * @param action
 * @category Reducer
 */
export const savedChartsReducer: Reducer<SavedCards> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_SAVED_CHARTS.fulfilled: {
      return payload as SavedCards;
    }
    case types.UPDATE_TABS.fulfilled:
    case types.UPDATE_CHARTS.fulfilled: {
      const { pageKey, savedCharts } = payload;
      return {
        ...state,
        [pageKey]: savedCharts
      };
    }
    case types.ADD_CARD.fulfilled: {
      const { pageKey, groupID, newCard } = payload;

      const updatedCards = _.map(state[pageKey], (group) =>
        _.isEqual(group.id, groupID)
          ? { ...group, cards: _.isNull(group.cards) ? [newCard] : [...group?.cards, newCard] }
          : group
      );

      return {
        ...state,
        [pageKey]: updatedCards
      };
    }
    case types.ADD_TAB.fulfilled: {
      const { pageKey, response } = payload;

      return {
        ...state,
        [pageKey]: [...state[pageKey], response]
      };
    }
  }

  return state;
};

export default cleanup<SavedCards>(initialState)(savedChartsReducer);
