import { Debug } from "@ctra/utils";
import { default as reducer, initialState } from "./reducer";

Debug.create("referralApi", "Referrals API", { prefixColor: "#884ea0", transports: true });

export { default as types } from "./types";
export { default as actions } from "./actions";
export { default as epics } from "./epics";
export { reducer, initialState };

export * from "./typings";

export default reducer;
