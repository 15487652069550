import { Debug } from "@ctra/utils";

import { default as reducer, initialState } from "./reducer";

Debug.create("accountDetailsAPI", "Account Details API", { transports: true });

export { reducer, initialState };
export * from "./typings";

export default reducer;
