import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import * as Sentry from "@sentry/react";
import { Redirect, Route, Switch } from "react-router-dom";
import TagManager from "react-gtm-module-custom-domain";

/**
 * Make sure this stays high so the CSS actually gets generated for everything.
 */
import "./index.less";

import { init as i18nInit, Subscriptions as SubscriptionsI18N, TranslationContext } from "@ctra/i18n";
import { Subscriptions, PersistGate, purgeStaleData as purgeStaleAPIData } from "@ctra/api";
import { LoadingOutlined, Row, Spin } from "@ctra/components";
import { CookieKeys, Cookies, isProduction, purgeStaleData as purgeStaleUtilsData } from "@ctra/utils";
import { GA, GAContext } from "@ctra/analytics";

import { LocalizationContext } from "@base";
import { RegisterToken, ProtectedRoute, UserContext } from "@auth";
import { Routes } from "@routes";
import { GACategories as SignupGACategories } from "@signup";

import { initSentry } from "./utils/sentry";
import { redirectURL } from "./utils/url";
import { purgeStaleData as purgeStaleAppData } from "./utils/versioning";
import * as serviceWorker from "./serviceWorker";

const SignupPage = lazy(() => import("./modules//signup/components/SignupPage"));
const SignupSuccessPage = lazy(() => import("./modules/signup/components/SignupSuccessPage"));
const ActivateUserPage = lazy(() => import("./modules/signup/components/ActivateUserPage"));
const ConfirmEmailPage = lazy(() => import("./modules/signup/components/ConfirmEmailPage"));
const App = lazy(() => import("./modules/base/components/App"));

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * Generic spinner
 */
const spinner = (
  <Row justify="center" align="middle" style={{ height: "100vh" }}>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
  </Row>
);

/**
 * Purge any stale data from the local storage and session storage
 */
purgeStaleAPIData();
purgeStaleAppData();
purgeStaleUtilsData();

const cookieConsent = Cookies.get(CookieKeys.cookieConsent);

if (cookieConsent === "optional") {
  /**
   * Google Tag Manager args
   * @type {{gtmId: string}}
   */
  const tagManagerArgs = {
    gtmId: isProduction() ? "GTM-WQTMJKLB" : "GTM-NGLVQPB2",
    customURL: "https://ua.connecterra.ai/gtm.js"
  };

  TagManager.initialize(tagManagerArgs);
}

/**
 * Show a spinner while the loco is loading
 */
ReactDOM.render(spinner, document.getElementById("root"));

/**
 * Init Sentry and Loco
 */
initSentry()
  .then(() => i18nInit(SubscriptionsI18N.endpoints))
  .then(() => {
    if (cookieConsent === "optional") {
      GA.initialize(isProduction(process.env.REACT_APP_ANALYTICS_ENV) ? "G-5J6RQPTH6D" : "G-BML74PB84C");
    }

    ReactDOM.render(
      <Provider store={Subscriptions.store}>
        <PersistGate persistor={Subscriptions.persistor}>
          <ConnectedRouter history={Subscriptions.history}>
            <RegisterToken />
            <Suspense fallback={spinner}>
              <Sentry.ErrorBoundary showDialog>
                <TranslationContext.Provider>
                  <LocalizationContext.Provider>
                    <Switch>
                      <SentryRoute
                        path={Routes.auth.login}
                        render={({ location: { search, hash } }) => {
                          const referrer = Cookies.get(CookieKeys.referrer);

                          if (referrer === "login" || referrer === "platform") {
                            /**
                             * Going back to the login page when the user
                             * was coming from the platform or the login page itself
                             * should remove the referrer as they will
                             * probably log back into the platform
                             */
                            Cookies.remove(CookieKeys.referrer);
                          } else {
                            /**
                             * When you end up on the signup in any other way (eg. after registration),
                             * then we assume you would like to be redirected to the signup app
                             * after you registered
                             */
                            Cookies.set(CookieKeys.referrer, "signup");
                          }

                          if (window) {
                            window.open(`${redirectURL}/auth/login${search}${hash}`, "_self");
                          }

                          return null;
                        }}
                      />
                      <SentryRoute
                        path={Routes.external.enterprise}
                        render={({ location: { search, hash } }) => {
                          if (window) {
                            window.open(`${redirectURL}/app/overview${search}${hash}`, "_self");
                          }

                          return null;
                        }}
                      />
                      <SentryRoute path={Routes.signup.signupSuccess}>
                        <GAContext.Provider value={{ category: SignupGACategories.activation }}>
                          <SignupSuccessPage />
                        </GAContext.Provider>
                      </SentryRoute>
                      <SentryRoute path={Routes.signup.activateUser}>
                        <GAContext.Provider value={{ category: SignupGACategories.activation }}>
                          <ActivateUserPage />
                        </GAContext.Provider>
                      </SentryRoute>
                      <SentryRoute path={Routes.signup.confirmEmail}>
                        <GAContext.Provider value={{ category: SignupGACategories.confirmEmail }}>
                          <ConfirmEmailPage />
                        </GAContext.Provider>
                      </SentryRoute>
                      <SentryRoute path={Routes.signup.index}>
                        <GAContext.Provider value={{ category: SignupGACategories.signup }}>
                          <SignupPage />
                        </GAContext.Provider>
                      </SentryRoute>
                      <ProtectedRoute
                        path={Routes.app.index}
                        redirect={Routes.auth.login}
                        render={() => (
                          <UserContext.Provider>
                            <App />
                          </UserContext.Provider>
                        )}
                      />
                      <SentryRoute
                        component={({
                          location,
                          ...rest
                        }: { location: Record<string, unknown> } & Record<string, unknown>) => (
                          <Redirect to={{ ...location, pathname: Routes.app.index }} {...rest} />
                        )}
                      />
                    </Switch>
                  </LocalizationContext.Provider>
                </TranslationContext.Provider>
              </Sentry.ErrorBoundary>
            </Suspense>
          </ConnectedRouter>
        </PersistGate>
      </Provider>,
      document.getElementById("root")
    );
  })
  .catch((error) => {
    throw error;
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
