import { createModule, from } from "../../utils";

export const gamePlan = createModule("gamePlan", {
  ...from("pageHeader"),
  /**
   * navigating between different game plan lists
   */
  navigation: from("active", "previous"),
  /**
   * user interactions with a game plan as a whole
   */
  actions: {
    delete: {
      ...from("label", "pending", "success"),
      confirm: from("title", "yes", "no")
    },
    create: "create",
    edit: "edit"
  },
  /**
   * game plan list related content
   */
  list: {
    empty: from("title", "description"),
    headers: from("title", "kpi", "current", "target", "tasks", "period", "start"),
    viewAll: "viewAll"
  },
  /**
   * task list related content
   */
  todo: {
    ...from("lastModified"),
    list: {
      empty: from("title", "description")
    },
    status: from("overdue", "todo", "done"),
    update: from("complete", "unmark", "reject")
  },
  /**
   * game plan wizard
   */
  wizard: {
    title: from("create", "update", "updateHeader", "general", "tracking", "tasks", "summary"),
    subtitle: from("general", "tracking", "tasks", "summary"),
    step: from("general", "tracking", "tasks", "summary", "next", "previous"),
    form: {
      actions: from("submit", "cancel", "addTask"),
      label: {
        ...from("title", "description", "timePeriod", "dataDescriptorID", "farmID", "goal"),
        taskList: from("label", "assignedTo", "dueDate", "optional")
      },
      validation: {
        ...from("title", "farmID", "timePeriod", "dataDescriptorID"),
        taskList: {
          label: "label"
        }
      },
      placeholder: {
        ...from("title", "description", "startEpoch", "endEpoch", "dataDescriptorID", "farmID"),
        goal: from("enabled", "disabled"),
        taskList: from("label", "assignedTo", "dueDate", "addTask")
      },
      extra: from("submitting", "description", "hasTarget", "hasNoTarget", "withoutKPI")
    }
  },
  /**
   * generic labels related to game plans (may be spread through many components)
   */
  labels: from("kpi", "current", "goal", "description", "todo", "period", "title", "deadline", "farm")
});
