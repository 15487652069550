import { createAsyncActions } from "@ctra/utils";
import types from "./types";
import { FarmEntity } from "../farms";

/**
 * Create a game plan
 * @category Action
 */
const createFarmSummary = createAsyncActions(
  types.CREATE_FARM_SUMMARY,
  (farmID: FarmEntity["id"], start, end) => ({
    farmID,
    start,
    end
  }),
  (response: Record<string, unknown>) => response,
  ({ error, statusCode }) => ({ error, statusCode })
);

export default { createFarmSummary };
