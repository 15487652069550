import { memoize, Optional } from "@ctra/utils";

import { Base } from "./Base";

import { Enterprise } from "../../../enterprise";
import { DIMGroupEntity } from "../../dim-groups";

class DimGroupSeries extends Base {
  protected entity: Optional<DIMGroupEntity>;

  constructor(id: string) {
    super(id);

    try {
      this.entity = Enterprise.entities.getDIMGroup(Enterprise.store.getState(), { id });
    } catch (e) {}
  }

  @memoize
  getField(): Optional<string> {
    return this.entity?.name;
  }
}

export { DimGroupSeries };
