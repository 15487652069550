import { schema } from "normalizr";
import * as _ from "lodash";

import { DAY, omitDeepUndefined } from "@ctra/utils";
import { ChartEntity, ChartFilterType, ChartSeriesType } from "./typings";
import { MetricVariantResponse } from "../data-dictionary";

/**
 * The chart entity which is defined in the new metric variant response
 * @type {schema.Entity<ChartEntity>}
 */
const metricDefined = new schema.Entity(
  "chart",
  {},
  {
    processStrategy: (
      { id, view }: { id: string; view: string },
      { __source: parent }: { __source: MetricVariantResponse }
    ): ChartEntity => {
      const {
        name,
        type,
        valueSettings: { minimumDataInterval },
        features: {
          projection,
          analytics: { defaultInterval, timeAdjustable, displayLagDays }
        },
        flags,
        filters
      } = parent;

      const { maxProjectionInterval } = _.defaultTo(
        projection,
        {} as NonNullable<MetricVariantResponse["features"]["projection"]>
      );

      const { isBeta, isSeriesTranslatable } = _.defaultTo(flags, {} as MetricVariantResponse["flags"]);

      return {
        id,
        view,
        title: name,
        flags: {
          isSeriesTranslatable,
          isTimeAdjustable: timeAdjustable,
          isBetaChart: isBeta,
          isMultiFarmFilterSupported: type === "farm",
          isMultiIntervalSupported: true
        },
        dataProperties: {
          maxProjectionInterval: _.defaultTo(maxProjectionInterval, null),
          minimumDisplayInterval: _.defaultTo(minimumDataInterval, defaultInterval),
          defaultDisplayInterval: _.defaultTo(defaultInterval, minimumDataInterval),
          offset: displayLagDays * DAY,
          seriesType: type as ChartSeriesType
        },
        filters: _.map(filters, (filter, filterName) => ({
          ...filter,
          name: filterName as ChartFilterType
        }))
      };
    }
  }
);

const chart = new schema.Entity(
  "chart",
  {},
  {
    idAttribute: "chartID",
    /**
     * Convert the legacy chart object to a more sustainable format.
     * @todo get rid of this fn when the back-end supports the new format
     * @param chartID
     * @param chartTitle
     * @param chartDescription
     * @param chartType
     * @param supportsMultiFarmFilter
     * @param supportsRelativeTime
     * @param supportsMultipleIntervals
     * @param dataLagOffsetDays
     * @param minimumDisplayInterval
     * @param isBetaChart
     * @param isTimeZoneSpecific
     * @param previewImageUri
     * @param filters
     * @param seriesType
     */
    processStrategy: ({
      chartID,
      chartTitle,
      chartDescription,
      chartType,
      supportsMultiFarmFilter,
      supportsRelativeTime,
      supportsMultipleIntervals,
      dataLagOffsetDays,
      minimumDisplayInterval,
      isBetaChart,
      isTimeZoneSpecific,
      previewImageUri,
      filters,
      seriesType
    }): ChartEntity =>
      omitDeepUndefined({
        id: chartID,
        title: chartTitle,
        description: chartDescription,
        view: _.camelCase(chartType),
        flags: {
          isBetaChart,
          isTimeZoneSpecific,
          isMultiFarmFilterSupported: supportsMultiFarmFilter,
          isRelativeTimeSupported: supportsRelativeTime,
          isMultiIntervalSupported: supportsMultipleIntervals
        },
        dataProperties: {
          minimumDisplayInterval,
          offset: dataLagOffsetDays * DAY,
          seriesType
        },
        previewImageUri,
        filters: _.map(filters, (filter, filterName) => ({ ...filter, name: filterName }))
      })
  }
);

export default {
  chart,
  metricDefined
};
