import { FC, createContext, useContext, useState, ComponentProps, useEffect } from "react";
import * as _ from "lodash";

import { Spin } from "antd";

interface ContextType {
  api: {
    /**
     * Toggle spinner
     * @param {string} id
     * @param {boolean} on
     * @param {Omit<React.ComponentProps<typeof Spin>, "spinning">} props
     */
    toggle: (id: string, on: boolean, props?: Omit<ComponentProps<typeof Spin>, "spinning">) => void;
  };
  on: boolean;
}

/**
 * Default context
 * @type {React.Context<ContextType>}
 */
const DefaultContext = createContext<ContextType>({
  api: {
    toggle: _.noop
  },
  on: false
});

/**
 * Spinner provider
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _SpinnerProvider: FC = ({ children }) => {
  const [queue, setQueue] = useState<Array<[string, Omit<ComponentProps<typeof Spin>, "spinning">]>>([]);
  const [on, setOn] = useState(false);
  const [props, setProps] = useState<Omit<ComponentProps<typeof Spin>, "spinning">>({});

  /**
   * Toggle spinner
   * @param {string} id
   * @param {boolean} on
   * @param {Omit<React.ComponentProps<typeof Spin>, "spinning"> | undefined} props
   */
  const toggle: ContextType["api"]["toggle"] = (id, on, props) => {
    setQueue((prev) => {
      const existing = _.find(prev, [0, id]);

      return on
        ? existing
          ? prev
          : [...prev, [id, props ?? {}]]
        : (_.without(prev, _.find(prev, [0, id])) as typeof prev);
    });
  };

  useEffect(() => {
    if (queue.length) {
      const [, props] = queue[0];

      setProps(props);
      setOn(true);
    } else {
      setOn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue.length]);

  return (
    <DefaultContext.Provider
      value={{
        api: {
          toggle
        },
        on
      }}
    >
      <Spin spinning={on} {...props}>
        {children}
      </Spin>
    </DefaultContext.Provider>
  );
};

export const SpinnerContext = {
  Provider: _SpinnerProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Use spinner hook
 * @return {ContextType}
 */
export const useSpinner = (): ContextType => useContext(DefaultContext);
