import { FC } from "react";
import { Col, Typography } from "antd";

import { WithSuperScript } from "..";

import styles from "./NoteWithSuperScript.module.less";

const { Text, Title } = Typography;

export interface NoteWithSuperScriptProps {
  /**
   * popover content to be shown
   */
  popoverContent: string;
  /**
   * Text to be displayed
   */
  text: string;
  /**
   * Title to be displayed
   */
  title?: string;
}

/**
 * Note that shows text with super script
 */
const NoteWithSuperScript: FC<NoteWithSuperScriptProps> = ({ popoverContent, text, title }) => {
  return (
    <Col className={styles.Wrapper}>
      {title && <Title className={styles.Title}>{title}</Title>}
      <Text className={styles.Text}>
        <WithSuperScript content={popoverContent} popoverPlacement="topRight">
          {text}
        </WithSuperScript>
      </Text>
    </Col>
  );
};

export default NoteWithSuperScript;
