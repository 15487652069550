import * as React from "react";
import { Layout, PageHeader, Spin, Row, Col } from "antd";

import { classname } from "@ctra/utils";

import { ContentWrapper } from "../../layout";
import ChartHeader, { ChartHeaderProps } from "../ChartHeader/ChartHeader";
import styles from "./Chart.module.less";

const { Content } = Layout;

export enum ChartVariant {
  /**
   * "headless" chart displaying only the graph
   * @type {ChartVariant.Embedded}
   */
  Embedded = "embedded",
  /**
   * V3 stylinh
   */
  V3 = "v3",
  /**
   * Mini variant
   */
  Mini = "mini"
}

export interface ChartProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title">,
    Pick<ChartHeaderProps, "extra"> {
  /**
   * Chart variant
   */
  variant?: ChartVariant;
  /**
   * Chart filtering widgets
   */
  filters?: React.ReactNode;
  /**
   * Loading state
   */
  loading?: boolean;
  /**
   * Loading text
   */
  tip?: string;
  /**
   * extra content for the chart header
   */
  extra?: React.ComponentProps<typeof PageHeader>["extra"];
  /**
   * extra content for the chart header
   */
  extension?: React.ReactNode;
  /**
   * Chart footer
   */
  footer?: React.ReactNode;
  /**
   * custom chart tag
   */
  tag?: JSX.Element;
}

/**
 * Chart container which encapsulates the Mongo Charts iframe.
 * @param {boolean} loading
 * @param {string | undefined} tip
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} filters
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | (React.ReactElement<any, string | React.JSXElementConstructor<any>> & undefined) | (Iterable<React.ReactNode> & undefined) | (React.ReactPortal & undefined) | (Iterable<ReactI18NextChild> & React.ReactElement<any, string | React.JSXElementConstructor<any>>) | (Iterable<ReactI18NextChild> & string) | (Iterable<ReactI18NextChild> & number) | (Iterable<ReactI18NextChild> & {}) | (Iterable<ReactI18NextChild> & Iterable<React.ReactNode>) | (Iterable<ReactI18NextChild> & React.ReactPortal) | (Iterable<ReactI18NextChild> & boolean) | (Iterable<ReactI18NextChild> & null) | (Iterable<ReactI18NextChild> & undefined)} children
 * @param {string} className
 * @param {ChartVariant | undefined} variant
 * @param {JSX.Element | undefined} tag
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} extra
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} extension
 * @param {any} footer
 * @param {Omit<React.PropsWithChildren<ChartProps>, "extension" | "children" | "footer" | "extra" | "variant" | "tip" | "className" | "filters" | "tag" | "loading">} rest
 * @returns {JSX.Element}
 * @constructor
 */
const Chart: React.FC<ChartProps> = ({
  loading = false,
  tip,
  filters,
  children,
  className = "",
  variant,
  tag,
  extra,
  extension,
  footer = null,
  ...rest
}) => {
  return variant === ChartVariant.Mini ? (
    <Layout className={styles.Mini} {...rest}>
      <Spin spinning={loading} tip={tip}>
        {children}
      </Spin>
    </Layout>
  ) : (
    <Layout className={classname("ctra-chart-wrapper", styles.Chart, className)} {...rest}>
      <Spin spinning={loading} tip={tip}>
        {variant === ChartVariant.V3 && (
          <Col className={styles.Header}>
            <ChartHeader extra={extra} />
          </Col>
        )}
        {filters && <PageHeader className={styles.Filter}>{filters}</PageHeader>}
        <Row gutter={[0, 16]} className={styles.Content}>
          <Col span={24} lg={extension ? 18 : 24}>
            <Content className="ctra-chart-content">{children}</Content>
          </Col>
          {extension && (
            <Col span={24} lg={6}>
              <ContentWrapper className="ctra-chart-extension" padded={false}>
                {extension}
              </ContentWrapper>
            </Col>
          )}
        </Row>
        {footer}
      </Spin>
    </Layout>
  );
};

export default Chart;
