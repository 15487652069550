import { Component } from "react";
import GA from "react-ga4";

type ComponentTrackerProps = {
  category: string;
  action: string;
  label?: string;
  value?: number;
};

/**
 * Drop in tracker component. Useful for tracking components that
 * does not require user interaction
 * Since this tracker doesn't require user interaction, nonInteraction is set to true to avoid skewing bounce rate data
 * ```ts
 * <ComponentTracker category={"category"} action={"render"} label={"label"} />
 * ```
 */
export class ComponentTracker extends Component<ComponentTrackerProps> {
  componentDidMount(): void {
    GA.event({ ...this.props, nonInteraction: true });
  }

  render(): null {
    return null;
  }
}
