import { createModule, from, makeAsset } from "../../utils";

export const settings = createModule("settings", {
  pageIntro: from("title", "description"),
  type: from("insight", "help", "account"),
  navigation: from("general", "insights", "notifications", "account", "personal", "password", "users"),
  insights: {
    ...from("title", "description"),
    breadcrumbs: from("add", "edit"),
    farmList: {
      th: from("farmName", "insightsEnabled"),
      td: from("insightsEnabled")
    },
    insightList: {
      ...from("title"),
      empty: from("title", "description", "enable"),
      actions: from("addNew", "back"),
      th: from("typeName", "range", "sensitivity"),
      td: from("range", "noRange")
    },
    kpiInsightForm: {
      labels: {
        ...from("dataDescriptorID", "thresholds", "pen", "herdGroup"),
        range: from("primary", "secondary", "average"),
        sensitivity: {},
        input: from("lower", "upper")
      },
      placeholders: from("dataDescriptorID", "pen", "herdGroup"),
      actions: {
        ...from("submit", "cancel", "disable"),
        disable: {
          ...from("label"),
          confirm: from("title", "yes", "no")
        }
      }
    }
  },
  accountDetails: {
    ...from("title", "description"),
    user: from("name", "email", "locale")
  },
  notifications: {
    ...from("title", "description", "feature"),
    mapping: from("title", "description", "popover"),
    groupMapping: from("category", "categoryDescription", "popover"),
    appDownload: from("title", "description", "download")
  },
  dataIntegrations: {
    ...from("title", "description"),
    alerts: {
      selectFarm: from("title", "description")
    },
    dataType: makeAsset,
    entry: {
      labels: from("name", "description", "lastUpdated"),
      name: makeAsset,
      description: makeAsset
    }
  },
  details: from("title", "description"),
  password: from("title", "description"),
  users: {
    ...from("title", "description"),
    alerts: {
      selectFarm: from("title", "description"),
      referral: from("title", "description")
    },
    actions: {
      ...from("invite"),
      delete: from("error", "success"),
      confirmDelete: from("ok", "cancel", "text")
    },
    table: {
      ...from("empty"),
      columns: from("name", "email", "role", "org", "invitation"),
      values: {
        status: makeAsset
      }
    },
    form: {
      ...from("title", "submit", "cancel", "error", "success"),
      role: makeAsset,
      validation: from(
        "firstNameRequired",
        "lastNameRequired",
        "emailRequired",
        "roleRequired",
        "consentRequired"
      ),
      labels: from("consent", "readMore", "firstName", "lastName", "email", "org", "role"),
      placeholders: from("firstName", "lastName", "email", "org", "role")
    }
  },
  logout: "logout"
});
