import { version } from "../../package.json";

/**
 * Get the version from the package.json
 * @return {number}
 */
export const getVersion = () => {
  return parseFloat(version.replace(/\./g, ""));
};

/**
 * Get the storage key prefix
 * @param {boolean} persist
 * @return {`ctra.i18n.v${number}`}
 */
export const getStorageKey = ({ persist }: { persist: boolean } = { persist: false }) => {
  return `${persist ? "persist:" : ""}ctra.i18n.v${getVersion()}`;
};
