import { FarmEntity } from "../farms";
import { UserEntity } from "../user";

import { Nullable } from "@ctra/utils";
import { InsightTypeEntity } from "../insights";
import { NormalizedSchema } from "normalizr";
import { PenEntity } from "../pens";
import { HerdGroupEntity } from "../herd-groups";

interface Dictionary<T> {
  [index: string]: T;
}

export enum EventScope {
  cow = "Cow",
  farm = "Farm",
  herdGroup = "HerdGroup",
  pen = "Pen"
}

export enum EventSourceType {
  genericInsight = "GenericInsight",
  genericLabel = "GenericLabel",
  idaQnA = "IdaQnA",
  gamePlanTask = "GamePlanTask",
  customEvent = "CustomEvent",
  aiModel = "AIModel"
}

export enum EventCategory {
  feed = "Feed",
  farmEquipment = "FarmEquipment",
  housing = "Housing",
  management = "Management",
  other = "Other",
  weather = "Weather"
}

/**
 * @todo review if this is right
 */
export enum EventStatus {
  analysing = "Analysing",
  processing = "Processing",
  completed = "Completed",
  done = "Done",
  initial = "Initial",
  new = "New"
}

/**
 * Expected direction of change in the behaviour/KPI
 */
export enum Direction {
  increase = "increase",
  decrease = "decrease",
  stagnate = "stagnate"
}

export enum EventOrigin {
  automated = "automated",
  manual = "manual"
}

interface EventSourceReference {
  id: number;
  type: EventSourceType;
}

interface EventScopeReference {
  ids: number[];
  type: EventScope;
}

export interface EventListQueryParams {
  categories: string; // comma separated string eg. Feed, Housing etc.
  endDate: number;
  source: EventSourceType;
  startDate: number;
  exclude?: Array<EventSourceType>;
}

export interface EventContext extends Record<string, unknown> {
  sourceState: string;
  relatedAnnotationID?: EventEntity["id"];
}

export interface GenericInsightEventContext extends EventContext {
  insightTypeID: InsightTypeEntity["id"];
  insightResolutionIds: Array<string>; // comma separated string of resolution ids
}

/**
 * Event Source entity coming from BE
 */
export interface EventSource {
  id: string;
  category: EventCategory;
  createdAt: string;
  title: Nullable<string>;
  description: string;
  endAt: string;
  farmId: FarmEntity["id"];
  scope: EventScopeReference;
  source: EventSourceReference;
  startAt: string;
  state: string;
  subCategory: string;
  context: Record<string, unknown>;
}

/**
 * Event entity with proper context
 */
export interface EventEntity<C extends EventContext = EventContext> extends EventSource {
  context: C;
}

/**
 * Event entity with proper context
 */
export interface ExtendedEventEntity<C extends EventContext = EventContext> extends EventSource {
  context: C;
  children: Array<EventEntity>;
}

/**
 * A list of event entities
 */
export type EventList = Record<EventEntity["id"], EventEntity>;

/**
 * Event list including children
 */
export type ExtendedEventList = Record<EventEntity["id"], ExtendedEventEntity>;

/**
 * Grouped event list
 */
export type GroupedEventList = Dictionary<[ExtendedEventEntity, ...ExtendedEventEntity[]]>;

/**
 * Create/edit timeline event form values
 */
export interface TimelineEventFormValues {
  id?: EventEntity["id"];
  farmID: FarmEntity["id"];
  userID: UserEntity["id"];
  category: Nullable<EventCategory>;
  subCategory: Nullable<string>;
  scope: Nullable<EventScope>;
  title: Nullable<string>;
  description: Nullable<string>;
  startTs: string; // The date field will always be populated with the current date and will never be null.
  endTs: Nullable<string>;
  penNameList: Array<PenEntity["name"] | HerdGroupEntity["id"]>;
}

export type NormalizedEvent = NormalizedSchema<
  {
    event: EventList;
  },
  EventEntity["id"]
>;

export type NormalizedEventList = NormalizedSchema<
  {
    event: EventList;
  },
  Array<EventEntity["id"]>
>;

export interface EventState {
  entities: Record<EventEntity["id"], EventEntity>;
  lists: Record<string, Array<EventEntity["id"]>>;
}
