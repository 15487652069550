import { Component } from "react";
import { connect } from "react-redux";
import { v4 } from "uuid";

import { Action } from "@ctra/utils";

import actions from "../../actions";

type BreadcrumbProps = {
  title: string;
  path?: string;
  drop: (id: string, title: string, path?: string) => void;
  update: (id: string, newTitle: string, newPath?: string) => void;
  pick: (id: string) => void;
};

class B extends Component<BreadcrumbProps> {
  id: string = v4();

  componentDidMount() {
    const { drop, title, path } = this.props;

    drop(this.id, title, path);
  }

  componentDidUpdate() {
    const { update, title, path } = this.props;

    update(this.id, title, path);
  }

  componentWillUnmount() {
    const { pick } = this.props;

    pick(this.id);
  }

  render() {
    return null;
  }
}

/**
 * Invisible breadcrumb which you may drop at different routes in your app.
 * On mount and onmount, it will manage the breadcrumb state in Redux.
 * @todo consider moving it to @ctra/utils as it will come handy in other apps too
 * @example
 * ```ts
 * <Breadcrumb title="Where I am right now" />
 * ```
 */
export const Breadcrumb = connect(null, (dispatch) => ({
  /**
   * Drop the breadcrumb
   * @param id
   * @param title
   * @param path
   */
  drop: (id: string, title: string, path?: string): Action =>
    dispatch(actions.dropBreadcrumb(id, title, path)),
  /**
   * Update the breadcrumb title
   * @param id
   * @param newTitle
   * @param newPath
   */
  update: (id: string, newTitle: string, newPath?: string): Action =>
    dispatch(actions.updateBreadcrumb(id, newTitle, newPath)),
  /**
   *
   * Pick up the breadcrumb
   * @param id
   */
  pick: (id: string): Action => dispatch(actions.pickUpBreadcrumb(id))
}))(B);
