import { createModule, from, makeAsset } from "../../utils";

export const events = createModule("events", {
  entity: {
    ...from("noDescription"),
    title: makeAsset,
    actions: from("edit", "delete", "download"),
    description: makeAsset,
    ctaText: makeAsset,
    status: makeAsset
  },
  empty: from("title", "description", "action"),
  list: {
    ...from("header", "loading", "showMore", "show"),
    headers: from("title", "addEvent"),
    entry: {
      meta: from("source"),
      cta: {
        ...from("ask"),
        remove: from("confirm", "yes", "no")
      }
    }
  },
  widget: {
    title: "title"
  },
  page: {
    ...from("title", "description")
  }
});

export const timeline = createModule("timeline", {
  drawer: from("title", "toggle", "tooltip"),
  filter: {
    origin: makeAsset,
    type: makeAsset,
    group: from("origin", "type")
  },
  createEvent: {
    ...from("summary", "submit", "cancel", "loading", "updating", "success", "fail", "trackingInfo"),
    dialog: from("title"),
    steps: {
      title: makeAsset,
      rollback: null
    },
    labelPicker: {
      ...from("title", "other", "placeholder"),
      custom: from("placeholder", "newLabel"),
      category: makeAsset,
      label: makeAsset
    },
    datePicker: {
      ...from("placeholder", "inactive"),
      options: from("date", "range")
    },
    scopeSelector: from("label"),
    herdGroupSelector: from("label", "placeholder", "submit"),
    scope: makeAsset,
    description: from("label", "placeholder", "skip", "toggle", "submit", "empty")
  }
});
