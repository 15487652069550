import * as _ from "lodash";

import { Debug, Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import actions from "./actions";
import { UserEntity, UserListState } from "./typings";

export const initialState: UserListState = {};

/**
 * Reducer for user list (coming as "users of partner")
 * @param state
 * @param action
 */
export const reducer: Reducer<UserListState> = (state, action) => {
  const { type } = action;

  switch (type) {
    case types.FETCH_USER.fulfilled: {
      const {
        payload: {
          entities: { user },
          result
        }
      } = action as ReturnType<typeof actions.fetchUser.fulfill>;

      const { farmAccess } = user[result];

      if (!farmAccess) {
        Debug.userAapi.error("No farm access for user, using -1 (generic) as farm ID", user[result]);
      }

      const farmId = _.defaultTo(farmAccess?.farmId, -1);

      return {
        ...state,
        [farmId]: {
          ...state[farmId],
          ...user
        }
      };
    }
    case types.FETCH_USER_LIST.fulfilled: {
      const {
        payload: {
          farmID,
          entities: { user }
        }
      } = action as ReturnType<typeof actions.fetchUserList.fulfill>;

      return {
        ...state,
        [farmID]: _.mergeWith({}, state[farmID], user, (objValue: UserEntity, srcValue: UserEntity) => ({
          ..._.omitBy(objValue, _.isNil),
          ..._.omitBy(srcValue, _.isNil)
        }))
      };
    }
  }

  return state;
};

export default cleanup<UserListState>(initialState)(reducer);
