import { createModule, from } from "../../utils";

/**
 * Generic UI content
 * @type {I18NModule<{table: FromArray<[string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string]>}>}
 */
export const ui = createModule("ui", {
  ...from("new", "beta", "na", "self"),
  cookies: from("title", "text", "link", "accept"),
  loaders: from("longRequest", "renewToken", "acceptInvite", "loggingIn", "reload"),
  table: from(
    "filterTitle",
    "filterConfirm",
    "filterReset",
    "filterEmptyText",
    "filterCheckall",
    "filterSearchPlaceholder",
    "emptyText",
    "selectAll",
    "selectInvert",
    "selectNone",
    "selectionAll",
    "sortTitle",
    "expand",
    "collapse",
    "triggerDesc",
    "triggerAsc",
    "cancelSort"
  )
});

/**
 * Module for navigation related content
 * @todo don't pollute, this is not a trash for content without a category
 */
export const navigation = createModule("navigation", {
  main: from(
    "overview",
    "insights",
    "farms",
    "gamePlans",
    "iofc",
    "farmSummaries",
    "docs",
    "fetchingData",
    "analytics",
    "settings",
    "myConnections",
    "helpCenter",
    "support",
    "allFarms",
    "selectFarm",
    "backToAllFarms",
    "more",
    "timeline",
    "collapse",
    "logout"
  ),
  fab: from("compareCharts", "newGamePlan", "activateInsight", "addEvent"),
  userMenu: from("logout"),
  language: from("label"),
  unitSystem: from("label", "imperial", "metric"),
  demo: from("on", "off")
});

/**
 * Generic free version indicator
 */
export const free = createModule("free", {
  text: "text",
  popover: "popover",
  title: "title"
});

/**
 * Error handling
 */
export const errors = createModule("errors", {
  notFound: from("title", "subtitle", "action"),
  jsError: from("title", "subtitle", "action")
});
