import { Epic, StateObservable, ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";
import { AjaxResponse } from "rxjs/ajax";
import { of, Observable } from "rxjs";
import { Action } from "redux";
import _ from "lodash";

import { Debug, isProduction } from "@ctra/utils";

import { EnterpriseAppState } from "../../enterprise";
import { makeAzureApiURL, Request as R } from "../../utils/ajax";
import actions from "./actions";
import types from "./types";
import { FeedbackPayload } from "./typings";

/**
 * Send user feedback
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const sendFeedback: Epic = (
  action$: Observable<Action>,
  state$: StateObservable<EnterpriseAppState>,
  { Request }: { Request: typeof R }
): Observable<Action> =>
  action$.pipe(
    ofType(types.SEND_FEEDBACK.pending),
    mergeMap<ReturnType<typeof actions.sendFeedback.start>, Observable<Action>>(({ payload }) =>
      Request.POST<FeedbackPayload>(
        `https://ctra-notes-${isProduction() ? "prod" : "staging"}.azurewebsites.net/notes`,
        {
          body: payload as unknown as Record<string, unknown>
        }
      ).pipe(
        map<AjaxResponse<any>, Action>(({ response }) => {
          return actions.sendFeedback.fulfill(response);
        }),
        catchError<Action, Observable<Action>>(({ response }) => {
          const error = _.get(response, ["error"]);
          const statusCode = _.get(response, ["statusCode"]);
          const details = _.get(response, ["details"]);

          if (error) {
            Debug.iofcApi.error(error);
          }

          return of(actions.sendFeedback.reject(error, statusCode, details));
        })
      )
    )
  );

export default {
  sendFeedback
};
