import React, { ComponentProps, ComponentType } from "react";
import * as _ from "lodash";
import { Space, Select } from "antd";
import { LabeledValue } from "antd/es/select";

import { DefaultTFuncReturn } from "@ctra/i18n";
import { classname, Nullable } from "@ctra/utils";

import styles from "./MultiDropdown.module.less";
import { Icon } from "../../../../elements";

export type MultiDropdownProps<T = string | number> = Omit<
  ComponentProps<typeof Select>,
  "onSelect" | "onChange"
> & {
  /**
   * max number of options to allow, a new selection replaces the oldest one
   */
  limit?: Nullable<number>;
  /**
   * placeholder
   */
  placeholder?: string | React.ReactNode;
  /**
   * possible values
   */
  values: Array<T>;
  /**
   * selected values
   */
  selection: Array<T>;
  /**
   * Handler to call when the user clicks on the list items
   */
  onChange: (selection: Array<T>) => void;
  /**
   * Get a human readable label from the value
   * @param {string} key
   */
  getLabel?: (key: T) => T | DefaultTFuncReturn;
  /**
   * open state (mainly for unit tests)
   */
  open?: boolean;
  /**
   * filter options by input
   */
  filterOption?: ComponentProps<typeof Select>["filterOption"];
  /**
   * disabled
   */
  disabled?: ComponentProps<typeof Select>["disabled"];
  /**
   * Icon
   */
  icon?: ComponentType;
};

/**
 * Dropdown with simple API
 */
function MultiDropdown<V extends string | number>({
  limit,
  values,
  selection,
  placeholder,
  onChange,
  getLabel = (key) => key,
  open,
  icon,
  className,
  ...rest
}: React.PropsWithChildren<MultiDropdownProps<V>>): React.ReactElement {
  /**
   * Handle change of selection
   * @param state
   */
  const handleChange = (state: Array<LabeledValue>) => {
    const values = _.map(limit ? _.takeRight(state, limit) : state, "value");

    onChange(values as Array<V>);
  };

  return (
    <Space direction="horizontal" className={icon ? styles.Space : ""}>
      <Icon component={icon} className={styles.Icon} />
      <Select
        open={open}
        size="large"
        className={classname("ctra-charts-Dropdown", styles.Select, className)}
        // @ts-ignore
        value={_.map(selection, (value) => ({ value, label: getLabel(value) }))}
        style={{ minWidth: 200 }}
        allowClear
        placeholder={placeholder}
        showArrow
        labelInValue
        maxTagCount={3}
        mode="multiple"
        onChange={handleChange}
        {...rest}
      >
        {_.without(values, ...selection).map((value) => (
          <Select.Option key={value} value={value}>
            {getLabel(value)}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
}

export default MultiDropdown;
