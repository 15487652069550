import { createModule, from } from "../../utils";

/**
 * Error handling
 */
export const cows = createModule("cows", {
  entity: {
    status: (copyKey: string, status: string) => `${copyKey}-${status}`,
    ...from("lactationNumber", "dim", "pen")
  }
});
