import { Action } from "@ctra/utils";
import types from "./types";

/**
 * Log out
 * @category Action
 */
const logout = (): Action => ({
  type: types.LOGOUT
});

/**
 * Reset session (without logging out)
 * @return {Action}
 */
const reset = (): Action => ({
  type: types.RESET
});

export default {
  logout,
  reset
};
