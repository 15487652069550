import * as _ from "lodash";
import { CamelCasedPropertiesDeep } from "type-fest";

/**
 * function recursively converts a nested object's properties to camel case
 * returns a deeply camel cased object
 * @param obj
 */
export const camelCaseDeep = (obj: object): CamelCasedPropertiesDeep<Record<string, unknown>> =>
  _.transform(obj, (acc: Record<string, unknown>, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);
    acc[camelKey] = _.isObject(value) ? camelCaseDeep(value) : value;
  });

/**
 * function that recursively converts nested object keys to lowercase
 * @param obj
 */
export const lowerCaseDeep = (obj: object) => {
  if (_.isObject(obj)) {
    const newObj: Record<string, unknown> = {};

    _.forOwn(obj, (value, key) => {
      const newKey = key.toLowerCase();
      newObj[newKey] = lowerCaseDeep(value);
    });

    return newObj;
  } else {
    return obj;
  }
};

/**
 * Convert specific keys in a nested object to lower case
 * @param obj
 * @param keysToConvert
 * @returns
 */
export const convertKeysToLowerCase = (
  obj: Record<string, unknown>,
  keysToConvert: string[]
): Record<string, unknown> => {
  if (_.isObject(obj)) {
    const newObj: Record<string, unknown> = {};
    for (const [key, value] of _.entries(obj)) {
      const updatedKey = _.includes(keysToConvert, key) ? _.lowerCase(key) : key;
      newObj[updatedKey] = convertKeysToLowerCase(value as Record<string, unknown>, keysToConvert);
    }
    return newObj;
  }
  return obj;
};
