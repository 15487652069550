import { Observable, of } from "rxjs";
import { mergeMap, map, tap, catchError } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";
import * as _ from "lodash";
import * as Yup from "yup";

import { Action, camelize } from "@ctra/utils";
import { Debug } from "@ctra/utils";

import farmActions from "../farms/actions";
import { makeApiURL, makeAzureApiURL } from "../../utils/ajax";

import types from "./types";
import actions from "./actions";
import { AuthSource, RenewTokenValues } from "./typings";

/**
 * A hand created token request payload
 * as we cannot use Request here due to the headers.
 * @todo set up proper URL composing
 */
const requestConfig = {
  method: "POST",
  crossDomain: true,
  headers: {
    "content-type": "application/json"
  }
};

/**
 * Login request
 */
const tokenRequestConfig = {
  ...requestConfig,
  url: makeAzureApiURL("auth", "/token")()
};

/**
 * Renew token request
 */
const renewTokenRequestConfig = {
  ...requestConfig,
  url: makeAzureApiURL("auth", "/token-renewal")()
};

const getSsoTokenRequestConfig = ({ refreshToken, provider }: Partial<RenewTokenValues>) => ({
  ...requestConfig,
  method: "GET",
  url: makeAzureApiURL(
    "auth",
    "/sso/token/?provider=<%= provider %>&refreshToken=<%= refreshToken %>"
  )({ refreshToken: refreshToken ? encodeURIComponent(refreshToken) : null, provider })
});

/**
 * Forgot password request
 */
const forgotPasswordRequestConfig = {
  ...requestConfig,
  url: makeApiURL("https://classic-auth<%= env %>.connecterra.ai/Account/ForgotPassword", {
    dev: "staging"
  })({})
};

/**
 * Reset password config
 */
const passwordResetRequestConfig = {
  ...requestConfig,
  url: makeApiURL("https://classic-auth<%= env %>.connecterra.ai/Account/ResetPassword", {
    dev: "staging"
  })({})
};

const emailSchema = Yup.string().email();

/**
 * Request a bearer token from the BE
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} ajax
 * @param {any} Request
 * @param {any} localStorage
 * @return {Observable<unknown>}
 */
const requestToken: Epic = (
  action$: Observable<any>,
  state$: Observable<any>,
  { ajax, Request, localStorage }: any
): Observable<unknown> =>
  action$.pipe(
    ofType(types.REQUEST_TOKEN.pending),
    mergeMap<ReturnType<typeof actions.requestToken.start>, Observable<Promise<unknown>>>(
      ({ payload: { username, impersonating, password, rememberMe } }) => {
        return ajax({
          ...tokenRequestConfig,
          body: {
            grant_type: "password",
            username: _.isEmpty(impersonating) ? username : [username, impersonating].join(","),
            rememberMe,
            password
          }
        }).pipe(
          tap<{ response: AuthSource }>(({ response }) => {
            Request.registerToken(response.token);
          }),
          map<{ response: AuthSource }, Action>(({ response }) => {
            const impersonatedUser = _.first(impersonating);
            let isImpersonating = false;

            try {
              isImpersonating = !!emailSchema.validateSync(impersonatedUser);
            } catch (e) {
              console.info("It seems like you are not impersonating any users, no problem!");
            }

            return actions.requestToken.fulfill(response, isImpersonating ? impersonatedUser : void 0);
          }),
          catchError<unknown, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);

            if (error) {
              Debug.authApi.error(error);
            }

            return of(actions.requestToken.reject(error, statusCode));
          })
        );
      }
    )
  );

/**
 * Renew existing token
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} ajax
 * @param {any} Request
 * @param {any} localStorage
 * @return {Observable<unknown>}
 */
const renewToken: Epic = (action$, state$, { ajax, Request }) =>
  action$.pipe(
    ofType(types.RENEW_TOKEN.pending),
    mergeMap<ReturnType<typeof actions.renewToken.start>, Observable<Promise<unknown>>>(
      ({ payload: { currentToken, refreshToken, provider } }) =>
        ajax({
          ...(currentToken ? renewTokenRequestConfig : getSsoTokenRequestConfig({ refreshToken, provider })),
          ...(currentToken
            ? {
                body: {
                  currentToken,
                  refreshToken
                }
              }
            : {})
        }).pipe(
          tap<{ response: AuthSource }>(({ response }) => {
            Request.registerToken(response.token);
          }),
          map<{ response: AuthSource }, Action>(({ response }) => {
            return actions.renewToken.fulfill(response);
          }),
          catchError<unknown, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);
            const statusCode = _.get(response, ["statusCode"]);

            if (error) {
              Debug.authApi.error(error);
            }

            return of(actions.renewToken.reject(error, statusCode));
          })
        )
    )
  );

/**
 * Report forgotten password
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} ajax
 * @return {Observable<unknown>}
 */
const forgotPassword: Epic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(types.REPORT_FORGOT_PASSWORD.pending),
    mergeMap<ReturnType<typeof actions.reportForgotPassword.start>, Observable<Promise<unknown>>>(
      ({ payload: { username } }) =>
        ajax({
          ...forgotPasswordRequestConfig,
          body: {
            Email: username
          }
        }).pipe(
          map<{ response: Record<string, unknown> }, Action>(({ response }) =>
            actions.reportForgotPassword.fulfill(camelize(response))
          ),
          catchError<unknown, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);

            if (error) {
              Debug.authApi.error(error);
            }

            return of(actions.reportForgotPassword.reject(error));
          })
        )
    )
  );

/**
 * Report forgotten password
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} ajax
 * @return {Observable<unknown>}
 */
const resetPassword: Epic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(types.RESET_PASSWORD.pending),
    mergeMap<ReturnType<typeof actions.resetPassword.start>, Observable<Promise<unknown>>>(
      ({ payload: { userId, password, otp } }) =>
        ajax({
          ...passwordResetRequestConfig,
          body: {
            UserId: userId,
            Password: password,
            ConfirmPassword: password,
            Code: otp
          }
        }).pipe(
          map<{ response: Record<string, unknown> }, Action>(({ response }) =>
            actions.resetPassword.fulfill(camelize(response))
          ),
          catchError<unknown, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);

            if (error) {
              Debug.authApi.error(error);
            }

            return of(actions.resetPassword.reject(error));
          })
        )
    )
  );

/**
 * Register user based on referral
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} ajax
 * @return {Observable<unknown>}
 */
const registerUser: Epic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(types.REGISTER_USER.pending),
    mergeMap<ReturnType<typeof actions.registerUser.start>, Observable<Promise<unknown>>>(
      ({ payload: { opportunityID, confirmPassword, ...rest } }) =>
        ajax({
          ...requestConfig,
          method: "PUT",
          headers: {
            "content-type": "application/json"
          },
          url: makeAzureApiURL(
            "salesforce",
            "/opportunities/<%= opportunityID %>"
          )({
            opportunityID
          }),
          body: rest
        }).pipe(
          map<{ response: Record<string, unknown> }, Action>(({ response }) =>
            actions.registerUser.fulfill(camelize(response))
          ),
          catchError<unknown, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);

            if (error) {
              Debug.signupApi.error(error);
            }

            return of(actions.registerUser.reject(error));
          })
        )
    )
  );

/**
 * Accept an invite
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const acceptInvite: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.ACCEPT_INVITE.pending),
    mergeMap<ReturnType<typeof actions.acceptInvite.start>, Observable<Promise<unknown>>>(
      ({ payload: { opportunityID } }) =>
        Request.PUT(
          makeAzureApiURL(
            "salesforce",
            "/opportunities/<%= opportunityID %>"
          )({
            opportunityID
          }),
          {
            body: {}
          }
        ).pipe(
          mergeMap<{ response: Record<string, unknown> }, Observable<Action>>(({ response }) => {
            /**
             * Fulfill the invite and refetch the farm list
             */
            return of(actions.acceptInvite.fulfill(response), farmActions.fetchFarmList.start("enterprise"));
          }),
          catchError<unknown, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);

            if (error) {
              Debug.signupApi.error(error);
            }

            return of(actions.acceptInvite.reject(error));
          })
        )
    )
  );

/**
 * Sign up user via the user sign up form
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const signupUser: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.SIGN_UP_USER.pending),
    mergeMap<ReturnType<typeof actions.signupUser.start>, Observable<Promise<unknown>>>(({ payload }) =>
      Request.POST(makeAzureApiURL("accounts", "/registrations/new")(), {
        body: payload
      }).pipe(
        map<{ response: Record<string, unknown> }, Action>(({ response }) =>
          actions.signupUser.fulfill(camelize(response))
        ),
        catchError<unknown, Observable<Action>>(({ response }) => {
          const error = _.get(response, ["error"]);
          const details = _.get(response, ["details"]);
          let formattedDetails: string | undefined = void 0;

          if (_.isString(details)) {
            formattedDetails = details;
          } else if (_.isPlainObject(details)) {
            const values = _.values(details);

            formattedDetails = _.defaultTo(_.first(_.flatten(values)), "");
          }

          if (error) {
            Debug.signupApi.error(error);
          }

          return of(actions.signupUser.reject(formattedDetails || error));
        })
      )
    )
  );

/**
 * Activate user from email link
 * @param action$
 * @param state$
 * @param ajax
 */
const activateUser: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.ACTIVATE_USER.pending),
    mergeMap<ReturnType<typeof actions.activateUser.start>, Observable<Promise<unknown>>>(({ payload }) =>
      Request.POST(makeAzureApiURL("accounts", `/registrations/activation`)(), {
        body: payload
      }).pipe(
        map<{ response: Record<string, unknown> }, Action>(({ response }) =>
          actions.activateUser.fulfill(camelize(response))
        ),
        catchError<unknown, Observable<Action>>(({ response }) => {
          const error = _.get(response, ["error"]);

          if (error) {
            Debug.signupApi.error(error);
          }

          return of(actions.activateUser.reject(error));
        })
      )
    )
  );

/**
 * Request activation and send email to provided address with account activation token
 * @param {Observable<any>} action$
 * @param {StateObservable<any>} state$
 * @param {any} Request
 * @return {Observable<unknown>}
 */
const requestActivation: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.REQUEST_ACTIVATION.pending),
    mergeMap<ReturnType<typeof actions.requestActivation.start>, Observable<Promise<unknown>>>(
      ({ payload: { email } }) =>
        Request.POST(makeAzureApiURL("accounts", `/registrations/activation-request?email=${email}`)()).pipe(
          map<{ response: Record<string, unknown> }, Action>(({ response }) =>
            actions.requestActivation.fulfill(camelize(response))
          ),
          catchError<unknown, Observable<Action>>(({ response }) => {
            const error = _.get(response, ["error"]);

            if (error) {
              Debug.signupApi.error(error);
            }

            return of(actions.requestActivation.reject(error));
          })
        )
    )
  );

export default {
  forgotPassword,
  requestToken,
  resetPassword,
  registerUser,
  renewToken,
  acceptInvite,
  signupUser,
  activateUser,
  requestActivation
};
