import { ComponentProps, FC, ReactNode } from "react";
import { Row, Col, Badge, Tag, Typography, Grid } from "antd";
import { RightOutlined, CalendarOutlined } from "@ant-design/icons";

import { Nullable, classname } from "@ctra/utils";

import { Icon, Barn } from "../../../../elements";
import MobileViewRow from "../../../farm-overview/MobileViewRow";
import styles from "./ListItem.module.less";

const { Text } = Typography;

export interface ListItemProps extends ComponentProps<typeof Row> {
  /**
   * icon reference
   */
  icon: ReactNode;
  /**
   * insight title
   */
  title: string;
  /**
   * insight resolution / validation
   */
  feedback?: ReactNode;
  /**
   * farm name
   */
  farm: string;
  /**
   * time period component
   */
  period: ReactNode;
  /**
   * group e.g per pen
   */
  group: Nullable<string>;
  /**
   * click handler
   */
  onClick: () => void;
  /**
   * handle view to toggle seen/unseen
   */
  onView?: () => void;
  /**
   * tell whether the insight is unseen
   */
  unseen: boolean;
}

/**
 * Mobile view of the insight list item
 * @constructor
 */
const MobileView: FC<ListItemProps> = ({
  icon,
  title,
  feedback,
  farm,
  onClick,
  onView,
  unseen,
  period,
  group,
  className,
  ...rest
}) => {
  return (
    <Row
      wrap={false}
      align="middle"
      className={classname("ctra-insightList-entry--mobile", styles.ListItem, styles.Col, className)}
      gutter={[16, 16]}
      onClick={onClick}
      {...rest}
    >
      <Col>
        <Row gutter={[16, 16]}>
          <MobileViewRow hasBadge={unseen} icon={icon}>
            <Text strong className={styles.Title}>
              {title}
            </Text>
          </MobileViewRow>
          {feedback && <MobileViewRow>{feedback}</MobileViewRow>}
          <MobileViewRow icon={<Icon component={Barn} />}>
            <Tag color="purple">{farm}</Tag>
          </MobileViewRow>
          <MobileViewRow icon={<CalendarOutlined />}>{period}</MobileViewRow>
        </Row>
      </Col>
      <Col>
        <Text type="secondary" className={styles.Caret}>
          <RightOutlined />
        </Text>
      </Col>
    </Row>
  );
};

/**
 * Insight list item component
 * @param {React.PropsWithChildren<ListItemProps>} props
 * @return {JSX.Element}
 * @constructor
 */
const ListItem: FC<ListItemProps> = (props) => {
  const { icon, title, feedback, farm, onClick, onView, unseen, period, group, className, ...rest } = props;
  const { md } = Grid.useBreakpoint();

  return md ? (
    <Row
      wrap={false}
      align="middle"
      className={classname("ctra-insightList-entry", styles.ListItem, className)}
      gutter={[0, 0]}
      onClick={onClick}
      {...rest}
    >
      <Col className={styles.Col} span={8}>
        <Row align="middle" wrap={false} gutter={[16, 0]}>
          <Col>
            <Badge className={classname(unseen ? null : styles.Seen)} status="error" />
          </Col>
          <Col className={styles.Icon}>{icon}</Col>
          <Col>
            <Text className={styles.Title}>{title}</Text>
          </Col>
        </Row>
      </Col>
      <Col className={styles.Col} span={3}>
        {group}
      </Col>
      <Col className={styles.Col} span={3}>
        {feedback || null}
      </Col>
      <Col className={styles.Col} span={3}>
        <Tag color="purple" icon={<Icon component={Barn} />}>
          {farm}
        </Tag>
      </Col>
      <Col className={styles.Col} span={6}>
        <Row align="middle" wrap={false} justify="space-between" gutter={[16, 0]}>
          <Col>{period}</Col>
          <Col>
            <Text className={styles.Caret}>
              <RightOutlined />
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  ) : (
    <MobileView {...props} />
  );
};

export default ListItem;
