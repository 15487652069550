import { FC } from "react";
import { Col, Row, Space } from "antd";
import { WarningOutlined, ClockCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import * as _ from "lodash";

import { DataAvailability, Direction, MissingDataReason } from "@ctra/api";
import { classname, Nullable } from "@ctra/utils";

import { Typography } from "../../../elements";
import Arrow from "../Arrow";
import styles from "./ValueChange.module.less";

const { Text, Title } = Typography;

export interface ValueChangeProps {
  /**
   * the change value represented
   */
  value: Nullable<number>;
  /**
   * suffix
   */
  suffix: Nullable<string>;
  /**
   * increase / decrease status text
   */
  text?: string;
  /**
   * type
   */
  type?: Direction;
  /**
   * context info to make decisions about what to render
   */
  context?: {
    dataStatus: Nullable<DataAvailability>;
    missingDataReason: Nullable<MissingDataReason>;
  };
}

/**
 * Numeric value change representation with suffix
 * @param {number | null | undefined} value
 * @param {string | null | undefined} suffix
 * @param {Direction | undefined} type
 * @param {string | undefined} text
 * @param {{dataStatus: Nullable<DataAvailability>, missingDataReason: Nullable<MissingDataReason>} | undefined} context
 * @returns {JSX.Element}
 * @constructor
 */
const Metric: FC<ValueChangeProps> = ({ value, suffix, type, text, context }) => {
  const { dataStatus, missingDataReason } = _.defaultTo(context, {
    missingDataReason: null,
    dataStatus: null
  });

  /**
   * Pick an arrow icon
   * @type {JSX.Element | null}
   */
  const arrowIcon = _.cond([
    [_.matches(Direction.increase), () => <Arrow direction={Direction.increase} />],
    [_.matches(Direction.decrease), () => <Arrow direction={Direction.decrease} />],
    [_.matches(Direction.stagnate), () => <MinusCircleOutlined />],
    [_.stubTrue, _.constant(null)]
  ])(type);

  /**
   * Pick a "selected" icon
   * @type {JSX.Element | null}
   */
  const icon =
    arrowIcon ||
    _.cond([
      [_.matches(DataAvailability.beforeOnly), () => <ClockCircleOutlined />],
      [_.matches(DataAvailability.afterOnly), _.constant(null)],
      [_.matches(MissingDataReason.TimeWindowNotPassed), _.constant(null)],
      [_.stubTrue, () => <WarningOutlined />]
    ])(missingDataReason || dataStatus);

  const hasRealValue = !!value && type !== Direction.stagnate;

  return (
    <Row
      justify="center"
      align="middle"
      wrap={false}
      className={classname(
        styles.Wrapper,
        "ctra-impactTracking-valueChangeMetric",
        hasRealValue ? null : "ctra-impactTracking-valueChangeMetric-noData"
      )}
    >
      <Col className={styles.Icon}>{icon}</Col>
      <Col>
        <Row>
          {hasRealValue && (
            <Col span={24} className="ctra-impactTracking-valueChangeMetric-value">
              <Title className={styles.Title} level={5}>
                <Space>
                  <Text>{_.round(value, 2)}</Text>
                  <Text size="lg">{suffix}</Text>
                </Space>
              </Title>
            </Col>
          )}
          {text ? (
            <Col
              className={classname(
                "ctra-impactTracking-valueChangeMetric-direction",
                hasRealValue ? null : "ctra-impactTracking-valueChangeMetric-noData-message"
              )}
              span={24}
            >
              <Text size="sm">{text}</Text>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default Metric;
