import * as _ from "lodash";
import convert from "convert-units";

import { Nullable } from "../typings";

/**
 * Abbreviations for seconds
 * @private
 */
const _second = ["s", "sec", "second", "seconds"];

/**
 * Abbreviations for minutes
 * @private
 */
const _minute = ["min", "minute", "minutes"];

/**
 * Abbreviations for hours
 * @private
 */
const _hour = ["h", "hr", "hour", "hours"];

/**
 * Abbreviations for hours
 * @private
 */
const _day = ["d", "day", "days"];

/**
 * Time units
 * @todo extend this as needed
 * @private
 */
const _timeUnits = [..._second, ..._minute, ..._hour, ..._day] as const;

/**
 * Currencies
 * @todo extend this as needed
 * @private
 */
const _currencies = ["$"] as const;

/**
 * Mass units
 * @todo extend this as needed
 * @private
 */
const _mass = [
  "mg",
  "mgs",
  "g",
  "gm",
  "gms",
  "gram",
  "grams",
  "kg",
  "kgs",
  "kilogram",
  "kilograms",
  "t",
  "ton",
  "tons",
  "lb",
  "lbs"
] as const;

/**
 * Length units
 */
const _length = ["mm", "cm", "m", "km", "in", "inch", "feet", "ft", "yard", "mile"] as const;

/**
 * Volume units
 */
const _volume = ["x1000 cells/mL"] as const;

/**
 * Temperature units
 */
const _temperature = ["degF", "degC"] as const;

/**
 * Percentage unit
 */
export type Percentage = "percentage";

/**
 * Time units
 */
export type TimeUnit = typeof _timeUnits[number];

/**
 * Currency units
 */
export type Currency = typeof _currencies[number];

/**
 * Mass units
 */
export type Mass = typeof _mass[number];

/**
 * Length
 */
export type Length = typeof _length[number];

/**
 * Volums units
 */
export type Volume = typeof _volume[number];

/**
 * Temperature units
 */
export type Temperature = typeof _temperature[number];

/**
 * Unit types
 */
export enum Unit {
  time = "time",
  percentage = "percentage",
  currency = "currency",
  temperature = "temperature",
  mass = "mass",
  length = "length",
  speed = "speed",
  number = "number",
  volume = "volume"
}

export enum UnitSystem {
  imperial = "imperial",
  metric = "si"
}

/**
 * Case insensitive indexOf
 * @param {string[]} array
 * @param {string} value
 * @return {number}
 */
const indexOfInsensitive = (array: string[] | readonly string[], value: string): number =>
  _.findIndex(array, (item) => item.toLowerCase() === value.toLowerCase());

/**
 * Tell if the given unit is percentage
 * @param {string} unit
 * @return {unit is Percentage}
 */
export const isPercentage = (unit: string): unit is Percentage => unit === Unit.percentage || unit === "%";

/**
 * Tell if a given unit is a time unit
 * @param {string} unit
 * @return {unit is TimeUnit}
 */
export const isTimeUnit = (unit: string): unit is TimeUnit => indexOfInsensitive(_timeUnits, unit) > -1;

/**
 * Tell if the given unit is a currency
 * @param {string} unit
 * @return {unit is Currency}
 */
export const isCurrency = (unit: string): unit is Currency => indexOfInsensitive(_currencies, unit) > -1;

/**
 * Tell if the given unit is mass
 * @param {string} unit
 * @return {unit is Mass}
 */
export const isMass = (unit: string): unit is Mass => indexOfInsensitive(_mass, unit) > -1;

/**
 * Tell if the given unit is length
 * @param {string} unit
 * @return {unit is Length}
 */
export const isLength = (unit: string): unit is Length => indexOfInsensitive(_length, unit) > -1;

/**
 * Tell if the given unit is volume
 * @param {string} unit
 * @return {unit is Volume}
 */
export const isVolume = (unit: string): unit is Volume => indexOfInsensitive(_volume, unit) > -1;

/**
 * Tell if the given unit is temperature
 * @param unit
 */
export const isTemperature = (unit: string): unit is Temperature =>
  indexOfInsensitive(_temperature, unit) > -1;

/**
 * Get the type of a given unit
 * @param unit
 * @example
 * ```ts
 * getType("hr"); // "time"
 * getType("$"); // "currency"
 * ```
 */
export const getType = (unit: string): Unit => {
  if (isPercentage(unit)) {
    return Unit.percentage;
  } else if (isCurrency(unit)) {
    return Unit.currency;
  } else if (isTimeUnit(unit)) {
    return Unit.time;
  } else if (isMass(unit)) {
    return Unit.mass;
  } else if (isLength(unit)) {
    return Unit.length;
  } else if (isVolume(unit)) {
    return Unit.volume;
  } else if (isTemperature(unit)) {
    return Unit.temperature;
  }

  return Unit.number;
};

/**
 * @param {string | number | undefined | null} value
 * @param {string} unit
 * @param {string} translatedUnit
 * @param {number} precision
 * @param {boolean} percentageFromFraction
 * @example
 * ```ts
 * format(12, "%"); // "12%"
 * format(1450, "$"); // "$1450"
 * ```
 *
 * @returns formatted string
 */
export const format = (
  value: string | number | undefined | null,
  unit: string,
  translatedUnit?: string,
  {
    precision,
    percentageFromFraction
  }: {
    precision?: number;
    percentageFromFraction?: boolean;
  } = {
    precision: 0,
    percentageFromFraction: false
  }
): string => {
  /**
   * The only place where I allow myself to use
   * a triple inline condition, as it just makes sense here
   */
  const converted = _.isNil(value) ? 0 : _.isString(value) ? parseFloat(value) : value;

  /**
   * Fix the number of digits to the given precision
   * when there is a fraction in the number
   */
  const fixed = _.round(converted, precision);

  if (!unit) {
    return fixed.toString();
  }

  if (isNaN(fixed as number)) {
    return "";
  }

  /**
   * Fall back if there is no translated unit
   * @type {string}
   */
  const u = _.defaultTo(translatedUnit, unit);

  switch (getType(unit)) {
    case Unit.currency:
      return `${u}${fixed}`;
    case Unit.percentage:
      return `${percentageFromFraction ? (fixed as number) * 100 : fixed}%`;
    case Unit.time:
    case Unit.mass:
      return `${fixed} ${u}`;
    case Unit.volume:
      return `${fixed}${u}`;
    case Unit.temperature:
      return `${fixed}${u}`;
    case Unit.length:
      return `${fixed} ${u}`;
    default:
      return `${u} ${fixed}`;
  }
};

type ConversionUnit = Parameters<ReturnType<typeof convert>["from"]>["0"];

/**
 * Guess a precision based on the given value
 * @param {number} value
 * @param {number} defaultPrecision
 * @return {number}
 */
export const getPrecision = (value: number, defaultPrecision = 1): number =>
  _.defaultTo(
    _.find([2, 3, 4, 5], (decimal) => {
      return value > Math.pow(10, -decimal);
    }),
    defaultPrecision
  );

/**
 *
 * @param value
 * @param units
 * @param unitSystems
 * @returns converted value in the desired unit system
 */
export const convertToUnitSystem = (
  value: string | number,
  units: Nullable<Record<string, string>>,
  {
    from,
    to,
    precision = 1
  }: {
    from: UnitSystem;
    to: UnitSystem;
    precision?: number;
  } = {
    from: UnitSystem.metric,
    to: UnitSystem.metric,
    precision: 1
  }
): number => {
  const originalValue = +value;

  /**
   * return value as is, if unit systems are same and not convertible
   */
  if (from === to) {
    return originalValue;
  }

  /**
   * conversion to work only if units are present and not empty
   */
  if (units && !_.isEmpty(units)) {
    /**
     * make sure the provided units are convertible e.g. lb, kg
     * units like % are not convertible so it should just return the value as is
     */
    const supportedUnits = convert().possibilities();

    /**
     * @type {ConversionUnit}
     */
    const metricUnit = units[UnitSystem.metric] as ConversionUnit;

    /**
     * @type {ConversionUnit}
     */
    const imperialUnit = units[UnitSystem.imperial] as ConversionUnit;

    /**
     * Check whether the library supports the provided units and both units are present
     */
    const isSupported =
      _.every([metricUnit, imperialUnit]) &&
      _.every(units, (unit) => supportedUnits.includes(unit as typeof supportedUnits[1]));

    if (isSupported) {
      const conversionMap = {
        [UnitSystem.imperial]: convert(originalValue).from(metricUnit).to(imperialUnit),
        [UnitSystem.metric]: convert(originalValue).from(imperialUnit).to(metricUnit)
      };

      const convertedValue = +conversionMap[to];

      return +convertedValue.toFixed(getPrecision(convertedValue, precision));
    }
  }

  return +originalValue.toFixed(precision);
};
