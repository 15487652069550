import { Middleware } from "redux";
import { getCookie, setCookie, removeCookie } from "typescript-cookie";
import * as _ from "lodash";

import { Action, Debug, isLocal, isProduction, isBlueGreen } from "@ctra/utils";

import { getStorageKey } from "../../utils/versioning";
import { types as sessionTypes } from "../session";
import types from "./types";
import { AuthState } from "./typings";

const key = `${getStorageKey()}.auth`;

/**
 * Create a debug instance for the cache middleware
 */
Debug.create("sessionCookies", "Auth Cookies", { prefixColor: "#3d8f3d", transports: true });

/**
 * Get the domain for the cookie
 * @type {string}
 */
const domain = isLocal()
  ? "localhost"
  : isBlueGreen()
  ? "deploy-app.connecterra.ai"
  : isProduction()
  ? "app.connecterra.ai"
  : "staging-app.connecterra.ai";

/**
 * Store the auth token in a cookie
 * @param {Array<string>} path
 * @return {Middleware}
 */
const sessionCookiesMiddleware =
  (path: Array<string> = []): Middleware =>
  (store) =>
  (next) =>
  (action: Action) => {
    const { type } = action;
    const result = next(action);
    const state = store.getState();
    const auth: AuthState = _.get(state, path);

    switch (type) {
      case types.REQUEST_TOKEN.fulfilled:
      case types.RENEW_TOKEN.fulfilled: {
        Debug.sessionCookies.info(`Setting auth token cookie using type ${type.toString()}`, { _auth: auth });

        setCookie<string>(key, JSON.stringify(auth), {
          path: "/",
          expires: auth.token.refreshTokenExpires
            ? new Date(auth.token.refreshTokenExpires)
            : auth.token.expires
            ? new Date(auth.token.expires)
            : void 0,
          domain
        });

        break;
      }
      case sessionTypes.LOGOUT: {
        Debug.sessionCookies.info("Removing auth token cookie via logging out");

        removeCookie(key, {
          path: "/",
          domain
        });

        break;
      }
    }

    return result;
  };

/**
 * Rehydrate the auth token from a cookie
 * @return {AuthState|null}
 */
export const rehydrate = () => {
  const cookie = getCookie(key);

  return cookie ? JSON.parse(cookie) : null;
};

export default {
  sessionCookiesMiddleware
};
