import * as _ from "lodash";

import { Delimiters, AsyncTypeStates, AsyncAction, AsyncStates, Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import { AsyncState } from "./typings";
import types from "./types";

const initialState: AsyncState = {};

/**
 * Async state reducer for keeping track of data calls
 * @param state
 * @param action
 */
export const reducer: Reducer<AsyncState, AsyncAction> = (state, action) => {
  const { type, payload } = action;

  /**
   * Extract action name and variant from the action type
   * @example "ns/FETCH_DATA.PENDING" => ["ns/FETCH_DATA", "PENDING"]
   * @note as action.type is a String constructor, we convert it to a string literal here
   */
  const [name, variant] = _.split(action.type.toString(), Delimiters.status);

  switch (type) {
    case types.CLEANUP: {
      const { name, id } = payload;

      return _.omit(state, id ? `${name}.${id}` : name);
    }
    case types.REPLAY: {
      /**
       * Nothing happens here, we just log.
       */
      break;
    }
  }

  if (_.values(AsyncTypeStates).indexOf(variant as AsyncStates[keyof AsyncStates]) > -1) {
    const primaryKey = action.type.primary;
    const preservesPayload = action.type.preservesPayload;

    if (primaryKey) {
      const primaryValue = (action.payload as Record<string, string | number>)[primaryKey];

      if (!_.isUndefined(primaryValue)) {
        const existing = (state as AsyncState)[name] as Record<string | number, string>;
        let preservedPayload = null;

        if (_.isString(preservesPayload) || _.isArray(preservesPayload)) {
          preservedPayload = _.pick(
            payload,
            _.isString(preservesPayload) ? [preservesPayload] : preservesPayload
          );
        } else if (_.isBoolean(preservesPayload)) {
          preservedPayload = payload;
        }

        return {
          ...state,
          [name]: {
            ...existing,
            [primaryValue]: {
              status: _.lowerCase(variant),
              payload: preservedPayload
            }
          }
        };
      } else {
        throw new Error(
          `A key "${primaryKey}" is mandatory in all payloads paired with \r\n` +
          action.type.toString() +
          "\r\n The current payload is \r\n" +
          JSON.stringify(action.payload, null, 2)
        );
      }
    } else {
      return {
        ...state,
        [name]: {
          status: _.lowerCase(variant),
          payload: preservesPayload ? payload : null
        }
      };
    }
  }

  return state;
};

export default cleanup<AsyncState, AsyncAction>(initialState)(reducer);
