import { EventCategory } from "@ctra/api";

import {
  FarmEquipment,
  FarmEquipmentCircle,
  FarmEquipmentSRC,
  Feed,
  FeedCircle,
  FeedSRC,
  Housing,
  HousingCircle,
  HousingSRC,
  Management,
  ManagementCircle,
  ManagementSRC,
  Other,
  OtherCircle,
  OtherSRC,
  Weather,
  WeatherCircle,
  WeatherSRC
} from "../../elements";

/**
 * Map categories to icons
 */
export const eventCategoryMap: Record<
  EventCategory,
  [typeof FarmEquipment, typeof FarmEquipment, typeof FarmEquipmentSRC]
> = {
  [EventCategory.farmEquipment]: [FarmEquipment, FarmEquipmentCircle, FarmEquipmentSRC],
  [EventCategory.other]: [Other, OtherCircle, OtherSRC],
  [EventCategory.weather]: [Weather, WeatherCircle, WeatherSRC],
  [EventCategory.feed]: [Feed, FeedCircle, FeedSRC],
  [EventCategory.housing]: [Housing, HousingCircle, HousingSRC],
  [EventCategory.management]: [Management, ManagementCircle, ManagementSRC]
};
