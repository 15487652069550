import * as _ from "lodash";

import { memoize, TS } from "@ctra/utils";

import { Base } from "./Base";

import { TableData } from "../typings";

/**
 * X axis data type date class
 *
 * Named as Day and not Date to avoid any confusion with the Javascript Date class
 */
class Day extends Base {
  /**
   * format xAxis data
   * @param value
   * @returns
   */
  @memoize
  formatXAxis<T extends number | string>(value: T): T {
    return _.isString(value) ? (TS.asMoment(value).format("YYYY-MM-DD") as T) : value;
  }

  /**
   * format table data
   * @param value
   * @returns
   */
  @memoize
  formatTableData(data: TableData["data"]): TableData["data"] {
    //@ts-ignore
    return _.sortBy(data, (item) => new Date(item.key).valueOf());
  }
}

export { Day };
