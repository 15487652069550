import { createAsyncActions } from "@ctra/utils";

import { FeedbackPayload } from "./typings";
import types from "./types";

/**
 * Send user feedback
 */
const sendFeedback = createAsyncActions(
  types.SEND_FEEDBACK,
  (payload: FeedbackPayload) => payload,
  (response: Record<string, unknown>) => response,
  (error, statusCode, details) => ({
    error,
    statusCode,
    details
  })
);

export default {
  sendFeedback
};
