import { createModule, from, makeAsset } from "../../utils";

export const login = createModule("login", {
  ...from("social"),
  page: from("header", "content"),
  form: {
    ...from("title", "loginFailed", "welcome", "activated", "footer"),
    labels: from("username", "password", "rememberMe", "submit", "forgotPassword", "activationResent"),
    placeholders: from("username", "password"),
    validation: from("emailFormat", "emailRequired", "incorrectCredentials", "passwordRequired")
  }
});

export const forgotPassword = createModule("forgotPassword", {
  page: from("header", "content"),
  form: {
    ...from("title", "subtitle", "description", "requestSentMessage"),
    labels: from("emailAddress", "sendResetLink", "login"),
    placeholders: from("username"),
    validation: from("emailFormat", "emailRequired")
  }
});

export const resetPassword = createModule("resetPassword", {
  page: from("header", "content"),
  form: {
    ...from("title", "subtitle", "resetPasswordMessage"),
    labels: from("emailAddress", "login", "resetPassword"),
    placeholders: from("password", "confirmPassword"),
    validation: from(
      "emailFormat",
      "emailRequired",
      "passwordRequired",
      "passwordLength",
      "passwordCharacters",
      "passwordsMatch"
    )
  }
});

export const acceptInvite = createModule("acceptInvite", {
  error: makeAsset,
  page: {
    ...from("header", "title", "content", "divider", "hasAccount", "login"),
    modal: from("subTitle", "action")
  },
  form: {
    ...from("inviteRejectedMessage"),
    subType: makeAsset,
    labels: from(
      "emailAddress",
      "createAccount",
      "password",
      "confirmPassword",
      "firstName",
      "lastName",
      "companyName",
      "subType"
    ),
    placeholders: from(
      "companyName",
      "emailAddress",
      "phoneNumber",
      "password",
      "confirmPassword",
      "firstName",
      "lastName",
      "subType"
    ),
    validation: from(
      "emailFormat",
      "emailRequired",
      "passwordRequired",
      "passwordLength",
      "passwordsMatch",
      "passwordFormat",
      "firstNameRequired",
      "lastNameRequired",
      "companyName"
    )
  },
  modal: {
    actions: from("ok", "cancel"),
    body: "body",
    title: "title"
  }
});
