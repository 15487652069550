import * as _ from "lodash";

import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import { InsightResolutionEntity, InsightSettingsState, InsightsState, NotificationAction } from "./typings";
import { NormalizedKPIInsightSettings, NormalizedInsightMappingList } from "./schemas";
import { FetchInsightsFulfilledPayload } from "./actions";

export const initialState: InsightsState = {
  entities: {},
  insightTypes: {},
  insightValidations: {},
  insightResolutions: {},
  lists: {}
};

export const initialInsightSettingsState: InsightSettingsState = {
  mappings: {},
  mappingGroups: {},
  kpiInsights: {}
};

/**
 * Reducer for insight settings
 * @param state
 * @param action
 * @category Reducer
 */
export const settingsReducer: Reducer<InsightSettingsState> = cleanup<InsightSettingsState>(
  initialInsightSettingsState
)((state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_INSIGHT_NOTIFICATION_MAPPINGS.fulfilled: {
      const {
        entities: { mappingGroup, insightMapping }
      } = payload as NormalizedInsightMappingList;

      return {
        ...state,
        mappings: insightMapping,
        mappingGroups: mappingGroup
      };
    }
    case types.UPDATE_INSIGHT_NOTIFICATION_SETTINGS.fulfilled: {
      const { notificationType, notificationMappingID, notificationAction } = payload;

      return {
        ...state,
        mappings: {
          ...state.mappings,
          [notificationMappingID]: {
            ...state.mappings[notificationMappingID],
            channels: {
              ...state.mappings[notificationMappingID].channels,
              [notificationType]: notificationAction === NotificationAction.enable ? true : false
            }
          }
        }
      };
    }
    case types.FETCH_KPI_INSIGHT_SETTINGS.fulfilled: {
      const { result } = payload as NormalizedKPIInsightSettings;
      return {
        ...state,
        kpiInsights: result
      };
    }
    case types.UPDATE_KPI_INSIGHT_SETTINGS.fulfilled: {
      const { farmID, dataDescriptorID, group, ...settings } = payload;

      return {
        ...state,
        kpiInsights: {
          ...state.kpiInsights,
          [farmID]: {
            ...state.kpiInsights[farmID],
            [dataDescriptorID]: group
              ? [
                  ..._.reject(_.get(state, ["kpiInsights", farmID, dataDescriptorID], []), { group }),
                  {
                    group,
                    ...settings
                  }
                ]
              : [{ group, ...settings }]
          }
        }
      };
    }
  }

  return state;
});

/**
 * Reducer for insights
 * @param state
 * @param action
 * @category Reducer
 */
export const insightsReducer: Reducer<InsightsState> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_INSIGHT_RESOLUTIONS.fulfilled: {
      const {
        entities: { insightResolution }
      } = payload;

      return {
        ...state,
        insightResolutions: {
          ...state.insightResolutions,
          ...insightResolution
        }
      };
    }
    case types.CREATE_INSIGHT_VALIDATION.fulfilled: {
      const {
        entities: { insightValidation },
        result
      } = payload;

      const { genericInsightID } = insightValidation[result];
      const { validations, ...rest } = state.entities[genericInsightID];

      return {
        ...state,
        insightValidations: {
          ...state.insightValidations,
          ...insightValidation
        },
        entities: {
          ...state.entities,
          [genericInsightID]: {
            validations: [result],
            ...rest
          }
        }
      };
    }
    case types.UPDATE_INSIGHT_VALIDATION.fulfilled: {
      const { id } = payload;

      return {
        ...state,
        insightValidations: {
          ...state.insightValidations,
          [id]: payload
        }
      };
    }
    case types.FETCH_INSIGHT_TYPES.fulfilled: {
      const {
        entities: { insightType }
      } = payload;

      return {
        ...state,
        insightTypes: {
          ...state.insightTypes,
          ...insightType
        }
      };
    }
    case types.FETCH_INSIGHTS.fulfilled: {
      const {
        list,
        hash,
        entities: { insight, insightType, insightValidation, insightResolution },
        result
      } = payload as FetchInsightsFulfilledPayload;

      return {
        /**
         * What is in the state already should be priority
         * during merging the 2 sources.
         */
        insightResolutions: {
          ...insightResolution,
          ...state.insightResolutions
        },
        insightValidations: {
          ...state.insightValidations,
          ...insightValidation
        },
        insightTypes: {
          ...state.insightTypes,
          ...insightType
        },
        entities: {
          ...state.entities,
          ...insight
        },
        /**
         * keep track of the insights list either via
         * the named list or making a hash of the query
         */
        lists: {
          ...state.lists,
          ...(list
            ? {
                [list]: _.uniq([..._.get(state.lists, list, []), ...result])
              }
            : {
                [hash]: result
              })
        }
      };
    }
    /* istanbul ignore next */
    case types.UPDATE_INSIGHT.pending: {
      const {
        source: { resolution }
      } = payload;

      /**
       * Collect the custom resolutions
       */
      const customResolutions = _.reduce<
        InsightResolutionEntity,
        Record<InsightResolutionEntity["id"], InsightResolutionEntity>
      >(
        _.filter(resolution, ["source", "custom"]),
        (result, item) => {
          const { id } = item;

          result[id] = item;

          return result;
        },
        {}
      );

      return _.isEmpty(customResolutions)
        ? state
        : {
            ...state,
            insightResolutions: {
              ...state.insightResolutions,
              ...customResolutions
            }
          };
    }
    case types.UPDATE_INSIGHT.fulfilled: {
      const { id, insightResolution, ...updates } = payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...state.entities[id],
            ...updates
          }
        }
      };
    }
  }

  return state;
};

export default cleanup<InsightsState>(initialState)(insightsReducer);
