import { combineReducers, Reducer } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import storage from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";

import { Action } from "@ctra/utils";

import { SubscriptionsAppState } from "./state";
import { getVersion, getStorageKey } from "../utils/versioning";

/**
 * @todo import new reducers here
 */
import async from "../modules/async";
import auth from "../modules/auth";
import breadcrumbs from "../modules/breadcrumbs";
import users from "../modules/user";
import signup from "../modules/signup";

/**
 * @todo bump version between releases
 */
const persistConfig = {
  version: getVersion(),
  key: getStorageKey("subscriptions"),
  blacklist: ["auth", "async", "navigation", "session"],
  storage
};

export default (history: History): Reducer =>
  persistReducer<SubscriptionsAppState, Action>(
    persistConfig,
    /**
     * @todo add new reducers here
     */
    combineReducers<SubscriptionsAppState, Action>({
      router: connectRouter(history),
      // @ts-ignore - not an issue, just inconsistency between the original and our Reducer types
      auth,
      users,
      async,
      signup,
      navigation: combineReducers({
        breadcrumbs
      })
    })
  );
