import { ChartSeriesType } from "../typings";
import { Base } from "./Base";
import { FarmSeries } from "./FarmSeries";
import { DimGroupSeries } from "./DimGroupSeries";
import { HerdGroupSeries } from "./HerdGroupSeries";

type RegisteredSeries = typeof FarmSeries | typeof Base;

class Factory {
  private static _registry = new Map();

  /**
   * Register a series
   * @param type
   * @param cls
   */
  static register<I extends RegisteredSeries>(type: ChartSeriesType, cls: I): void {
    if (!Factory._registry.has(type)) {
      Factory._registry.set(type, cls);
    } else {
      throw new Error(`${type} series is already in the registry`);
    }
  }

  /**
   * Create an instance of a series
   * @param key
   * @param type
   * @returns
   */
  static create<I extends RegisteredSeries>(key: string | number, type?: ChartSeriesType): InstanceType<I> {
    let cls = Factory._registry.get(type);

    if (!cls) {
      cls = Base;
    }

    return new cls(key);
  }
}

Factory.register(ChartSeriesType.farm, FarmSeries);
Factory.register(ChartSeriesType.herdGroup, HerdGroupSeries);
Factory.register(ChartSeriesType.dimGroup, DimGroupSeries);

export { Factory };
