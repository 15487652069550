import { memoize } from "@ctra/utils";

import { TableData } from "../typings";

/**
 * X axis data type base class
 */
class Base {
  /**
   * Format xAxis data
   * @param {T} value
   * @return {T}
   */
  @memoize
  formatXAxis<T extends number | string>(value: T): T {
    return value;
  }

  /**
   * Format table data
   * @param {TableData["data"]} data
   * @return {TableData["data"]}
   */
  @memoize
  formatTableData(data: TableData["data"]): TableData["data"] {
    return data;
  }
}

export { Base };
