import { Nullable } from "@ctra/utils";
import { FarmEntity } from "../farms";

export interface IOFCRequest {
  startDate: string;
  endDate: string;
  milkYieldSource: string;
  milkFatSource: string;
  milkProteinSource: string;
  avgFeedCost: Nullable<number>;
  avgMilkPrice: Nullable<number>;
  milkPriceClass: Array<1 | 2 | 3 | 4>;
  classWeights: Array<number>;
  units: "Imperial" | "SI";
}

/**
 * List of KPIs to pick from
 * @type {readonly [string, string, string, string, string, string, string, string, string, string, string, string, string]}
 */
export const IOFCKpiList = [
  "proteinPerc",
  "Butterfat",
  "Skim",
  "dmimilking",
  "Milk",
  "fatPerc",
  "feedcost",
  "inmilk",
  "yield",
  "Iofc",
  "EffectiveClass1MilkPrice",
  "EffectiveClass3MilkPrice",
  "exist",
  "MilkPickupFatPct"
] as const;

export type IOFCKPI = typeof IOFCKpiList[number];
export type IOFCRequestPayload = { farmId: FarmEntity["id"] } & IOFCRequest;

export type IOFCResponse = {
  data: Record<IOFCKPI, Array<number>> & { date: Array<string> };
  meta: { units: Record<IOFCKPI, string> };
};
