import * as _ from "lodash";

/**
 * Converts the keys of the given object to camelCase.
 * @param {{}} obj - the object to convert
 * @todo find a generic solution to allow autocomplete
 * @example
 * ```typescript
 * camelize({ this_key: 1, another_key: 2 })
 * // { thisKey: 1, anotherKey: 2 }
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const camelize = (obj: Record<string, any>): Record<string, any> =>
  _.mapKeys(obj, (v, k) => _.camelCase(k));
