import { Debug } from "@ctra/utils";

Debug.create("genericLabelApi", "Label API", { prefixColor: "#BE80FF", transports: true });

// import { default as reducer, initialState } from "./reducer";

export { default as types } from "./types";
// export { default as schemas } from "./schemas";
export { default as actions } from "./actions";
export { default as epics } from "./epics";
// export { reducer, initialState };

export * from "./typings";
// export { utils };

// export default reducer;
