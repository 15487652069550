import * as _ from "lodash";

import { Reducer } from "@ctra/utils";

import { cleanup } from "../../utils/reducer";
import types from "./types";
import { InvitationState, ReferralStatus } from "./typings";
import { NormalizedInvitation, NormalizedInvitationList } from "./schemas";
import { retry } from "rxjs";

export const initialState: InvitationState = {
  entities: {},
  sent: [],
  received: []
};

/**
 * Reducer for user list (coming as "users of partner")
 * @param state
 * @param action
 * @return {InvitationState}
 */
export const reducer: Reducer<InvitationState> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_INVITATION.fulfilled: {
      const {
        entities: { invitation },
        result
      } = payload as NormalizedInvitation;

      return {
        ...state,
        received: _.uniq([...state.received, result]),
        entities: {
          ...state.entities,
          ...invitation
        }
      };
    }
    case types.FETCH_INVITATION_LIST.fulfilled: {
      const {
        entities: { invitation },
        result: {
          sent,
          received,
          metadata: { farms }
        }
      } = payload as NormalizedInvitationList;

      /**
       * Iterate over the list to inject the farm names from the metadata
       */
      _.forEach(invitation, (obj) => {
        const matchingFarm = _.get(farms, obj.farmID);

        // If a match is found, inject the farmName
        if (matchingFarm) {
          obj.farmName = matchingFarm;
        }
      });

      return {
        entities: _.pickBy(invitation, ({ status }) => status !== ReferralStatus.deleted),
        sent,
        received
      };
    }
    case types.CREATE_INVITATION.fulfilled: {
      const {
        entities: { invitation },
        result
      } = payload as NormalizedInvitation;

      return {
        ...state,
        sent: _.uniq([...state.sent, result]),
        entities: {
          ...state.entities,
          ...invitation
        }
      };
    }
    case types.ACCEPT_INVITATION.fulfilled:
    case types.UPDATE_INVITATION.fulfilled: {
      const {
        entities: { invitation }
      } = payload as NormalizedInvitation;

      return {
        ...state,
        entities: {
          ...state.entities,
          ...invitation
        }
      };
    }
    case types.DELETE_INVITATION.fulfilled: {
      const { invitationId } = payload;
      const { [invitationId]: _, ...rest } = state.entities;

      return {
        ...state,
        sent: state.sent.filter((id) => id !== invitationId),
        entities: rest
      };
    }
  }

  return state;
};

export default cleanup<InvitationState>(initialState)(reducer);
