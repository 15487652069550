import { default as reducer, initialState } from "./reducer";
//
// export { default as types } from "./types";
// export { default as actions } from "./actions";
// export { default as epics } from "./epics";
export { reducer, initialState };

export * from "./typings";

export default reducer;
