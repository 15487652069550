/* istanbul ignore file - just a bunch of vars and types */
import { getStorageKey } from "../versioning";

export const localColorStoreKey = `${getStorageKey()}.colors`;
export type ColorType = { hue: number; saturation: number; luminosity: number };

/**
 * Hue palette
 *
 * According to our design scheme:
 * @see https://www.figma.com/file/8cUXP1eqw1Wft9YTDvTezo/Ida-Enterprise-v3.0?node-id=7160%3A433594
 */
export const huePalette: Array<number> = [100, 228, 52, 327, 265, 25, 77, 357, 187, 32, 0, 180, 40];

/**
 * export const colorPalette: Array<string> = [
  "#52C41A",
  "#2F54EB",
  "#FADB14",
  "#EB2F96",
  "#722ED1",
  "#FF6C01",
  "#A0D911",
  "#F5222D",
  "#009EB3",
  "#FF8E0A",
  "#BFBFBF"
  "#13C2C2",
  "#FAAD14",
];
 */
