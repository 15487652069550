import { Base } from "./Base";
import { BarNormalizer } from "./BarNormalizer";
import { LineNormalizer } from "./LineNormalizer";
import { TableNormalizer } from "./TableNormalizer";
import { HeatmapNormalizer } from "./HeatmapNormalizer";

import { ChartDataSource, ChartType } from "../typings";

type RegisteredNormalizers =
  | typeof LineNormalizer
  | typeof BarNormalizer
  | typeof TableNormalizer
  | typeof HeatmapNormalizer;

class Factory {
  private static _registry = new Map();

  /**
   * Register a class of Normalizer
   * @param type
   * @param cls
   */
  static register<I extends RegisteredNormalizers>(type: ChartType, cls: I): void {
    if (!Factory._registry.has(type)) {
      Factory._registry.set(type, cls);
    } else {
      throw new Error(`${type} normalizer is already set in the registry`);
    }
  }

  /**
   * Create a Normalizer instance if found in the registry
   * else create an instance of the Base Normalizer
   * @param source
   * @returns
   */
  static create<I extends RegisteredNormalizers>(source: ChartDataSource): InstanceType<I> {
    const {
      meta: { type }
    } = source;
    let cls = Factory._registry.get(type);

    if (!cls) {
      console.error(`${type} normalizer is not yet in the registry. Using Normalizer Base as constructor`);
      cls = Base;
    }

    return new cls(source);
  }
}

Factory.register(ChartType.basicBar, BarNormalizer);
Factory.register(ChartType.basicColumn, LineNormalizer);

Factory.register(ChartType.stackedColumn, LineNormalizer);
Factory.register(ChartType.stackedPercentColumn, LineNormalizer);
Factory.register(ChartType.stackedArea, LineNormalizer);
Factory.register(ChartType.basicLine, LineNormalizer);

Factory.register(ChartType.table, TableNormalizer);
Factory.register(ChartType.heatmap, HeatmapNormalizer);

export { Factory };
