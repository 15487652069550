import * as _ from "lodash";
import { Nullable } from "../typings";

type Parsed<Keys extends readonly string[]> = Record<Keys[number], Nullable<string>>;

/**
 * Parse the given keys from the URL Params
 * @param urlParams
 * @param keys
 */
export const parseQuery = <Keys extends readonly string[]>(
  urlParams: URLSearchParams,
  ...keys: Keys
): Parsed<Keys> =>
  _.reduce<Keys[number], Parsed<Keys>>(
    keys,
    (result, key) => {
      result[key] = urlParams.get(key);

      return result;
    },
    {} as Parsed<Keys>
  );
