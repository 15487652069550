import { createModule, from } from "../../utils";

export const signup = createModule("signup", {
  ...from("pageTitle", "social"),
  form: {
    ...from("title", "signupFailed", "eula", "footer"),
    labels: from("create"),
    placeholders: from("firstName", "lastName", "email", "password", "confirmPassword", "phoneNumber"),
    validation: from(
      "emailFormat",
      "emailRequired",
      "passwordRequired",
      "passwordLength",
      "passwordsMatch",
      "passwordFormat",
      "firstNameRequired",
      "lastNameRequired"
    )
  }
});

export const activate = createModule("activate", {
  ...from("pageTitle"),
  pageContent: from("intro", "mailInfo"),
  actions: from("resend"),
  alerts: from("emailSent")
});

export const confirmEmail = createModule("confirmEmail", {
  ...from("pageTitle"),
  pageContent: from("mailInfo", "label"),
  actions: from("resend"),
  alerts: from("emailSent")
});

export const activation = createModule("activation", {
  page: from("header"),
  content: {
    ...from("intro", "email", "expired", "resend", "emailSent"),
    activating: {
      ...from("inProgress"),
      failed: from("title", "description")
    }
  }
});
