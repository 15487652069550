import * as _ from "lodash";
import { Nullable } from "@ctra/utils";

/**
 * Parse a $`content` formatted loco template string
 * @param {string} text
 * @param {boolean} trim
 * @param {boolean} passthrough
 * @returns {Nullable<Array<string>>}
 */
export const parseLocoTemplate = (
  text: string,
  {
    trim,
    passthrough
  }: {
    trim?: boolean;
    passthrough?: boolean;
  } = { trim: false, passthrough: false }
): Nullable<Array<string>> => {
  const rx = new RegExp("\\$`(.*?)`", "gi");
  const compact = _.compact(text.split(rx));
  const result: Array<string> = trim ? _.filter(compact, (content) => /\S/.test(content)) : compact;

  return result.length > 1 || passthrough ? result : null;
};
