import { Observable, of, interval, delay } from "rxjs";
import * as _ from "lodash";
import { mergeMap, map, catchError, switchMap, takeWhile, startWith } from "rxjs/operators";
import { ofType, Epic } from "redux-observable";

import { Action } from "@ctra/utils";

import { makeAzureApiURL } from "../../utils/ajax";
import types from "./types";
import actions from "./actions";
import { AnnotationResultsResponse, AnnotationState } from "./typings";

/**
 * Request annotation results
 * @param action$
 * @param state$
 * @param Request
 */
const fetchAnnotationResults: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.FETCH_ANNOTATION_RESULTS.pending),
    switchMap<ReturnType<typeof actions.fetchAnnotationResults.start>, Observable<unknown>>(
      ({ payload: { annotationID } }) => {
        const fetchData$ = () =>
          Request.GET(makeAzureApiURL("annotations", `/annotations/${annotationID}/impact`)()).pipe(
            map<{ response: AnnotationResultsResponse }, Action>(({ response }) =>
              actions.fetchAnnotationResults.fulfill(annotationID, response)
            ),
            catchError((error) => of(actions.fetchAnnotationResults.reject(annotationID, error)))
          );

        // Create an observable that emits every 5 seconds (adjust as needed)
        const interval$ = interval(5000).pipe(startWith(0));

        // Keep fetching until the 'isProcessing' field is not true
        return interval$.pipe(
          switchMap(() => fetchData$()),
          takeWhile((action) => {
            const annotationState = _.get(action, [
              "payload",
              "entities",
              "annotationResult",
              annotationID,
              "state"
            ]);

            const isProcessing = _.isEqual(annotationState, AnnotationState.processing);

            return isProcessing;
          }, true)
        );
      }
    )
  );

/**
 * Request annotation results
 * @param action$
 * @param state$
 * @param Request
 */
const addMetricToEvent: Epic = (action$, state$, { Request }) =>
  action$.pipe(
    ofType(types.ADD_METRIC.pending),
    mergeMap<ReturnType<typeof actions.addMetricToEvent.start>, Observable<Promise<unknown>>>(
      ({ payload: { annotationID, dataDescriptorID } }) =>
        Request.POST(makeAzureApiURL("annotations", `/annotations/${annotationID}/impact/custom-metrics`)(), {
          body: { descriptorId: dataDescriptorID }
        }).pipe(
          map(() => actions.addMetricToEvent.fulfill(annotationID)),
          /**
           * a small delay of 2 seconds so when this is fulfilled and a new fetch call is made to /impact
           * it can get the latest state as updated from DS
           */
          delay(2000),
          catchError<unknown, Observable<Action>>((error) => {
            return of(actions.addMetricToEvent.reject(annotationID, error));
          })
        )
    )
  );

export default {
  fetchAnnotationResults,
  addMetricToEvent
};
