import { ComponentProps, FC } from "react";
import { Space, Typography, Switch as SwitchUI } from "antd";

import { classname } from "@ctra/utils";

import styles from "./Switch.module.less";

const { Text } = Typography;

export interface SwitchProps extends ComponentProps<typeof SwitchUI> {
  /**
   * prop name explanation
   */
  label: string;
}

/**
 * Switch component
 * @param {string} label
 * @param {string | undefined} className
 * @param {boolean | undefined} disabled
 * @param {Pick<SwitchProps & {children?: React.ReactNode | undefined}, "prefixCls" | "size" | "checked" | "defaultChecked" | "onChange" | "onClick" | "checkedChildren" | "unCheckedChildren" | "loading" | "autoFocus" | "style" | "title" | "tabIndex" | "id" | "ref" | "key" | "children">} rest
 * @return {JSX.Element}
 * @constructor
 */
const Switch: FC<SwitchProps> = ({ label, className, disabled, ...rest }) => (
  <Space direction="horizontal" className={classname("ctra-generic-switch", styles.Switch, className)}>
    <SwitchUI disabled={disabled} {...rest} />
    <Text type={disabled ? "secondary" : void 0}>{label}</Text>
  </Space>
);

export default Switch;
