import { Reducer } from "@ctra/utils";

import { SessionState } from "./typings";
import types from "./types";

export const initialState: SessionState = {
  flags: {
    isReset: false
  }
};

/**
 * Session reducer
 * @param {SessionState} state
 * @param {Action} action
 * @return {{flags: {isReset: boolean}}}
 */
export const reducer: Reducer<SessionState> = (state, action) => {
  const { type } = action;

  switch (type) {
    case types.RESET: {
      return {
        ...state,
        flags: {
          ...state.flags,
          isReset: true
        }
      };
    }
  }

  return state || initialState;
};

export default reducer;
