import { AsyncType, createCRUDAsyncTypes } from "@ctra/utils";

const ns = "app/farm-summary";

/**
 * CRUD farm summary
 */
const [
  /**
   * @category Type
   */
  CREATE_FARM_SUMMARY,
  /**
   * @category Type
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _FETCH_FARM_SUMMARY,
  /**
   * @category Type
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _UPDATE_FARM_SUMMARY,
  /**
   * @category Type
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _DELETE_FARM_SUMMARY
]: Array<AsyncType> = createCRUDAsyncTypes(ns, "FARM_SUMMARY", { primary: "id" });

export default { CREATE_FARM_SUMMARY };
