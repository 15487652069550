import * as _ from "lodash";

import { UserEntity, UserList } from "./typings";

/**
 * Get user display name
 * @param {UserEntity} user
 */
const getDisplayName = (user: Partial<UserEntity>): string => {
  const name = _.compact([user.firstName, user.lastName]).join(" ");

  return _.trim(name) ? name : (user.username as string);
};

/**
 * Resolve a user object from its user id or username
 * @note this is to maintain backward compatibility while we move to user ids in game plans
 * @param users
 * @param key
 */
const resolve = (users: UserList, key: NonNullable<UserEntity["id"] | UserEntity["username"]>): UserEntity =>
  users[key] || _.find(users, (user) => user.username === key);

export default {
  resolve,
  getDisplayName
};
